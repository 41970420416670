import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import Drawflow from 'drawflow';
import { Dataservice } from '../services/dataservice';
import { AuthenticationService } from '../Authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { HomeComponent } from '../home/home.component';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
  isFormGroup,
} from '@angular/forms';
import { ContentObserver } from '@angular/cdk/observers';
import { deletepopup } from '../mydevices/mydevices.component';
import { MatDialog } from '@angular/material/dialog';
import {
  GridsterComponent,
  GridsterConfig,
  GridsterConfigService,
  GridsterItem,
  GridsterItemComponentInterface,
} from 'angular-gridster2';
import { Options } from '@angular-slider/ngx-slider';
import { COMMA, ENTER, SEMICOLON, V } from '@angular/cdk/keycodes';
import { first, map, skip, startWith, take } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { newproduct } from '../product/product.component';
import chartGroups from '../chartTypes';
import {
  single,
  multi,
  bubble,
  boxData,
  fiscalYearReport,
  timelineFilterBarData,
} from '../data';
import { LegendPosition } from '@swimlane/ngx-charts';
import { Color, colorSets } from '@swimlane/ngx-charts';
import { data as countries } from 'emoji-flags';
import * as Chart from 'chart.js';
import { ScaleType } from '@swimlane/ngx-charts';
import * as shape from 'd3-shape';
import { logout } from '../menu/menu.component';
const monthName = new Intl.DateTimeFormat('en-us', { month: 'short' });
const weekdayName = new Intl.DateTimeFormat('en-us', { weekday: 'short' });
@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.css'],
})
/**
 * This component handles various aspects of route management and UI interactions.
 */
export class RoutesComponent implements OnInit {
  @ViewChild('chart') chart: any;
  // Reference to elements using @ViewChild decorator
  @ViewChild('pinmodeSelect') pinmodeSelect!: ElementRef; // Reference to the pin mode select element
  @ViewChild('selectpin') selectpin!: ElementRef; // Reference to the select pin element
  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav; // Reference to the main sidenav component
  @ViewChild('dbdesign_sidenav') sidenav_dbdesign!: MatSidenav; // Reference to the design sidenav component
  @ViewChild('gridster', { static: false }) gridster!: GridsterComponent; // Reference to the gridster component (lazy loading)
  @ViewChild('gridsteredit', { static: false })
  gridsteredit!: GridsterComponent; // Reference to the gridster edit component (lazy loading)
  @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>; // Reference to the user input element
  @ViewChild('Editor') editorElementRef!: ElementRef; // Reference to the editor element
  analog_type: any; // Placeholder for the analog type
  eventcolor: any = '#0dcaf0'; // Default event color
  gaugetype: any;
  charttype: any;
  v1pin!: boolean;
  v7pin!: boolean;
  dataa!: any;
  piedata!: any;
  pieoptions!: any;
  clustername: any;
  temp!: any;
  topvalue!: any;
  tcolor!: any;
  routesrefresh: any = false;
  range = this.formbuilder.group({
    cluster_Id: ['', Validators.required],
  });
  slavedevices: any;
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: any;
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  /**
   * Executes after the view and child views are initialized.
   * Used for additional initialization and UI interactions.
   */

  ngAfterViewInit() {
    // Scroll functionality for menu

    const buttonRight = document.getElementById('scrollRight');
    const buttonLeft = document.getElementById('scrollLeft');
    /**
     * addeventlisterner function
     *
     */

    buttonRight!.addEventListener('click', () => {
      const container = document.getElementById('menu');
      if (container) {
        container.scrollLeft += 40;
        console.log('right click works');
      }
    });

    buttonLeft!.addEventListener('click', () => {
      const container = document.getElementById('menu');
      if (container) {
        container.scrollLeft -= 40;
        console.log('left click works');
      }
    });
    // Initialize pin mode and pin selection if in edit mode

    if (this.editdatastream) {
      const selectEl = this.pinmodeSelect?.nativeElement;
      const selectpin = this.selectpin?.nativeElement;

      const selectedOption = selectEl.querySelector(
        `option[value="${this.editdatastream?.pinmode}"]`
      );
      const selectedpin = selectpin.querySelector(
        `option[value="${this.editdatastream?.pin}"]`
      );

      if (selectedOption) {
        selectEl.selectedIndex = selectedOption.index;
      }
      if (selectedpin) {
        selectpin.selectedIndex = selectedpin.index;
      }
    }
  }
  useremail: any[] = [];
  usernumber: any[] = [];
  filteredUser: any;
  filteredUserNumber: any;
  inputname: any;
  GaugeProp!: boolean;
  switchprop!: boolean;
  virtualprop!: boolean;
  in_name: any;
  output_name: any;
  smstoogle!: boolean;
  id: any = null;
  editor: any = null;
  transform: string = '';
  mobile_item_selec: string = '';
  mobile_last_move: any = null;
  productvalue: any;
  data: any;
  hardware_name: any;
  Digitalpin: any[] = [];
  AnalogpinOut: any[] = [];
  AnalogpinIn: any[] = [];
  Virtualpin: any[] = [];
  DigitalpinOut: any[] = [];
  Digitalpinpull: any[] = [];
  editdatastream: any;
  btn: any;
  nodename: any;
  units: any;
  datass!: any;
  pin: any;
  cluster_id: any;
  datastream_name: any;
  cluster_name: any;
  color = '#3633b7';

  textcolor = '#ffffff';
  screencolor = '#000000';
  disabledpin: any[] = [];
  firstflag: boolean;
  newdatacome: any[] = [];
  remove: boolean;
  lastcluster_Id!: any;
  lastmoduleindex: any;
  routesdata: any[] = [];
  value: number = 80;
  dashboardwidget: any[] = [];
  UserCtrl = new FormControl('', [Validators.email]);
  UserNumberCtrl = new FormControl('');
  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, 188];
  user_Id: any[] = [];
  selectable = true;
  removable = true;
  events_data: any[] = [];
  pintag: any;
  notify_type: any[] = [];
  lastnode_Id: any;
  FirstSave!: boolean;
  lastRemove!: boolean;
  design!: boolean;
  chartGroups!: any[];
  multi!: any[];
  Single: any[] = [];
  gradient = false;
  legendPosition = LegendPosition.Right;
  animations: boolean = true;
  calendarData!: any[];
  fiscalYearReport: any[] = fiscalYearReport;
  timelineFilterBarData!: any[];

  // options
  showXAxis = true;
  showYAxis = true;

  showLegend = true;
  legendTitle = 'Legend';

  showXAxisLabel = true;
  tooltipDisabled = false;
  showText = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'GDP Per Capita';
  showGridLines = true;
  innerPadding = '10%';
  barPadding = 8;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  showSeriesOnHover = true;
  roundEdges: boolean = true;
  xScaleMin!: any;
  xScaleMax!: any;
  yScaleMin!: number;
  yScaleMax!: number;
  showDataLabel: boolean = false;
  noBarWhenZero: boolean = true;
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = true;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 16;
  strokeColor: string = '#FFFFFF';
  strokeWidth: number = 2;
  wrapTicks = false;
  dateData!: any[];
  linecount: number = 0;
  chartlines: any[] = [];
  costcount: number = 0;
  addresscount: number = 0;
  addresslist: any[] = [];

  costlist: any[] = [];
  CostNode!: boolean;
  exportRoutes: any = null;



// speedometer gauge options
public canvasWidth = 300;
public needleValue = 0;
public gaugeoptions = {
  hasNeedle: true, needleColor: 'black', needleUpdateSpeed: 1000, arcColors: [], arcDelimiters: [10, 20, 30, 40, 50, 60, 70, 80, 90], arcPadding: 6, arcLabels: ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'], arcLabelFontSize: false, rangeLabel: ['0', '100'], needleStartValue: 0,
}

  curves: any = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear,
  };

  basicData: any;
  chartoptions: any;
  piechartoptions: any;
  chartlabel: any = 'Label';
  /**
   * An array containing time values for chart labels.
   * Each element represents the formatted current time.
   */
  chartlabelvalue: any[] = [
    new Date().toLocaleTimeString(),
    new Date().toLocaleTimeString(),
    new Date().toLocaleTimeString(),
    new Date().toLocaleTimeString(),
    new Date().toLocaleTimeString(),
  ];
  selectedValue!: string | null;
  virtual_Int!: boolean;
  emailToggle!: boolean;
  email_to: string[] = [];
  User_Number: any[] = [];
  widgetbox: any[] = [];
  device_dashboard: any[] = [];
  options: any = {
    draggable: true,
    resizable: true,
    // Other gridster options
  };
  widget_list: any[] = [
    'Switch',
    'LED',
    'Label',
    'Cost',
    'Slider',
    'Gauge',
    'Terminal',
    'Chart',
    'Gauge3Phase',
    'Speedometer'
  ];

  // gridster
  gaugeType = 'arch';
  chartType = 'line';
  gaugeLabel = 'Speed';
  Type = 'full';

  // ngx slider
  Options: Options = {
    floor: 0,
    ceil: 1000,
    step: 30,
    disabled: true,
    // showTicks: true,
    // draggableRange: true
  };
  /**
   *
   * Gridster options which is used for web dashboard (Widgets)
   */
  gridsterOptions: GridsterConfig = {
    gridType: 'fixed',
    fixedColWidth: 80,
    fixedRowHeight: 100,
    compactType: 'none',
    margin: 10,
    outerMargin: false,
    minCols: 16,
    maxCols: 16,
    minRows: 5,
    maxRows: 100,
    displayGrid: 'onDrag&Resize',
    // defaultItemCols: 2,
    // defaultItemRows: 1,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,

    draggable: {
      enabled: true,
    },
    resizable: {
      enabled: false,
    },
    pushItems: true,
    pushResizeItems: false,
    swap: true,
    itemResizeCallback: (widget, itemComponent) =>
      this.itemResize(widget, itemComponent),
    itemChangeCallback: (widget, itemComponent) =>
      this.itemChange(widget, itemComponent),
  };
  checked: any;
  datafeedsdata: any[] = [];
  selectedOption: string = '0';
  pinmode = ['Output', 'Input', 'Input_pullup'];
  datastream_type = ['Integer', 'String'];
  oldpin!: boolean;
  loading!: boolean;
  routes!: boolean;
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  propLoader!: boolean;
  countries: any = countries;

  sparklineData = [
    {
      name: 'Germany',
      series: [
        {
          name: '2010',
          value: 40632,
          extra: {
            code: 'de',
          },
        },
        {
          name: '2000',
          value: 36953,
          extra: {
            code: 'de',
          },
        },
        {
          name: '1990',
          value: 31476,
          extra: {
            code: 'de',
          },
        },
      ],
    },
    {
      name: 'United States',
      series: [
        {
          name: '2010',
          value: 0,
          extra: {
            code: 'us',
          },
        },
        {
          name: '2000',
          value: 45986,
          extra: {
            code: 'us',
          },
        },
        {
          name: '1990',
          value: 37060,
          extra: {
            code: 'us',
          },
        },
      ],
    },
    {
      name: 'France',
      series: [
        {
          name: '2010',
          value: 36745,
          extra: {
            code: 'fr',
          },
        },
        {
          name: '2000',
          value: 34774,
          extra: {
            code: 'fr',
          },
        },
        {
          name: '1990',
          value: 29476,
          extra: {
            code: 'fr',
          },
        },
      ],
    },
    {
      name: 'United Kingdom',
      series: [
        {
          name: '2010',
          value: 36240,
          extra: {
            code: 'uk',
          },
        },
        {
          name: '2000',
          value: 32543,
          extra: {
            code: 'uk',
          },
        },
        {
          name: '1990',
          value: 26424,
          extra: {
            code: 'uk',
          },
        },
      ],
    },
  ];
  // line interpolation
  linechartdata = [
    {
      name: 'Monaco',
      series: [
        {
          value: 6292,
          name: '2016-09-20T13:02:53.223Z',
        },
        {
          value: 3899,
          name: '2016-09-20T02:29:20.500Z',
        },
        {
          value: 6660,
          name: '2016-09-18T10:53:02.385Z',
        },
        {
          value: 2124,
          name: '2016-09-24T01:52:22.445Z',
        },
        {
          value: 5750,
          name: '2016-09-14T10:43:59.940Z',
        },
      ],
    },
    {
      name: 'Myanmar',
      series: [
        {
          value: 5017,
          name: '2016-09-20T13:02:53.223Z',
        },
        {
          value: 6500,
          name: '2016-09-20T02:29:20.500Z',
        },
        {
          value: 2314,
          name: '2016-09-18T10:53:02.385Z',
        },
        {
          value: 5762,
          name: '2016-09-24T01:52:22.445Z',
        },
        {
          value: 2256,
          name: '2016-09-14T10:43:59.940Z',
        },
      ],
    },
    {
      name: 'Nauru',
      series: [
        {
          value: 4762,
          name: '2016-09-20T13:02:53.223Z',
        },
        {
          value: 2490,
          name: '2016-09-20T02:29:20.500Z',
        },
        {
          value: 3456,
          name: '2016-09-18T10:53:02.385Z',
        },
        {
          value: 5663,
          name: '2016-09-24T01:52:22.445Z',
        },
        {
          value: 4553,
          name: '2016-09-14T10:43:59.940Z',
        },
      ],
    },
    {
      name: 'Albania',
      series: [
        {
          value: 3861,
          name: '2016-09-20T13:02:53.223Z',
        },
        {
          value: 4641,
          name: '2016-09-20T02:29:20.500Z',
        },
        {
          value: 3429,
          name: '2016-09-18T10:53:02.385Z',
        },
        {
          value: 5002,
          name: '2016-09-24T01:52:22.445Z',
        },
        {
          value: 2827,
          name: '2016-09-14T10:43:59.940Z',
        },
      ],
    },
    {
      name: 'Luxembourg',
      series: [
        {
          value: 3439,
          name: '2016-09-20T13:02:53.223Z',
        },
        {
          value: 4492,
          name: '2016-09-20T02:29:20.500Z',
        },
        {
          value: 5700,
          name: '2016-09-18T10:53:02.385Z',
        },
        {
          value: 5574,
          name: '2016-09-24T01:52:22.445Z',
        },
        {
          value: 5528,
          name: '2016-09-14T10:43:59.940Z',
        },
      ],
    },
  ];
  curveType: string = 'Linear';
  curve: any = this.curves[this.curveType];
  interpolationTypes = [
    'Basis',
    'Bundle',
    'Cardinal',
    'Catmull Rom',
    'Linear',
    'Monotone X',
    'Monotone Y',
    'Natural',
    'Step',
    'Step After',
    'Step Before',
  ];

  closedCurveType: string = 'Linear Closed';
  // closedCurve: any = this.curves[this.closedCurveType];
  closedInterpolationTypes = [
    'Basis Closed',
    'Cardinal Closed',
    'Catmull Rom Closed',
    'Linear Closed',
  ];

  colorSets: any = colorSets;
  colorScheme: any;
  schemeType = ScaleType.Ordinal;
  selectedColorScheme!: string;
  rangeFillOpacity: number = 0.15;

  // Override colors for certain values
  customColors: any[] = [
    {
      name: 'Germany',
      value: '#a8385d',
    },
  ];

  // pie
  showLabels = true;
  explodeSlices = false;
  doughnut = false;
  arcWidth = 0.25;

  // line, area
  autoScale = true;
  timeline = false;

  // margin
  margin: boolean = false;
  marginTop: number = 40;
  marginRight: number = 40;
  marginBottom: number = 40;
  marginLeft: number = 40;

  // box
  boxData = boxData;

  // gauge
  gaugeMin: number = 0;
  gaugeMax: number = 100;
  gaugeLargeSegments: number = 10;
  gaugeSmallSegments: number = 5;
  gaugeTextValue: string = '';
  gaugeUnits: string = 'alerts';
  gaugeAngleSpan: number = 240;
  gaugeStartAngle: number = -120;
  gaugeShowAxis: boolean = true;
  gaugeValue: number = 50; // linear gauge value
  gaugePreviousValue: number = 70;

  // heatmap
  heatmapMin: number = 0;
  heatmapMax: number = 50000;

  // Combo Chart
  // barChart: any[] = barChart;
  // lineChartSeries: any[] = lineChartSeries;
  lineChartScheme: Color = {
    name: 'coolthree',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5'],
  };

  comboBarScheme: Color = {
    name: 'singleLightBlue',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#01579b'],
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Utilization';

  // demos
  totalSales = 0;
  salePrice = 100;
  personnelCost = 100;

  mathText = '3 - 1.5*sin(x) + cos(2*x) - 1.5*abs(cos(x))';
  // mathFunction: (o: any) => any;

  // treemap: any[];
  treemapPath: any[] = [];
  sumBy: string = 'Size';

  // // bubble chart interactive demo
  // bubbleDemoTempData: any[] = [];
  // bubbleDemoChart: BubbleChartInteractiveServerDataModel;
  bubble: any[] = bubble;

  // Reference lines
  showRefLines: boolean = true;
  showRefLabels: boolean = true;

  // Supports any number of reference lines.
  refLines = [
    { value: 42500, name: 'Maximum' },
    { value: 37750, name: 'Average' },
    { value: 33000, name: 'Minimum' },
  ];

  // data
  plotData: any;

  // Sidebar Controls:
  colorVisible: boolean = true;
  dataVisible: boolean = true;
  dimVisible: boolean = true;
  optsVisible: boolean = true;
  costValue: any;

  setColorScheme(name: any) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find((s: any) => s.name === name);
  }

  calendarAxisTickFormatting(mondayString: string) {
    const monday = new Date(mondayString);
    const month = monday.getMonth();
    const day = monday.getDate();
    const year = monday.getFullYear();
    const lastSunday = new Date(year, month, day - 1);
    const nextSunday = new Date(year, month, day + 6);
    return lastSunday.getMonth() !== nextSunday.getMonth()
      ? monthName.format(nextSunday)
      : '';
  }

  calendarTooltipText(c: any): string {
    return `
      <span class="tooltip-label">${c.label
      } • ${c.cell.date.toLocaleDateString()}</span>
      <span class="tooltip-val">${c.data.toLocaleString()}</span>
    `;
  }

  getCalendarData(): any[] {
    // today
    const now = new Date();
    const todaysDay = now.getDate();
    const thisDay = new Date(now.getFullYear(), now.getMonth(), todaysDay);

    // Monday
    const thisMonday = new Date(
      thisDay.getFullYear(),
      thisDay.getMonth(),
      todaysDay - thisDay.getDay() + 1
    );
    const thisMondayDay = thisMonday.getDate();
    const thisMondayYear = thisMonday.getFullYear();
    const thisMondayMonth = thisMonday.getMonth();

    // 52 weeks before monday
    const calendarData = [];
    const getDate = (d: any) => new Date(thisMondayYear, thisMondayMonth, d);
    for (let week = -52; week <= 0; week++) {
      const mondayDay = thisMondayDay + week * 7;
      const monday = getDate(mondayDay);

      // one week
      const series = [];
      for (let dayOfWeek = 7; dayOfWeek > 0; dayOfWeek--) {
        const date = getDate(mondayDay - 1 + dayOfWeek);

        // skip future dates
        if (date > now) {
          continue;
        }

        // value
        const value = dayOfWeek < 6 ? date.getMonth() + 1 : 0;

        series.push({
          date,
          name: weekdayName.format(date),
          value,
        });
      }

      calendarData.push({
        name: monday.toString(),
        series,
      });
    }

    return calendarData;
  }

  getFlag(country: any) {
    return this.countries.find((c: any) => c.name === country)?.emoji;
  }

  onFilter(event: any) {
    console.log('timeline filter', event);
  }

  /**
   * Constructor of the RoutesComponent class.
   *
   * @param {ChangeDetectorRef} cdRef - Reference to the ChangeDetectorRef.
   * @param {MatDialog} matdialog - Reference to the MatDialog service.
   * @param {DomSanitizer} sanitizer - Reference to the DomSanitizer service.
   * @param {Renderer2} renderer - Reference to the Renderer2 service.
   * @param {Dataservice} dataservice - Reference to the Dataservice.
   * @param {AuthenticationService} authentication - Reference to the AuthenticationService.
   * @param {ToastrService} toastr - Reference to the ToastrService for displaying toast messages.
   * @param {HomeComponent} homecomponent - Reference to the HomeComponent.
   * @param {FormBuilder} formbuilder - Reference to the FormBuilder.
   * @param {ElementRef} el - Reference to the ElementRef.
   */
  constructor(
    private cdRef: ChangeDetectorRef,
    private matdialog: MatDialog,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private dataservice: Dataservice,
    private authentication: AuthenticationService,
    private toastr: ToastrService,
    private homecomponent: HomeComponent,
    private formbuilder: FormBuilder,
    private el: ElementRef
  ) {
    this.firstflag = true;
    this.remove = false;
    this.FirstSave = false;
    this.lastRemove = false;
    this.design = true;
    this.oldpin = true;
    this.loading = true;
    this.routes = false;
    this.propLoader = false;
    this.chartGroups = chartGroups;
    this.Single = single;
    this.multi = multi;
    this.setColorScheme('cool');
    this.calendarData = this.getCalendarData();

    // Get user information from AuthenticationService
    const user = this.authentication.getUserData();
    const org_Id = user.org_Id;
    const vertical_Id = user.vertical_Id;
    // Extract user roles for permission checks
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[12]; // index 12 corresponds to the roles related to routes:
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
    // Fetch device and product data from Dataservice
    this.dataservice.getdevicepageproductdata(user).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res?.data;
        this.productvalue = this.data;
        this.loading = false;
      } else {
        this.toastr.error('Error occurred');
      }
    });
    // Subscribe to ClusterCreate event to update data on changes
    this.dataservice.ClusterCreate.subscribe((res: any) => {
      this.dataservice
        .getdevicepageproductdata(user)
        .subscribe(async (res: any) => {
          if (res.status == '200') {
            this.data = res?.data;
            this.productvalue = this.data;
            await this.getproductdata();
          } else {
            this.toastr.error('Error occurred');
          }
        });
    });
    // Fetch user email and number data from Dataservice
    const id = { org_Id, vertical_Id };

    this.dataservice.useremail(id).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.data.map((x: any) => {
          this.useremail.push({ email: x.email_id, user_Id: x.user_Id });
        });
      } else {
        this.toastr.error('error occurred');
      }
    });

    this.dataservice.usernumber(id).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.data.map((x: any) => {
          this.usernumber.push({
            Name: x.firstname,
            user_Id: x.user_Id,
            number: x.phone_num,
          });
        });
      } else {
        this.toastr.error('error occurred');
      }
    });
    // Initialize filteredUser and filteredUserNumber for auto-complete functionality
    this.filteredUser = this.UserCtrl.valueChanges.pipe(
      startWith(null),
      map((user: any) => (user ? this._filter(user) : this.useremail.slice()))
    );
    this.filteredUserNumber = this.UserNumberCtrl.valueChanges.pipe(
      startWith(null),
      map((user: any) =>
        user ? this._filterNumber(user) : this.usernumber.slice()
      )
    );
  }

  /**
   * Adds an email to the list of selected user emails when a chip is added.
   *
   * @param {MatChipInputEvent} event - The MatChipInputEvent triggered when a chip is added.
   */
  addEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();
    // Check if the email value exists in the useremail array
    const foundItem = this.useremail.find((x: any) => {
      return x.email === value;
    });
    // If the email value exists, add it to the user_Id array and clear the input
    if (foundItem) {
      this.user_Id.push({ email: value, user_Id: foundItem.user_Id });
      if (input) {
        input.value = '';
      }
      // Reset the autocomplete input value
      this.UserCtrl.setValue(null);
    }
  }
  /**
   * Adds a phone number to the list of selected user numbers when a chip is added.
   *
   * @param {MatChipInputEvent} event - The MatChipInputEvent triggered when a chip is added.
   */

  addNumber(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();
    // Check if the phone number value exists in the usernumber array
    const foundItem = this.usernumber.find((x: any) => {
      return x.Name === value;
    });
    // If the phone number value exists, add it to the User_Number array and clear the input
    if (foundItem) {
      this.User_Number.push({ Name: value, user_Id: foundItem.user_Id });
      if (input) {
        input.value = '';
      }
      // Reset the autocomplete input value
      this.UserCtrl.setValue(null);
    }
  }
  /**
   * Removes an email from the list of selected user emails when a chip is removed.
   *
   * @param {any} user - The user object associated with the chip being removed.
   * @param {any} user_Id - The user Id associated with the chip being removed.
   */
  removeeEmail(user: any, user_Id: any): void {
    // Find the user_Id object in the user_Id array based on the provided user_Id value
    const foundItem = this.user_Id.find((x: any) => {
      return Number(x.user_Id) === Number(user_Id);
    });
    // If the user_Id object is found, remove it from the user_Id array
    if (foundItem) {
      const index = this.user_Id.indexOf(foundItem);
      if (index >= 0) {
        this.user_Id.splice(index, 1);
      }
    }
  }
  /**
   * Removes an email from the list of selected user number when a chip is removed.
   *
   * @param {any} user - The user object associated with the chip being removed.
   * @param {any} user_Id - The user Id associated with the chip being removed.
   */
  removeeNumber(user: any, user_Id: any): void {
    const foundItem = this.User_Number.find((x: any) => {
      return Number(x.user_Id) === Number(user_Id);
    });

    if (foundItem) {
      const index = this.User_Number.indexOf(foundItem);
      if (index >= 0) {
        this.User_Number.splice(index, 1);
      }
    }
  }
  /**
   * Handles the selection of an email from the autocomplete dropdown and adds it to the list of selected user emails.
   *
   * @param {MatAutocompleteSelectedEvent} event - The MatAutocompleteSelectedEvent triggered when an option is selected from the autocomplete dropdown.
   */
  selectedEmail(event: MatAutocompleteSelectedEvent): void {
    this.user_Id.push({
      email: event.option.viewValue,
      user_Id: event.option.value,
    });
    this.userInput.nativeElement.value = '';
    this.UserCtrl.setValue(null);
  }

  /**
   * Handles the selection of a phone number from the autocomplete dropdown and adds it to the list of selected user numbers.
   *
   * @param {MatAutocompleteSelectedEvent} event - The MatAutocompleteSelectedEvent triggered when an option is selected from the autocomplete dropdown.
   */
  selectedNumber(event: MatAutocompleteSelectedEvent): void {
    this.User_Number.push({
      Name: event.option.viewValue,
      user_Id: event.option.value,
    });
    this.userInput.nativeElement.value = '';
    this.UserNumberCtrl.setValue(null);
  }

  /**
   * Filters the useremail array based on the provided value.
   *
   * @private
   * @param {any} value - The value used for filtering.
   * @returns {any[]} - An array of filtered user emails.
   */
  private _filter(value: any) {
    if (value >= 0) {
      return this.useremail.filter((user: any) => user.user_Id === value);
    } else {
      const filterValue = value.toLowerCase();

      return this.useremail.filter(
        (user: any) => user.email.toLowerCase().indexOf(filterValue) === 0
      );
    }
  }
  /**
   * Filters the usernumber array based on the provided value.
   *
   * @private
   * @param {any} value - The value used for filtering.
   * @returns {any[]} - An array of filtered user numbers.
   */
  private _filterNumber(value: any) {
    if (value >= 0) {
      return this.usernumber.filter((user: any) => user.user_Id === value);
    } else {
      const filterValue = value.toLowerCase();

      return this.usernumber.filter(
        (user: any) => user.Name.toLowerCase().indexOf(filterValue) === 0
      );
    }
  }




  onToggleChange(event: any, Datafeeds: any) {
    if (event.checked == true) {
      if (this.addresscount == 0) {
        this.AddAddress(Datafeeds, 'Single')
      }
      if (Datafeeds == 'Digital') {
        this.digitaldatastream.controls.isaddress.setValue('1')
      } else if (Datafeeds == 'Analog') {
        this.analogdatastream.controls.isaddress.setValue('1')
      } else if (Datafeeds == 'Virtual') {
        this.virtualpindatastream.controls.isaddress.setValue('1')
      }else if(Datafeeds=='gauge'){
        this.gaugedatastream.controls.markers.setValue('1')
      }

    } else {

      if (Datafeeds == 'Digital') {
        this.digitaldatastream.controls.isaddress.setValue('0')
      } else if (Datafeeds == 'Analog') {
        this.analogdatastream.controls.isaddress.setValue('0')
      } else if (Datafeeds == 'Virtual') {
        this.virtualpindatastream.controls.isaddress.setValue('0')
      }else if(Datafeeds=='gauge'){
        this.gaugedatastream.controls.markers.setValue('0')
      }
      let loopcount = this.addresscount;
      for (let i = 1; i <= loopcount; i++) {
        let removeitem = 'address' + '-' + i;
        this.RemoveAddress(removeitem, Datafeeds);

      }
      this.addresscount = 0;


    }
  }


  /**
   * Updates the Gridster options based on the window width to provide responsive behavior for grid sizing.
   * The method adjusts the `fixedColWidth` and `fixedRowHeight` properties of the Gridster options
   * based on the current window width to achieve responsive grid behavior.
   */

  private updateGridsterOptions() {
    console.log('window.innerWidth:', window.innerWidth);
    if (window.innerWidth >= 2560 && window.innerWidth <= 3840) {
      this.gridsterOptions.fixedColWidth = 150;
      this.gridsterOptions.fixedRowHeight = 140;

      console.log('fixedColWidth set to 120');
    } else if (window.innerWidth >= 1920 && window.innerWidth < 2560) {
      this.gridsterOptions.fixedColWidth = 110;
      this.gridsterOptions.fixedRowHeight = 120;

      console.log('fixedColWidth set to 200');
    } else {
      this.gridsterOptions.fixedColWidth = 80;

      console.log('fixedColWidth set to 100');
    }
  }
  /**
   * Called when an item in the Gridster layout changes its position.
   * @param widget - The widget (item) that changed.
   * @param itemComponent - The component representing the item.
   */

  itemChange(widget: any, itemComponent: any) {
    this.design = true;
  }

  /**
   * Called when an item in the Gridster layout is resized.
   * @param widget - The widget (item) being resized.
   * @param itemComponent - The component representing the resized item.
   */
  itemResize(
    widget: GridsterItem,
    itemComponent: GridsterItemComponentInterface
  ) {
    this.design = true;
  }
  /**
   * Handles the change event of a toggle switch or checkbox.
   * @param event - The change event object.
   */
  toggleChanges(event: any) {
    this.checked = event.source.checked;
  }

  /**
   * Opens the side navigation panel and adjusts the layout options.
   */
  Sidenavopen() {
    this.gridsterOptions.fixedColWidth = 70;
    this.gridsterOptions.fixedRowHeight = 100;
    this.options.draggable = !this.options.draggable;
    this.options.resizable = !this.options.resizable;
    this.gridsteredit.optionsChanged();
  }
  /**
   * Closes the side navigation panel and restores layout options.
   */
  Sidenavclose() {
    this.gridsterOptions.fixedColWidth = 75;
    this.gridsterOptions.fixedRowHeight = 100;
    this.options.draggable = !this.options.draggable;
    this.options.resizable = !this.options.resizable;
    this.gridsteredit.optionsChanged();
  }

  // decimallist: any[] = [
  //   '#',
  //   '#.#',
  //   '#.##',
  //   '#.###',
  //   '#.####',
  //   '#.#####',
  //   '#.0',
  //   '#.00',
  //   '#.000',
  //   '#.0000',
  //   '#.00000',
  // ];

  // digital datastream
  digitaldatastream: any = this.formbuilder.group({
    datastream_name: ['Digital'],
    node_name: ['Digital'],
    datastream_Id: ['0'],
    datastream_type: ['Integer'],
    icon: [''],
    name: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    alias: [
      'Digital 0',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],


    pin: ['', Validators.required],
    pinmode: ['', Validators.required],
    decimal_value: [''],
    isdelete: ['0'],
    isaddress: ['0'],


    addresscount: [this.addresscount]

  });

  // Analog datastream form
  analogdatastream: any = this.formbuilder.group({
    datastream_name: ['Analog'],

    node_name: ['Analog'],
    datastream_Id: ['0'],
    datastream_type: ['Integer'],
    icon: [''],
    name: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    alias: [
      'Analog A0',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],

    pin: ['', Validators.required],
    pinmode: ['', Validators.required],
    units: ['', Validators.required],

    default_value: ['0', Validators.required],
    decimal_value: [''],
    isdelete: ['0'],
    isaddress: ['0'],


    addresscount: [this.addresscount]
  });

  // Virtual pin form
  virtualpindatastream: any = this.formbuilder.group({
    node_name: ['Virtual'],
    datastream_name: ['Virtual'],
    datastream_Id: ['0'],
    icon: [''],
    name: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    alias: [
      'Integer V0',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],

    pin: ['', Validators.required],
    datastream_type: ['', Validators.required],
    // units: ['', Validators.required],

    pinmode: [''],

    // decimal_value: ['', Validators.required],
    default_value: ['0', Validators.required],
    isdelete: ['0'],
    isaddress: ['0'],


    addresscount: [this.addresscount]
  });

  // SWITCH DATASTREAM
  switchdatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    onvalue: ['1'],
    Offvalue: ['0'],
    color: [''],
    onlabel: [''],
    offlabel: [''],
    labelposition: [''],
    widgetnamedisplay: [''],
    widget_Id: [''],
    node_name: ['Switch'],
    widgetname: ['Switch'],
  });

  // LED DATASTREAM
  leddatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    color: [''],
    widget_Id: [''],
    node_name: ['LED'],
    widgetname: ['LED'],
  });

  // LABELDATASTREAM
  labeldatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    additional_option: [''],
    emissionfactor: [''],
    color: [''],
    node_name: ['Label'],
    widgetname: ['Label'],
    Max_kwh: ['0'],
    units: ['']
  });
  showKwhCostInputs: boolean = false;
  // COSTDATASTREAM
  // COSTDATASTREAM
  costdatastream: FormGroup = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    color: [''],
    node_name: ['Cost'],
    widgetname: ['Cost'],
    additional_option: ['Cost'],
    blling_date: [new Date(), Validators.required],

    costcount: [this.costcount],
  });

  // SLIDERDATASTREAM
  sliderdatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: [''],
    widget_Id: [''],
    color: [''],
    handlestep: ['', Validators.required],
    node_name: ['Slider'],
    Min: ['0', Validators.required],
    Max: ['255', Validators.required],
    Default_value: ['0'],
    widgetname: ['Slider'],
  });

  // SPEEDOMETER
  speedometer = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    color: [''],

    node_name: ['Speedometer'],
    Min: ['0', [Validators.required,Validators.min(0),Validators.max(100)]],
    Max: ['100', [Validators.required,Validators.max(100),Validators.min(0)]],
    widgetname: ['Speedometer'],
    additional_option: [''],
    units: ['']
  });

  // GAUGEDATASTREAM
  gaugedatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    color: [''],
    gaugeType: ['arch'],
    node_name: ['Gauge'],
    Min: ['0', Validators.required],
    Max: ['1000', Validators.required],
    widgetname: ['Gauge'],
    additional_option: [''],
    units: [''],
    markers:['1']
  });
  // GAUGE 3 phaseDATASTREAM
  gauge3Phasedatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    color: [''],
    gaugeType: ['arch'],
    node_name: ['Gauge3Phase'],
    Min: ['0', Validators.required],
    Max: ['1000', Validators.required],
    widgetname: ['Gauge3Phase'],
    unit1: ['', Validators.required],
    unit2: ['', Validators.required],
    unit3: ['', Validators.required]
  });
  piechartdatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    node_name: ['piechart'],
    widgetname: ['piechart'],
  });
  // TERMINAL DATASTREAM
  terminaldatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    inputhint: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    textcolor: [''],
    screencolor: [''],
    node_name: ['Terminal'],
    widgetname: ['Terminal'],
  });

  // CHART DATASTREAM
  Chartdatastream: FormGroup = this.formbuilder.group({
    title: [
      'Chart',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    chart_type: ['', Validators.required],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    Min: ['0', Validators.required],
    Max: ['1', Validators.required],
    autoScale: [false],
    timeseries: ['Default Timezone'],
    node_name: ['Chart'],
    label: [
      'Legend',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    widgetname: ['Chart'],
    color_scheme: ['cool'],
    chartfilter: ['Day'],
    xAxisLabel: [
      "X'axis",
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    yAxisLabel: [
      "Y'axis",
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
  });
  // Email
  event = this.formbuilder.group({
    event_Id: ['0'],
    event_name: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    Notification: [''],
    event_type: [''],
    devices: ['0'],
    color: [''],
    node_name: ['Notifications'],
    phone: [''],
    event_message_delay: ['0'],
    event_message_count: [''],
    user_email: [''],
    user_number: [''],
    analog_min: [''],
    analog_max: [''],
    analog_leftmin: [''],
    analog_leftmax: [''],
    analog_rightmin: [''],
    analog_rightmax: [''],
    digital_value: [''],
    analog_type: ['0'],
    method: [''],
    pin: [''],
    notify_type: [''],
    isactive: ['0'],
    virtual_datatype: [''],

    // virtual
    integer_value: [''],
    string_value: [''],
    St_Value: [''],
    Int_Value: [''],
    virtual_type: [''],
    virtual_value: [''],
    virtual_min: [''],
    virtual_max: [''],
    start_value: [''],
    end_value: [''],
    additional_option: [''],
  });

  Thermometerdatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    node_name: ['Thermometer'],
    widgetname: ['Thermometer'],
  });

  Weatherdatastream = this.formbuilder.group({
    title: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(15)],
    ],
    // datastream_Id: ['', Validators.required],
    widget_Id: [''],
    node_name: ['Weather'],
    widgetname: ['Weather'],
  });

  AutoScale(event: any) {
    this.Chartdatastream.controls['autoScale'].setValue(event.checked);
  }

  AddCost(type: any) {
    if (type == 'Inbetween') {
      if (this.costcount == 0) {
        ++this.costcount;
        // let name = 'cost-' + (+this.costcount - 1);
        const newcontrol: AbstractControl = this.formbuilder.group({
          fromkwh: [0, Validators.required],
          tokwh: ['', Validators.required],
          cost: ['', Validators.required],
          type: [type],
        });

        this.costdatastream.controls['costcount'].setValue(this.costcount);
        this.costdatastream.addControl(
          'cost' + '-' + this.costcount,
          newcontrol
        );
        this.costlist.push({
          Groupname: 'cost' + '-' + this.costcount,
          type: type,
        });
      } else {
        let lasttype = 'cost-' + this.costcount;
        if (this.costdatastream.value[lasttype].type != 'Above') {
          ++this.costcount;
          let name = 'cost-' + (+this.costcount - 1);
          const newcontrol: AbstractControl = this.formbuilder.group({
            fromkwh: [
              this.costdatastream.value[name].tokwh,
              Validators.required,
            ],
            tokwh: [
              '',
              [
                Validators.required,
                Validators.min(this.costdatastream.value[name].tokwh),
              ],
            ],
            cost: ['', Validators.required],
            type: [type],
          });

          this.costdatastream.controls['costcount'].setValue(this.costcount);
          this.costdatastream.addControl(
            'cost' + '-' + this.costcount,
            newcontrol
          );
          this.costlist.push({
            Groupname: 'cost' + '-' + this.costcount,
            type: type,
          });
        } else {
          this.toastr.info(
            'Exclude the last CostValue added in the previous type, and do not include any intermediate CostValues.'
          );
        }
      }
    } else if (type == 'Above') {
      if (this.costcount == 0) {
        ++this.costcount;
        const newcontrol: AbstractControl = this.formbuilder.group({
          above_Kwh: ['', Validators.required],
          cost: ['', Validators.required],
          type: [type],
        });
        this.costdatastream.controls['costcount'].setValue(this.costcount);
        this.costdatastream.addControl(
          'cost' + '-' + this.costcount,
          newcontrol
        );
        this.costlist.push({
          Groupname: 'cost' + '-' + this.costcount,
          type: type,
        });
      } else {
        let lasttype = 'cost-' + this.costcount;
        if (this.costdatastream.value[lasttype].type != 'Above') {
          ++this.costcount;
          let name = 'cost-' + (+this.costcount - 1);
          const newcontrol: AbstractControl = this.formbuilder.group({
            above_Kwh: [
              this.costdatastream.value[name].tokwh,
              Validators.required,
            ],
            cost: ['', Validators.required],
            type: [type],
          });
          this.costdatastream.controls['costcount'].setValue(this.costcount);
          this.costdatastream.addControl(
            'cost' + '-' + this.costcount,
            newcontrol
          );
          this.costlist.push({
            Groupname: 'cost' + '-' + this.costcount,
            type: type,
          });
        } else {
          this.toastr.info(
            "The last CostValue added in the above type should not be included in the above type's cost values."
          );
        }
      }
    }
  }

  RemoveCost(item: any) {
    this.costdatastream.removeControl(item);

    --this.costcount;

    const findIndex = this.costlist.findIndex(
      (item: any) => item.Groupname == item
    );

    this.costdatastream.controls['costcount'].setValue(this.costcount);
    this.costlist.splice(findIndex, 1);
  }





  AddAddress(datafeeds: any, type: any) {
    if (type == 'Single') {
      if (datafeeds == 'Digital') {

        ++this.addresscount;
        // let name = 'cost-' + (+this.costcount - 1);
        const newcontrol: AbstractControl = this.formbuilder.group({
          devices: [[this.slavedevices[0]?.device_Id], Validators.required],
          address: ['', Validators.required],
          offset: ['', Validators.required],
          params: ['', Validators.required],
          datatype: ['1', Validators.required],
          program: ['1', Validators.required],
          holding_register: ['0', Validators.required],
          endianness:['1',Validators.required],
          type: [type]
        });

        this.digitaldatastream.controls['addresscount'].setValue(this.addresscount);
        this.digitaldatastream.addControl(
          'address-' + this.addresscount,
          newcontrol
        );
        this.addresslist.push({
          Groupname: 'address-' + this.addresscount,
          type: type
        });

      } else if (datafeeds == 'Analog') {
        ++this.addresscount;
        // let name = 'cost-' + (+this.costcount - 1);
        const newcontrol: AbstractControl = this.formbuilder.group({
          devices: [[this.slavedevices[0]?.device_Id], Validators.required],
          address: ['', Validators.required],
          offset: ['', Validators.required],
          params: ['', Validators.required],
          datatype: ['1', Validators.required],
          program: ['1', Validators.required],
          holding_register: ['0', Validators.required],
          endianness:['1',Validators.required],
          type: [type]
        });

        this.analogdatastream.controls['addresscount'].setValue(this.addresscount);
        this.analogdatastream.addControl(
          'address-' + this.addresscount,
          newcontrol
        );
        this.addresslist.push({
          Groupname: 'address-' + this.addresscount,
          type: type
        });

      } else if (datafeeds == 'Virtual') {
        ++this.addresscount;
        // let name = 'cost-' + (+this.costcount - 1);
        const newcontrol: AbstractControl = this.formbuilder.group({
          devices: [[this.slavedevices[0]?.device_Id], Validators.required],
          address: ['', Validators.required],
          offset: ['', Validators.required],
          params: ['', Validators.required],
          datatype: ['1', Validators.required],
          program: ['1', Validators.required],
          holding_register: ['0', Validators.required],
          endianness:['1',Validators.required],
          type: [type]
        });

        this.virtualpindatastream.controls['addresscount'].setValue(this.addresscount);
        this.virtualpindatastream.addControl(
          'address-' + this.addresscount,
          newcontrol
        );
        this.addresslist.push({
          Groupname: 'address-' + this.addresscount,
          type: type
        });
      }
    } else if (type == 'Multiple') {

      if (datafeeds == 'Digital') {

        ++this.addresscount;
        // let name = 'cost-' + (+this.costcount - 1);
        const newcontrol: AbstractControl = this.formbuilder.group({
          devices: [[this.slavedevices[0]?.device_Id], Validators.required],
          address1: ['', Validators.required],
          address2: ['', Validators.required],
          address3: ['', Validators.required],
          offset1: ['', Validators.required],
          offset2: ['', Validators.required],
          offset3: ['', Validators.required],
          params: ['', Validators.required],
          params1: ['R', Validators.required],
          params2: ['Y', Validators.required],
          params3: ['B', Validators.required],
          datatype: ['1', Validators.required],
          program: ['1', Validators.required],
          holding_register: ['0', Validators.required],
          endianness:['1',Validators.required],
          type: [type]
        });

        this.digitaldatastream.controls['addresscount'].setValue(this.addresscount);
        this.digitaldatastream.addControl(
          'address-' + this.addresscount,
          newcontrol
        );
        this.addresslist.push({
          Groupname: 'address-' + this.addresscount,
          type: type
        });

      } else if (datafeeds == 'Analog') {
        ++this.addresscount;
        // let name = 'cost-' + (+this.costcount - 1);
        const newcontrol: AbstractControl = this.formbuilder.group({
          devices: [[this.slavedevices[0]?.device_Id], Validators.required],
          address1: ['', Validators.required],
          address2: ['', Validators.required],
          address3: ['', Validators.required],
          offset1: ['', Validators.required],
          offset2: ['', Validators.required],
          offset3: ['', Validators.required],
          params: ['', Validators.required],
          params1: ['R', Validators.required],
          params2: ['Y', Validators.required],
          params3: ['B', Validators.required],
          datatype: ['1', Validators.required],
          program: ['1', Validators.required],
          holding_register: ['0', Validators.required],
          endianness:['1',Validators.required],
          type: [type]
        });

        this.analogdatastream.controls['addresscount'].setValue(this.addresscount);
        this.analogdatastream.addControl(
          'address-' + this.addresscount,
          newcontrol
        );
        this.addresslist.push({
          Groupname: 'address-' + this.addresscount,
          type: type
        });

      } else if (datafeeds == 'Virtual') {
        ++this.addresscount;
        // let name = 'cost-' + (+this.costcount - 1);
        const newcontrol: AbstractControl = this.formbuilder.group({
          devices: [[this.slavedevices[0]?.device_Id], Validators.required],
          address1: ['', Validators.required],
          address2: ['', Validators.required],
          address3: ['', Validators.required],
          offset1: ['', Validators.required],
          offset2: ['', Validators.required],
          offset3: ['', Validators.required],
          params: ['', Validators.required],
          params1: ['R', Validators.required],
          params2: ['Y', Validators.required],
          params3: ['B', Validators.required],
          datatype: ['1', Validators.required],
          program: ['1', Validators.required],
          holding_register: ['0', Validators.required],
          endianness:['1',Validators.required],
          type: [type]
        });

        this.virtualpindatastream.controls['addresscount'].setValue(this.addresscount);
        this.virtualpindatastream.addControl(
          'address-' + this.addresscount,
          newcontrol
        );
        this.addresslist.push({
          Groupname: 'address-' + this.addresscount,
          type: type
        });
      }

    }


  }


  RemoveAddress(item: any, datafeeds: any) {
    if (datafeeds == 'Digital') {
      this.digitaldatastream.removeControl(item);
      --this.addresscount;
      const findIndex = this.addresslist.findIndex(
        (item: any) => item.Groupname == item
      );
      this.digitaldatastream.controls.addresscount.setValue(this.costcount);
      this.addresslist.splice(findIndex, 1);
    } else if (datafeeds == 'Analog') {
      this.analogdatastream.removeControl(item);
      --this.addresscount;
      const findIndex = this.addresslist.findIndex(
        (item: any) => item.Groupname == item
      );
      this.analogdatastream.controls.addresscount.setValue(this.costcount);
      this.addresslist.splice(findIndex, 1);

    } else if (datafeeds == 'Virtual') {
      this.virtualpindatastream.removeControl(item);
      --this.addresscount;
      const findIndex = this.addresslist.findIndex(
        (item: any) => item.Groupname == item
      );
      this.virtualpindatastream.controls.addresscount.setValue(this.costcount);
      this.addresslist.splice(findIndex, 1);

    }

  }



  async removeAddressControls(datafeeds: any) {
    if (datafeeds == 'Digital') {
      // Iterate over form controls and remove those with keys starting with 'address-'
      Object.keys(this.digitaldatastream.controls).forEach(controlKey => {
        if (controlKey.startsWith('address-')) {
          this.digitaldatastream.removeControl(controlKey);
        }
      });
    } else if (datafeeds == 'Analog') {
      // Iterate over form controls and remove those with keys starting with 'address-'
      Object.keys(this.analogdatastream.controls).forEach(controlKey => {
        if (controlKey.startsWith('address-')) {
          this.analogdatastream.removeControl(controlKey);
        }
      });
    } else if (datafeeds == 'Virtual') {
      // Iterate over form controls and remove those with keys starting with 'address-'
      Object.keys(this.virtualpindatastream.controls).forEach(controlKey => {
        if (controlKey.startsWith('address-')) {
          this.virtualpindatastream.removeControl(controlKey);
        }
      });
    }

  }

  /**
   * Email enabe or disable
   */
  onchangeemailevent(event: any) {
    if (event.checked) {
      this.emailToggle = true;
      this.notify_type.push('0');
    } else {
      this.emailToggle = false;
      const find = this.notify_type.find((x: any) => {
        return x === '0';
      });
      if (find) {
        const index = this.notify_type.indexOf(find);
        this.notify_type.splice(index, 1);
      }

      if (this.useremail.length >= 1) {
        this.user_Id = [];
      }
    }
  }
  /**
   * Handles the change event of an SMS checkbox.
   * @param event - The change event object.
   */
  onchangsmseevent(event: any) {
    if (event.checked) {
      this.smstoogle = true;
      this.notify_type.push('1');
    } else {
      this.smstoogle = false;
      const find = this.notify_type.find((x: any) => {
        return x === '1';
      });
      if (find) {
        const index = this.notify_type.indexOf(find);
        this.notify_type.splice(index, 1);
      }
    }
  }
  /**
   * Handles the change event of the analog type selection.
   * @param event - The change event object.
   */
  onOptionChange(event: Event) {
    const option = (event.target as HTMLSelectElement).value;
    this.analog_type = option;

    const analogMinControl = this.event.get('analog_min');
    const analogMaxControl = this.event.get('analog_max');
    const analogLeftMinControl = this.event.get('analog_leftmin');
    const analogLeftMaxControl = this.event.get('analog_leftmax');
    const analogRightMinControl = this.event.get('analog_rightmin');
    const analogRightMaxControl = this.event.get('analog_rightmax');

    // Reset the values of other option when switching
    if (this.analog_type === '0') {
      // If 'Min, Max' option is selected, clear values of range option
      analogLeftMinControl!.setValue('');
      analogLeftMaxControl!.setValue('');
      analogRightMinControl!.setValue('');
      analogRightMaxControl!.setValue('');
    } else {
      console.log(this.analog_type);
      // If 'Range' option is selected, clear values of min, max option
      analogMinControl!.setValue('');
      analogMaxControl!.setValue('');
    }

    // Clear previous validators
    analogMinControl!.clearValidators();
    analogMaxControl!.clearValidators();
    analogLeftMinControl!.clearValidators();
    analogLeftMaxControl!.clearValidators();
    analogRightMinControl!.clearValidators();
    analogRightMaxControl!.clearValidators();

    if (this.analog_type === '0') {
      // If 'Min, Max' option is selected, add validators for analog_min and analog_max
      analogMinControl!.setValidators([Validators.required]);
      analogMaxControl!.setValidators([Validators.required]);
    } else {
      // If 'Range' option is selected, add validators for analog_leftmin, analog_leftmax, analog_rightmin, analog_rightmax
      analogLeftMinControl!.setValidators([Validators.required]);
      analogLeftMaxControl!.setValidators([Validators.required]);
      analogRightMinControl!.setValidators([Validators.required]);
      analogRightMaxControl!.setValidators([Validators.required]);
    }

    // Update the controls' validation status
    analogMinControl!.updateValueAndValidity();
    analogMaxControl!.updateValueAndValidity();
    analogLeftMinControl!.updateValueAndValidity();
    analogLeftMaxControl!.updateValueAndValidity();
    analogRightMinControl!.updateValueAndValidity();
    analogRightMaxControl!.updateValueAndValidity();
  }

  randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getTemp() {
    this.temp = this.randomInteger(0, 90);
    this.topvalue = this.temp + '%';
    if (this.temp <= 30) {
      this.tcolor = 'green';
    } else if (this.temp > 30 && this.temp <= 60) {
      this.tcolor = 'yellow';
    } else if (this.temp > 60) {
      this.tcolor = 'red';
    }
  }

  /**
   * Angular lifecycle hook: Executed when the component is initialized.
   */

  async ngOnInit() {

    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];
    this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      "defaultOpen": false,
      idField: 'device_Id',
      textField: 'device_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      "enableCheckAll": true,
      allowSearchFilter: true
    };
    this.id = setInterval(() => {
      this.getTemp();
    }, 4000);

    // setTimeout(() => {
    //   this.chart.refresh();
    // }, 100);

    this.piedata = {
      labels: ['A', 'B', 'C'],
      datasets: [
        {
          data: [540, 325, 702],
        },
      ],
    };

    this.pieoptions = {
      plugins: {
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true,
          },
        },
      },
    };
    this.timelineFilterBarData = timelineFilterBarData();
    this.Single = [
      {
        extra: { code: 'de' },
        name: 'Germany',
        value: 402,
      },
      {
        extra: { code: 'us' },
        name: 'america',
        value: 444,
      },
      {
        extra: { code: 'aus' },
        name: 'australia',
        value: 406,
      },
      {
        extra: { code: 'ind' },
        name: 'india',
        value: 400,
      },
    ];
    this.dataservice.setPaginationState(null, 'all');
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();
    // Call the 'routes' method from the 'homecomponent'
    this.homecomponent.routes();
    // if (this.isread) {
    // Initialize Drawflow editor
    this.id = document.getElementById('drawflow');
    this.editor = new Drawflow(this.id);
    this.registerEvents(this.editor);
    this.editor.reroute = true;
    this.editor.drawflow = this.drawflow();
    this.editor.start();
    // this.editor.zoom_max = 1.2;
    // this.editor.zoom_min = 0.9;
    // this.editor.zoom_value = 0.1;
    // Set up touch event listeners for draggable elements
    const elements = document.getElementsByClassName('drag-drawflow');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('touchend', this.drop, false);
      elements[i].addEventListener('touchmove', this.positionMobile, false);
      elements[i].addEventListener('touchstart', this.drag, false);
    }

    // Subscribe to Sidenav close event
    this.dataservice.Sidenavclose.subscribe((response: any) => {
      this.Sidenavclose();
    });
    // Subscribe to Sidenav open event

    this.dataservice.Sidenavopen.subscribe((response: any) => {
      this.Sidenavopen();
    });
    // Subscribe to changes in 'analog_type' control value
    this.event.get('analog_type')!.valueChanges.subscribe((x: any) => {
      this.analog_type = x;
    });

    // virtual get values
    // Subscribe to the value changes of the select field
    this.event.get('integer_value')!.valueChanges.subscribe((value) => {
      this.selectedValue = value;
      // console.log(this.selectedValue);
      if (this.selectedValue == '6') {
        this.event.controls.virtual_max.reset();
        this.event.controls.virtual_min.reset();
      }
      if (this.selectedValue == '7') {
        this.event.controls.virtual_max.reset();
        this.event.controls.virtual_min.reset();
      }
    });

    this.event.get('event_type')!.valueChanges.subscribe((value) => {
      this.selectedValue = value;

      if (this.selectedValue == '0') {
        this.eventcolor = '#0dcaf0';
      } else if (this.selectedValue == '1') {
        this.eventcolor = '#ffc107';
      } else {
        this.eventcolor = '#dc3545';
      }
    });

    // Set chart options for displaying time-based data

    const documentStyle = getComputedStyle(document.documentElement);
    // const textColor = documentStyle.getPropertyValue('--text-color');
    // const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    // const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.chartoptions = {
      responsive: true,
      scales: {
        xAxes: [
          {
            type: 'time',
            distribution: 'linear',
            display: true,
            time: {
              displayFormats: {
                millisecond: 'h:mm:ss.SSS a',
                second: 'h:mm:ss a',
                minute: 'h:mm:ss a',
                hour: 'h:mm:ss a',
                day: 'MMM D',
                week: 'll',
                month: 'MMM YYYY',
                quarter: '[Q]Q - YYYY',
                year: 'YYYY',
              },
              unit: 'hour',
              tooltipFormat: 'h:mm:ss a',
            },
            ticks: {
              source: 'auto',
              autoSkip: true,
              maxTicksLimit: 12,
            },
          },
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
          },
        ],
      },
    };
    // Set data and options for the basic chart
    this.basicData = {
      labels: this.chartlabelvalue,
      datasets: [
        {
          label: this.chartlabel,
          data: [
            this.Chartdatastream.value.Min,
            this.Chartdatastream.value.Max,
          ],
          fill: false,
          tension: 0.4,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
        },
      ],
    };

    // GET cluster DATA
    await this.getproductdata();
    // Perform necessary actions on data refresh
    this.dataservice.Routesrefresh.subscribe(async (response: any) => {
      // if(this.routesrefresh){
      //   this.routesrefresh=false;
      this.firstflag = true;
      this.remove = false;
      this.newdatacome = [];
      console.log('routesrefresh');
      // GET cluster DATA
      await this.getproductdata();
      // }
    });

    // units
    this.dataservice.getdatastreamunits().subscribe((res: any) => {
      if (res.status == '200') {
        this.units = res.data;
      } else {
        this.toastr.error('error occured');
      }
    });
    // Subscribe to changes in 'datastream_type' control value for virtual pin
    this.virtualpindatastream.controls.datastream_type.valueChanges.subscribe(
      (x: any) => {
        if (x == 'Integer') {
          this.event.controls.integer_value.setValue('Greater than');
          this.event.controls.string_value.setValue('');
          this.event.controls.St_Value.setValue('');
          this.event.controls.St_Value.clearValidators(); // Corrected syntax
        } else if (x == 'String') {
          this.event.controls.string_value.setValue('Text contain');
          this.event.controls.integer_value.setValue('');
          this.event.controls.Int_Value.setValue('');
          this.event.controls.Int_Value.clearValidators(); // Corrected syntax
        }
        this.event.controls.Int_Value.updateValueAndValidity(); // Apply changes
        this.event.controls.St_Value.updateValueAndValidity(); // Apply changes
      }
    );
    // Subscribe to changes in 'pinmode' control value for digital data stream
    this.digitaldatastream.controls.pinmode.valueChanges.subscribe((x: any) => {
      if (x == 'Output') {
        if (this.hardware_name == 'Esp32') {
          if (this.disabledpin.includes('2')) {
            let D_out_isdisabled = true;
            this.DigitalpinOut.map((x: any) => {
              if (!x.isdisabled && D_out_isdisabled) {
                D_out_isdisabled = false;
                this.digitaldatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.digitaldatastream.controls.pin.setValue('2');
          }
        } else {
          if (this.disabledpin.includes('D1')) {
            let D_Out_isdisabled = true;
            this.DigitalpinOut.map((x: any) => {
              if (!x.isdisabled && D_Out_isdisabled) {
                D_Out_isdisabled = false;
                this.digitaldatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.digitaldatastream.controls.pin.setValue('D1');
          }
        }
      }
      if (x == 'Input') {
        if (this.hardware_name == 'Esp32') {
          if (this.disabledpin.includes('2')) {
            let D_In_isdisabled = true;
            this.Digitalpin.map((x: any) => {
              if (!x.isdisabled && D_In_isdisabled) {
                D_In_isdisabled = false;
                this.digitaldatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.digitaldatastream.controls.pin.setValue('2');
          }
        } else {
          if (this.disabledpin.includes('D1')) {
            let D_In_isdisabled = true;
            this.Digitalpin.map((x: any) => {
              if (!x.isdisabled && D_In_isdisabled) {
                D_In_isdisabled = false;
                this.digitaldatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.digitaldatastream.controls.pin.setValue('D1');
          }
        }
      } else if (x == 'Input_pullup') {
        if (this.hardware_name == 'Esp32') {
          if (this.disabledpin.includes('2')) {
            let D_inpull_isdisabled = true;
            this.Digitalpinpull.map((x: any) => {
              if (!x.isdisabled && D_inpull_isdisabled) {
                D_inpull_isdisabled = false;
                this.digitaldatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.digitaldatastream.controls.pin.setValue('2');
          }
        } else {
          if (this.disabledpin.includes('D1')) {
            let D_inpull_isdisabled = true;
            this.Digitalpinpull.map((x: any) => {
              if (!x.isdisabled && D_inpull_isdisabled) {
                D_inpull_isdisabled = false;
                this.digitaldatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.digitaldatastream.controls.pin.setValue('D1');
          }
        }
      }
    });
    // Subscribe to changes in 'pinmode' control value for analog data stream
    this.analogdatastream.controls.pinmode.valueChanges.subscribe((x: any) => {
      if (x == 'Output') {
        if (this.hardware_name == 'Esp32') {
          if (this.disabledpin.includes('2')) {
            let A_out_isdisabled = true;
            this.AnalogpinOut.map((x: any) => {
              if (!x.isdisabled && A_out_isdisabled) {
                A_out_isdisabled = false;
                this.analogdatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.analogdatastream.controls.pin.setValue('2');
          }
        } else {
          if (this.disabledpin.includes('D1')) {
            let A_out_isdisabled = true;
            this.AnalogpinOut.map((x: any) => {
              if (!x.isdisabled && A_out_isdisabled) {
                A_out_isdisabled = false;
                this.analogdatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.analogdatastream.controls.pin.setValue('D1');
          }
        }
      }
      if (x == 'Input') {
        if (this.hardware_name == 'Esp32') {
          if (this.disabledpin.includes('32')) {
            let A_In_isdisabled = true;
            this.AnalogpinIn.map((x: any) => {
              if (!x.isdisabled && A_In_isdisabled) {
                A_In_isdisabled = false;
                this.analogdatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.analogdatastream.controls.pin.setValue('32');
          }
        } else {
          if (this.disabledpin.includes('A0')) {
            let A_In_isdisabled = true;
            this.AnalogpinIn.map((x: any) => {
              if (!x.isdisabled && A_In_isdisabled) {
                A_In_isdisabled = false;
                this.analogdatastream.controls.pin.setValue(x.pin);
              }
            });
          } else {
            this.analogdatastream.controls.pin.setValue('A0');
          }
        }
      }
    });
  }
  /**
   * Retrieves product data for clusters and handles cluster selection.
   * Populates relevant properties based on fetched data.
   */
  async getproductdata() {
    console.log('getlastclusterid', this.lastcluster_Id);
    // Clear the disabled pin array
    this.disabledpin = [];
    // Get user data for authentication
    const data = this.authentication.getUserData();
    // Fetch product data for device page from the data service
    this.dataservice
      .getdevicepageproductdata(data)
      .subscribe(async (res: any) => {
        if (res.status == '200') {
          this.data = res?.data;
          this.productvalue = this.data;

          if (this.lastcluster_Id) {
            this.cluster_id = await this.lastcluster_Id;
            // Find the element in the array that matches the condition
            const selectedCluster = this.productvalue.find(
              (x: any) => Number(x.cluster_id) === Number(this.lastcluster_Id)
            );
            // Check if the element was found
            if (selectedCluster) {
              // Set properties based on the found element
              this.cluster_name = selectedCluster.cluster_api_Id;
              console.log('selecteddddddddd', selectedCluster);

              this.clustername = selectedCluster.cluster_name;
              this.lastmoduleindex = this.productvalue.indexOf(selectedCluster);
              this.range.controls.cluster_Id.setValue(this.cluster_name);
            }
          } else if (!this.lastcluster_Id && this.productvalue.length > 0) {
            this.cluster_id = await this.productvalue[0]?.cluster_id;
            this.cluster_name = await this.productvalue[0]?.cluster_api_Id;
            this.clustername = await this.productvalue[0]?.cluster_name;
            this.range.controls.cluster_Id.setValue(this.cluster_name);
          }

          if (this.cluster_name) {
            await this.gethardwarename(
              this.cluster_id,
              this.cluster_name,
              '',
              this.clustername
            );
          }
          // this.editor.changeModule(this.cluster_name);
        } else {
          this.toastr.error('Error occurred');
        }
      });
  }
  /**
   * Retrieves the hardware name for the specified cluster and triggers necessary updates.
   * @param clusterid - The ID of the cluster for which the hardware name is retrieved.
   * @param cluster_name - The name of the cluster.
   * @param event - The event triggering the function.
   */

  async gethardwarename(
    clusterid: any,
    cluster_name: any,
    event: any,
    clustername: any
  ) {
    console.log('lastclusterid', this.lastcluster_Id);

    let shouldFetchData = false;

    // Check if this is the first call to the function
    if (this.firstflag) {
      console.log('firstflag');
      shouldFetchData = true;
      this.firstflag = false;
    } else {
      console.log('secondflag');
      // Check if there are unsaved changes or new data
      if (this.newdatacome.length > 0 || this.remove) {
        if (window.confirm('Leave this page without saving your changes?')) {
          shouldFetchData = true;
        }
      } else {
        console.log('else firstflag');
        shouldFetchData = true;
      }
    }

    if (shouldFetchData) {
      // Set the cluster ID and update related properties
      this.cluster_id = await clusterid;
      this.lastcluster_Id = this.cluster_id;
      this.clustername = await clustername;

      // Fetch the hardware name from the data service
      this.dataservice
        .gethardwarename(this.cluster_id)
        .subscribe(async (res: any) => {
          if (res.status == 200) {
            this.hardware_name = await res?.data;
            // this.changeModule(event);
            this.editor.changeModule(cluster_name);
            // this.gethardwaredatastreampin(this.hardware_name);
          } else {
            this.toastr.error('Error occurred');
          }
        });

    }
  }

  /**
   * Fetches hardware data stream pin information and populates corresponding pin arrays.
   * @param hardware - The name of the hardware for which pin information is retrieved.
   */
  gethardwaredatastreampin(hardware: any) {
    // Fetch hardware data stream pin information from the data service
    this.dataservice.hardwaredatastreampin(hardware).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res?.data;
        // Iterate through the retrieved data stream information
        this.data?.map((x: any) => {
          if (x.datastream_name == 'Digital') {
            // Populate arrays for Digital data stream input, output, and pull-up pins
            this.Digitalpin = x.input.split(',').map((x: String) => {
              return {
                pin: x,
                isdisabled: false,
              };
            });
            this.DigitalpinOut = x.output.split(',').map((x: String) => {
              return {
                pin: x,
                isdisabled: false,
              };
            });
            this.Digitalpinpull = x.input_pull_up
              .split(',')
              .map((x: String) => {
                return {
                  pin: x,
                  isdisabled: false,
                };
              });
            // }
          }
          if (x.datastream_name == 'Analog') {
            // Populate arrays for Analog data stream input and output pins
            this.AnalogpinIn = x.input.split(',').map((x: String) => {
              return {
                pin: x,
                isdisabled: false,
              };
            });
            this.AnalogpinOut = x.output.split(',').map((x: String) => {
              return {
                pin: x,
                isdisabled: false,
              };
            });
            // }
          }
          if (x.datastream_name == 'Virtual') {
            this.Virtualpin = x.input.split(',').map((x: String) => {
              return {
                pin: x,
                isdisabled: false,
              };
            });
            // }
          }
        });
      } else {
        this.toastr.error('error occured');
      }
    });
  }
  /**
   * Registers event handlers for the Drawflow editor.
   * @param editor - The Drawflow editor instance.
   */
  registerEvents(editor: any): void {
    let connectionRemoved: any = true;
    let chartconnectionRemoved: any = false;

    editor.on('contextmenu', ($event: any) => {
      let nodeinfo: any = $event.srcElement
        .farthestViewportElement as HTMLElement;
      if (nodeinfo) {
        nodeinfo = nodeinfo?.getAttribute('class');
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        let nodeInMatch = /node_in_node-(\d+)/.exec(nodeinfo);
        let nodeOutMatch = /node_out_node-(\d+)/.exec(nodeinfo);
        // Extract the numbers if a match is found
        const nodeInNumber = nodeInMatch ? parseInt(nodeInMatch[1], 10) : null;
        const nodeOutNumber = nodeOutMatch
          ? parseInt(nodeOutMatch[1], 10)
          : null;

        const input_name =
          this.editor.drawflow.drawflow[name].data[String(nodeInNumber)].class;
        if (input_name == 'Chart') {
          const Chartconnection = editor.getNodeFromId(String(nodeInNumber));

          if (Chartconnection.inputs.input_1.connections?.length > 1) {
            this.widgetbox?.map((x: any) => {
              if (Number(x.dashboard_Id) == Number(nodeInNumber)) {
                x.Multiconnection = true;
              }
            });
            chartconnectionRemoved = true;
          }
        } else {
          chartconnectionRemoved = false;
        }
      }

      if (!this.iscreate || !this.isdelete) {
        // Hide the delete option from the context menu
        const drawflow_delete =
          this.el.nativeElement.querySelector('.drawflow-delete');
        this.renderer.setStyle(drawflow_delete, 'display', 'none');
        this.toastr.info('User not permitted');
      }
    });
    /**
     *Create node using Drawflow editor instance.
     * @param editor - The Drawflow editor instance.
     */
    editor.on('nodeCreated', (id: any) => {
      // Set the flag indicating that old pin data is used
      this.oldpin = true;
      this.dataservice.RouterlinkInActive.next();
      this.newdatacome.push(Number(id));
      // Store the ID of the created node
      this.id = id;
      this.datastream_name = '';
      // Open the sidenav for the created node
      this.sidenav.open(id);
      console.log('Node created ' + id);
      // Set initial values for various form controls based on node type
      this.switchdatastream.controls.title.setValue(this.nodename);
      this.leddatastream.controls.title.setValue(this.nodename);
      this.gaugedatastream.controls.title.setValue(this.nodename);
      this.gauge3Phasedatastream.controls.title.setValue(this.nodename);
      this.piechartdatastream.controls.title.setValue(this.nodename);
      this.sliderdatastream.controls.title.setValue(this.nodename);
      this.Thermometerdatastream.controls.title.setValue(this.nodename);
      this.Weatherdatastream.controls.title.setValue(this.nodename);
      this.terminaldatastream.controls.inputhint.setValue('Input here');
      this.terminaldatastream.controls.title.setValue(this.nodename);
      this.labeldatastream.controls.title.setValue(this.nodename);
      this.speedometer.controls.title.setValue(this.nodename);
      this.costdatastream.controls['title'].setValue(this.nodename);
      this.v1pin = false;
      this.v7pin = false;
      this.Chartdatastream.controls['title'].setValue(this.nodename);
      this.event.controls.event_name.setValue(this.nodename);

      // Reset various control values for event configuration
      this.smstoogle = false;
      this.emailToggle = false;
      this.event.controls.event_message_count.reset();
      this.event.controls.event_message_delay.reset();
      this.event.controls.analog_leftmax.reset();
      this.event.controls.analog_leftmin.reset();
      this.event.controls.digital_value.reset();
      this.event.controls.analog_max.reset();
      this.event.controls.analog_max.reset();
      this.event.controls.start_value.reset();
      this.event.controls.end_value.reset();
      this.event.controls.analog_rightmax.reset();
      this.event.controls.analog_rightmin.reset();
      this.event.controls.analog_type.reset();
      this.event.controls.additional_option.reset();
      this.Chartdatastream.controls['chart_type'].setValue('line-chart');
      this.Chartdatastream.controls['autoScale'].setValue(false);
      this.event.controls.event_type.setValue('0');
      this.event.controls.devices.setValue('0');

      // Based on the hardware type, set initial values for datastream controls
      if (this.hardware_name == 'Esp32') {
        // ... set values for Esp32 hardware ...
        this.digitaldatastream.controls.name.setValue('Digital');
        // this.digitaldatastream.controls.pin.setValue('2');
        this.digitaldatastream.controls.pinmode.setValue('Output');
        this.analogdatastream.controls.name.setValue('Analog');
        // this.analogdatastream.controls.pin.setValue('2');
        this.analogdatastream.controls.pinmode.setValue('Output');
        this.analogdatastream.controls.units.setValue('');

        this.virtualpindatastream.controls.name.setValue('Virtual');
        this.virtualpindatastream.controls.pin.setValue('V0');
        this.virtualpindatastream.controls.datastream_type.setValue('Integer');
        this.event.controls.integer_value.setValue('Greater than');
        this.event.controls.event_type.setValue('0');
        this.event.controls.devices.setValue('0');
        // this.virtualpindatastream.controls.units.setValue('None');

        // this.virtualpindatastream.controls.decimal_value.setValue('#.00000');
      } else if (this.hardware_name == 'Esp8266') {
        // ... set values for Esp8266 hardware ...
        // this.digitaldatastream.controls.pin.setValue('D1');
        this.digitaldatastream.controls.pinmode.setValue('Output');
        // this.analogdatastream.controls.pin.setValue('D1');
        this.analogdatastream.controls.pinmode.setValue('Output');
        this.analogdatastream.controls.units.setValue('');
        // this.virtualpindatastream.controls.pin.setValue('V0');
        this.analogdatastream.controls.name.setValue('Analog');
        this.digitaldatastream.controls.name.setValue('Digital');
        this.virtualpindatastream.controls.name.setValue('Virtual');
        // this.virtualpindatastream.controls.units.setValue('None');
        this.virtualpindatastream.controls.datastream_type.setValue('Integer');
        this.event.controls.integer_value.setValue('Greater than');
        this.event.controls.event_type.setValue('0');
        this.event.controls.devices.setValue('0');
        // this.virtualpindatastream.controls.decimal_value.setValue('#.00000');
      }

      if (this.nodename == 'Digital' || this.nodename == 'Analog' || this.nodename == 'Virtual') {

        this.addresslist = [];
        let loopcount = this.addresscount;
        for (let i = 1; i <= loopcount; i++) {
          let removeitem = 'address' + '-' + i;
          this.RemoveAddress(removeitem, this.nodename);

        }
        this.addresscount = 0;


        this.digitaldatastream.controls.isaddress.setValue('0');
        this.analogdatastream.controls.isaddress.setValue('0');
        this.virtualpindatastream.controls.isaddress.setValue('0');
      }


      // Handle specific settings only for widget nodes
      if (
        this.nodename == 'Switch' ||
        this.nodename == 'Slider' ||
        this.nodename == 'LED' ||
        this.nodename == 'Terminal' ||
        this.nodename == 'Label' ||
        this.nodename == 'Cost' ||
        this.nodename == 'Gauge' ||
        this.nodename == 'Gauge3Phase' ||
        this.nodename == 'piechart' ||
        this.nodename == 'Thermometer' ||
        this.nodename == 'Weather' ||
        this.nodename == 'Chart' ||
        this.nodename == 'Speedometer'
      ) {
        // Handle settings for various widget nodes
        this.sliderdatastream.controls.handlestep.reset();
        this.sliderdatastream.controls.Max.setValue('255');
        this.sliderdatastream.controls.Min.setValue('0');
        this.sliderdatastream.controls.handlestep.setValue('10');
        this.terminaldatastream.controls.inputhint.setValue('Input here');

        this.sliderdatastream.controls.Default_value.setValue('0');
        this.Chartdatastream.controls['label'].setValue('Legend');
        this.Chartdatastream.controls['Max'].setValue('1000');
        this.Chartdatastream.controls['Min'].setValue('0');
        this.gaugedatastream.controls.Min.setValue('0');
        this.gaugedatastream.controls.Max.setValue('1000');
        this.gaugedatastream.controls.markers.setValue('1');
        this.speedometer.controls.Min.setValue('0');
        this.speedometer.controls.Max.setValue('100');
        this.gauge3Phasedatastream.controls.Min.setValue('0');
        this.gauge3Phasedatastream.controls.unit1.setValue('');
        this.gauge3Phasedatastream.controls.unit2.setValue('');
        this.gauge3Phasedatastream.controls.unit3.setValue('');
        this.gaugedatastream.controls.units.setValue('');
        this.labeldatastream.controls.units.setValue('');
        this.speedometer.controls.units.setValue('');
        this.gauge3Phasedatastream.controls.Max.setValue('1000');
        this.costlist = [];
        for (let i = 2; i <= 7; i++) {
          this.costdatastream.removeControl('cost-' + i);
        }

        this.textcolor = '#ffffff';
        this.screencolor = '#000000';
        this.color = '#3633b7';
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });

        if (this.nodename == 'Switch') {
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.switchdatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.switchdatastream.value;
        } else if (this.nodename == 'Slider') {
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.sliderdatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.sliderdatastream.value;
        } else if (this.nodename == 'LED') {
          this.leddatastream.value.color = this.color;
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.leddatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.leddatastream.value;
        } else if (this.nodename == 'Terminal') {
          this.terminaldatastream.value.screencolor = this.screencolor;
          this.terminaldatastream.value.textcolor = this.textcolor;
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.terminaldatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.terminaldatastream.value;
        } else if (this.nodename == 'Label') {
          this.labeldatastream.value.color = this.color;
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.labeldatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.labeldatastream.value;
        } else if (this.nodename == 'Cost') {
          this.CostNode = true;
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.costdatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data = {
            ...this.costdatastream.value,
            Multiconnection: false,
          };
        } else if (this.nodename == 'Gauge') {
          this.gaugedatastream.value.color = this.color;

          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.gaugedatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.gaugedatastream.value;
        } else if (this.nodename == 'Gauge3Phase') {
          this.gauge3Phasedatastream.value.color = this.color;

          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.gauge3Phasedatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.gauge3Phasedatastream.value;
        } else if (this.nodename == 'piechart') {
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.piechartdatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.piechartdatastream.value;
        } else if (this.nodename == 'Chart') {
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.Chartdatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data = {
            ...this.Chartdatastream.value,
            Multiconnection: false,
          };
        } else if (this.nodename == 'Thermometer') {
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.Thermometerdatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.Thermometerdatastream.value;
        } else if (this.nodename == 'Weather') {
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.Weatherdatastream.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.Weatherdatastream.value;
        } else if (this.nodename == 'Speedometer') {
          this.widgetbox.push({
            dashboard_Id: Number(id),
            widgetname: this.nodename,
            ...this.speedometer.value,
            Multiconnection: false,
          });
          this.editor.drawflow.drawflow[name].data[this.id].data =
            this.speedometer.value;
        }
      } else if (this.nodename == 'Notifications') {
        // Handle settings for Notifications node
        const analogMinControl = this.event.get('analog_min');
        const analogMaxControl = this.event.get('analog_max');
        const analogLeftMinControl = this.event.get('analog_leftmin');
        const analogLeftMaxControl = this.event.get('analog_leftmax');
        const analogRightMinControl = this.event.get('analog_rightmin');
        const analogRightMaxControl = this.event.get('analog_rightmax');
        // ... Clear validators and set values for analog controls ...
        analogMinControl!.clearValidators();
        analogMaxControl!.clearValidators();
        analogLeftMinControl!.clearValidators();
        analogLeftMaxControl!.clearValidators();
        analogRightMinControl!.clearValidators();
        analogRightMaxControl!.clearValidators();

        this.event.controls.event_name.setValue(this.nodename);

        this.event.controls.event_type.setValue('0');
        this.event.controls.devices.setValue('0');

        this.smstoogle = false;
        this.emailToggle = false;
        this.event.controls.event_message_count.reset();
        this.event.controls.event_message_delay.reset();
        this.event.controls.analog_leftmax.reset();
        this.event.controls.analog_leftmin.reset();
        this.event.controls.digital_value.reset();
        this.event.controls.analog_min.reset();
        this.event.controls.analog_max.reset();
        this.event.controls.analog_rightmax.reset();
        this.event.controls.analog_rightmin.reset();
        this.event.controls.analog_type.reset();
        this.event.controls.additional_option.reset();
        this.GaugeProp = false;
        this.switchprop = false;
        this.virtualprop = false;
        this.notify_type = [];
        this.User_Number = [];

        this.user_Id = [];
        this.events_data.push({
          event_node_Id: Number(id),
          eventname: this.nodename,
        });
        console.log(this.events_data);
        console.log(this.event);
      } else if (
        /**
         * Handles settings and conditions when a node of type "Digital," "Analog," or "Virtual" is created.
         * */
        this.nodename == 'Digital' ||
        this.nodename == 'Analog' ||
        this.nodename == 'Virtual'
      ) {
        this.routesdata.push({
          datafeeds_Id: Number(id),
          node_name: this.nodename,
        });
        // Loop through Digital pins
        this.Digitalpin.forEach((x: any) => {
          if (this.disabledpin.includes(x.pin)) {
            x.isdisabled = true;
          } else {
            x.isdisabled = false;
          }
        });
        // Loop through Digital pins with pull
        this.Digitalpinpull.forEach((x: any) => {
          if (this.disabledpin.includes(x.pin)) {
            x.isdisabled = true;
          } else {
            x.isdisabled = false;
          }
        });
        // Loop through Digital output pins
        this.DigitalpinOut.forEach((x: any) => {
          if (this.disabledpin.includes(x.pin)) {
            x.isdisabled = true;
          } else {
            x.isdisabled = false;
          }
        });
        // Loop through Analog input pins
        this.AnalogpinIn.map((x: any) => {
          if (this.disabledpin.includes(x.pin)) {
            x.isdisabled = true;
          } else {
            x.isdisabled = false;
          }
        });
        // Loop through Analog output pins
        this.AnalogpinOut.map((x: any) => {
          if (this.disabledpin.includes(x.pin)) {
            x.isdisabled = true;
          } else {
            x.isdisabled = false;
          }
        });
        // Loop through Virtual pins
        this.Virtualpin.map((x: any) => {
          if (this.disabledpin.includes(x.pin)) {
            x.isdisabled = true;
          } else {
            x.isdisabled = false;
          }
        });
        // Handle specific conditions based on hardware and pin availability
        if (this.disabledpin.includes('2') || this.disabledpin.includes('D1')) {
          let D_Out_isdisabled = true;
          this.DigitalpinOut.map((x: any) => {
            if (!x.isdisabled && D_Out_isdisabled) {
              D_Out_isdisabled = false;
              this.digitaldatastream.controls.pin.setValue(x.pin);
            }
          });
        }

        if (this.disabledpin.includes('2') || this.disabledpin.includes('D1')) {
          let A_out_isdisabled = true;
          this.AnalogpinOut.map((x: any) => {
            if (!x.isdisabled && A_out_isdisabled) {
              A_out_isdisabled = false;
              this.analogdatastream.controls.pin.setValue(x.pin);
            }
          });
        }

        if (this.disabledpin.includes('V0')) {
          let V_isdisabled = true;
          this.Virtualpin.map((x: any) => {
            if (!x.isdisabled && V_isdisabled) {
              V_isdisabled = false;
              this.virtualpindatastream.controls.pin.setValue(x.pin);
            }
          });
        }
      }

    });
    /**
     * Handles the removal of a node from the Drawflow editor instance.
     *
     * @param id - The ID of the removed node.
     */
    editor.on('nodeRemoved', (id: string) => {
      this.lastRemove = true;
      let pin: any;
      this.id = id;
      this.sidenav.close();
      if (this.newdatacome.length > 0) {
        const index = this.newdatacome.indexOf(Number(id));
        this.newdatacome.splice(index, 1);
      } else {
        this.remove = true;
      }

      if (this.newdatacome.length <= 0) {
        this.dataservice.RouterlinkActive.next();
        this.FirstSave = false;
      }
      // If removal was flagged, update routing to inactive
      if (this.remove) {
        this.dataservice.RouterlinkInActive.next();
      }
      // Find and remove widget information associated with the removed node
      if (this.widgetbox.length > 0) {
        const foundWidget = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });

        if (foundWidget) {
          if (foundWidget.node_name == 'Cost') {
            this.CostNode = false;
          }
          const Widgetindex = this.widgetbox.indexOf(foundWidget);
          this.widgetbox.splice(Widgetindex, 1);
        }
      }

      if (this.routesdata.length > 0 || this.datafeedsdata.length > 0) {
        // Find the associated route data with the removed node
        const foundfeeds = this.routesdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });
        // Retrieve the pin information if found
        pin = foundfeeds?.pin;
        if (foundfeeds) {
          const feedsindex = this.routesdata.indexOf(foundfeeds);
          this.routesdata.splice(feedsindex, 1);
        }
        // If there are associated data feeds, handle them as well
        if (this.datafeedsdata.length > 0) {

          const founddatafeeds = this.datafeedsdata.find((x: any) => {
            console.log(x?.datafeeds_Id, this.id)
            return Number(x?.datafeeds_Id) === Number(this.id);
          });

          // If the data feed is not marked as deleted
          if (founddatafeeds?.isdelete == 0) {
            founddatafeeds.isdelete = 1;
            founddatafeeds.datafeeds_Id = 0;
            // Update the data feed entry in the array
            const datafeedsdataindex =
              this.datafeedsdata.indexOf(founddatafeeds);
            this.datafeedsdata.splice(datafeedsdataindex, 1, founddatafeeds);
            console.log(this.datafeedsdata)
          }
        }
      }
      // Handle event data associated with the removed node
      if (this.events_data.length > 0) {
        // Find the associated event data entry
        const foundevent = this.events_data.find((x: any) => {
          return Number(x.event_node_Id) === Number(this.id);
        });
        // If the event is marked as inactive, update or remove it
        if (foundevent?.isactive == 0) {
          foundevent.isactive = 1;
          foundevent.event_node_Id = 0;
          const eventindex = this.events_data.indexOf(foundevent);
          this.events_data.splice(eventindex, 1, foundevent);
        } else if (!foundevent.isactive) {
          const eventindex = this.events_data.indexOf(foundevent);
          this.events_data.splice(eventindex, 1);
        }
      }
      if (this.disabledpin.length > 0) {
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // Find and remove the disabled pin from the list
        const foundItem = this.disabledpin?.find((x: any) => {
          return x == pin;
        });
        if (foundItem) {
          const index = this.disabledpin.indexOf(foundItem);
          this.disabledpin.splice(index, 1);
        }
      }
      console.log('Node removed ' + id);
      console.log(this.routesdata);
      console.log(this.widgetbox);
    });
    /**
     * Event listener for when a module is created within the Drawflow editor instance.
     *
     * @param name - The name of the created module.
     */
    editor.on('moduleCreated', (name: string) => {
      console.log('Module Created ' + name);
    });
    /**
     * Event listener for when a connection is created within the Drawflow editor instance.
     *
     * @param connection - The connection object representing the created connection.
     */
    editor.on('connectionCreated', (connection: any) => {
      let connectionCreated: any = true;
      // Get information about the input and output nodes of the connection
      const InnodeInfo = editor.getNodeFromId(connection.input_id);
      const OutnodeInfo = editor.getNodeFromId(connection.output_id);
      // Check if the output node is "Notifications"
      if (OutnodeInfo.name == 'Notifications') {
        // Display a toastr notification indicating restricted connection
        this.toastr.info('Connection Restricted');
        // Prevent the connection from being created
        connectionCreated = false;
        // Remove the connection that was attempted to be created
        const removeConnectionInfo = InnodeInfo.inputs.input_1.connections[0];
        editor.removeSingleConnection(
          removeConnectionInfo.node,
          connection.input_id,
          removeConnectionInfo.input,
          connection.input_class
        );
      }
      // Check if both the output and input nodes are widgets
      else if (this.widget_list.includes(OutnodeInfo.name)) {
        if (this.widget_list.includes(InnodeInfo.name)) {
          // Check if the input node already has a connection

          if (InnodeInfo.inputs.input_1.connections.length === 1) {
            connectionCreated = false;
            this.toastr.info('Connection Restricted');
            // Remove the connection that was attempted to be created
            const removeConnectionInfo =
              InnodeInfo.inputs.input_1.connections[0];
            editor.removeSingleConnection(
              removeConnectionInfo.node,
              connection.input_id,
              removeConnectionInfo.input,
              connection.input_class
            );
          } else {
            connectionCreated = false;
            connectionRemoved = false;
            this.toastr.info('Connection Restricted');
            // Remove the second connection (if present) from the input node
            const removeConnectionInfo =
              InnodeInfo.inputs.input_1.connections[
              InnodeInfo.inputs.input_1.connections?.length - 1
              ];
            if (removeConnectionInfo) {
              editor.removeSingleConnection(
                removeConnectionInfo.node,
                connection.input_id,
                removeConnectionInfo.input,
                connection.input_class
              );
            }
          }
          //  else if (InnodeInfo.name == "Chart") {
          //   if (InnodeInfo.inputs.input_1.connections.length === 1) {
          //     connectionCreated = false;
          //     this.toastr.info("Connection Restricted");
          //     // Remove the connection that was attempted to be created
          //     const removeConnectionInfo = InnodeInfo.inputs.input_1.connections[0];
          //     editor.removeSingleConnection(removeConnectionInfo.node, connection.input_id, removeConnectionInfo.input, connection.input_class);
          //   }
          //   else if (InnodeInfo.inputs.input_1.connections?.length > 1) {
          //     connectionCreated = false;
          //     connectionRemoved = false;
          //     this.toastr.info("Connection Restricted");
          //     const removeConnectionInfo = InnodeInfo.inputs.input_1.connections[InnodeInfo.inputs.input_1.connections?.length - 1];
          //     if (removeConnectionInfo) {
          //       editor.removeSingleConnection(removeConnectionInfo.node, connection.input_id, removeConnectionInfo.input, connection.input_class);
          //     }
          //   }

          // }
        }
      }
      // Check if the output node is "Digital", "Analog", or "Virtual"
      else if (OutnodeInfo.name == 'Digital' || 'Analog' || 'Virtual') {
        // Check if the input node has more than one connection

        if (InnodeInfo.name != 'Chart') {
          if (InnodeInfo.inputs.input_1.connections?.length > 1) {
            this.toastr.info('Connection Restricted');
            connectionCreated = false;
            connectionRemoved = false;
            const removeConnectionInfo =
              InnodeInfo.inputs.input_1.connections[1];

            if (removeConnectionInfo) {
              editor.removeSingleConnection(
                removeConnectionInfo.node,
                connection.input_id,
                removeConnectionInfo.input,
                connection.input_class
              );
            }
          }
        }
        //   else if(
        //     InnodeInfo.name == "Chart"
        //   ){
        //     if (InnodeInfo.inputs.input_1.connections?.length > 1) {
        //       let name: any;
        // this.productvalue.map((x: any) => {
        //   if (x.cluster_id === this.cluster_id) {
        //     name = x.cluster_api_Id;
        //   }
        // });

        //     console.log(InnodeInfo)
        //     const node = document.querySelector(`#node-${InnodeInfo.id}`) as HTMLElement;

        //     node.childNodes[1].childNodes[1].childNodes[3].textContent="*"

        //     }
        //   }
      }
      // Check if the input node is "Notifications" and the output node is a widget
      if (InnodeInfo.name === 'Notifications') {
        if (this.widget_list.includes(OutnodeInfo.name)) {
          // Check if the input node has more than one connection
          if (InnodeInfo.inputs.input_1.connections?.length > 1) {
            this.toastr.info('Connection Restricted');
            // Prevent the connection from being created
            connectionCreated = false;
            connectionRemoved = false;
            // Remove the second connection (if present) from the input node
            const removeConnectionInfo =
              InnodeInfo.inputs.input_1.connections[1];

            if (removeConnectionInfo) {
              editor.removeSingleConnection(
                removeConnectionInfo.node,
                connection.input_id,
                removeConnectionInfo.input,
                connection.input_class
              );
            }
          }
        }
      }
      // Update the router link state to inactive
      this.dataservice.RouterlinkInActive.next();
      // Set a flag indicating the last connection removal
      this.lastRemove = true;
      let multichartconnection: any = false;
      console.log('Connection created');
      // Retrieve the name associated with the cluster
      let name: any;
      this.productvalue.map((x: any) => {
        if (x.cluster_id === this.cluster_id) {
          name = x.cluster_api_Id;
        }
      });
      // Check if a pin is associated with the output node and the connection was created
      if (
        this.editor.drawflow.drawflow[name].data[connection.output_id].data
          .pin &&
        connectionCreated
      ) {
        // Update pin and input_name information in the editor's data structure
        this.editor.drawflow.drawflow[name].data[connection.input_id]['pin'] =
          this.editor.drawflow.drawflow[name].data[
            connection.output_id
          ].data.pin;

        this.pin =
          this.editor.drawflow.drawflow[name].data[connection.input_id].pin;

        // Update the 'input_name' field with the output node's name
        this.editor.drawflow.drawflow[name].data[connection.input_id][
          'input_name'
        ] =
          this.editor.drawflow.drawflow[name].data[
            connection.output_id
          ].data.node_name;
        // Store the updated input name for reference
        this.in_name =
          this.editor.drawflow.drawflow[name].data[
            connection.input_id
          ].input_name;
        // Get the class name of the input node from the editor's data structure
        this.output_name =
          this.editor.drawflow.drawflow[name].data[connection.input_id].class;
        // Check if the input node is of type "Notifications"
        this.inputname = this.in_name;

        if (this.output_name === 'Chart') {
          this.widgetbox?.map((x: any) => {
            if (Number(x.dashboard_Id) == Number(connection.input_id)) {
              // x.Multiconnection = true;
              if (this.pin == 'V1') {
                x['KwhDay'] = this.pin;
              }
            }
          });
          const Chartconnection = editor.getNodeFromId(connection.input_id);
          if (Chartconnection.inputs.input_1.connections?.length > 1) {
            this.widgetbox?.map((x: any) => {
              if (Number(x.dashboard_Id) == Number(connection.input_id)) {
                x.Multiconnection = true;
              }
            });
            multichartconnection = true;
            // const newcontrol: AbstractControl = this.formbuilder.control('line' + this.linecount)
            // this.Chartdatastream.addControl('line'+'-'+this.linecount, newcontrol);
            // this.chartlines.push({name:"line"+'-'+this.linecount})
            ++this.linecount;
            console.log('Chartdatastream form', this.Chartdatastream);

            this.editor.drawflow.drawflow[name].data[connection.input_id].data[
              'Multiconnection'
            ] = true;
          }
        } else {
          multichartconnection = false;
        }
        // Check if the input node type is "Digital"
        if (this.in_name == 'Digital') {
          // Select the input node's UI element
          const node = document.querySelector(
            `#node-${connection.input_id}`
          ) as HTMLElement;

          if (multichartconnection) {
            let removeelement = node.querySelector('h4,h5,h6');
            if (removeelement) {
              removeelement?.parentNode!.removeChild(removeelement);
            }
            // Create a new <h6> element for displaying pin information
            const heading = document.createElement('h3') as HTMLElement;

            // Get the pin information from the output node's data in the editor's data structure
            this.pin =
              this.editor.drawflow.drawflow[name].data[
                connection.output_id
              ].data.pin;
            // Set the text content of the heading to the pin information
            heading.innerText = '*';

            // Append the heading element to the input node's UI
            node.childNodes[1].childNodes[1].appendChild(heading);
          } else {
            // Create a new <h6> element for displaying pin information
            const heading = document.createElement('h6') as HTMLElement;

            // Get the pin information from the output node's data in the editor's data structure
            this.pin =
              this.editor.drawflow.drawflow[name].data[
                connection.output_id
              ].data.pin;
            // Set the text content of the heading to the pin information
            heading.innerText = this.pin;

            // Append the heading element to the input node's UI
            node.childNodes[1].childNodes[1].appendChild(heading);
          }
        }

        // Check if the input node type is "Analog"
        if (this.in_name == 'Analog') {
          // Select the input node's UI element
          const node = document.querySelector(
            `#node-${connection.input_id}`
          ) as HTMLElement;

          if (multichartconnection) {
            let removeelement = node.querySelector('h4,h5,h6');
            if (removeelement) {
              removeelement?.parentNode!.removeChild(removeelement);
            }
            const heading = document.createElement('h3') as HTMLElement;
            // Set the text content of the heading to the pin information
            heading.innerText = '*';
            // Append the heading element to the input node's UI
            node.childNodes[1].childNodes[1].appendChild(heading);
          } else {
            // Create a new <h5> element for displaying pin information
            const heading = document.createElement('h5') as HTMLElement;

            // Get the pin information from the output node's data in the editor's data structure
            this.pin =
              this.editor.drawflow.drawflow[name].data[
                connection.output_id
              ].data.pin;
            // Set the text content of the heading to the pin information
            heading.innerText = this.pin;
            // Append the heading element to the input node's UI
            node.childNodes[1].childNodes[1].appendChild(heading);
          }
        }

        // Check if the input node type is "Virtual"
        if (this.in_name == 'Virtual') {
          // Select the input node's UI element
          const node = document.querySelector(
            `#node-${connection.input_id}`
          ) as HTMLElement;

          if (multichartconnection) {
            let removeelement = node.querySelector('h4,h5,h6');
            if (removeelement) {
              removeelement?.parentNode!.removeChild(removeelement);
            }
            // Create a new <h4> element for displaying pin information
            const heading = document.createElement('h3') as HTMLElement;

            // Get the pin information from the output node's data in the editor's data structure
            this.pin =
              this.editor.drawflow.drawflow[name].data[
                connection.output_id
              ].data.pin;
            // Set the text content of the heading to the pin information
            heading.innerText = '*';

            // Append the heading element to the input node's UI
            node.childNodes[1].childNodes[1].appendChild(heading);
          } else {
            // Create a new <h4> element for displaying pin information
            const heading = document.createElement('h4') as HTMLElement;

            // Get the pin information from the output node's data in the editor's data structure
            this.pin =
              this.editor.drawflow.drawflow[name].data[
                connection.output_id
              ].data.pin;

            // Set the text content of the heading to the pin information
            heading.innerText = this.pin;
            if (this.pin == 'V1') {
              this.v1pin = true;
              this.labeldatastream.controls.additional_option.setValue(
                'totalkwh'
              );
              this.gaugedatastream.controls.additional_option.setValue(
                'totalkwh'
              );
            } else if (this.pin == 'V7') {
              this.v7pin = true;
            } else {
              this.v1pin = false;
              this.v7pin = false;
            }
            // Append the heading element to the input node's UI
            node.childNodes[1].childNodes[1].appendChild(heading);
          }
        }

        if (this.output_name === 'Notifications') {
          // Get the datastream method from the output node's data in the editor's data structure
          let method =
            this.editor.drawflow.drawflow[name].data[connection.output_id].data
              .datastream_name;
          // Initialize a variable to store a numeric value based on the method
          let value;
          // Depending on the datastream method, configure properties and values
          if (method === 'Digital') {
            this.switchprop = true;
            this.GaugeProp = false;
            this.virtualprop = false;
            value = '0';
          } else if (method === 'Analog') {
            this.GaugeProp = true;
            this.switchprop = false;
            this.virtualprop = false;
            this.event.controls.analog_type.setValue('0');
            value = '1';
          } else if (method === 'Virtual') {
            this.virtualprop = true;
            this.GaugeProp = false;
            this.switchprop = false;

            let ds_type =
              this.editor.drawflow.drawflow[name].data[connection.output_id]
                .data.datastream_type;
            this.editor.drawflow.drawflow[name].data[connection.input_id][
              'ds_type'
            ] = ds_type;

            if (ds_type == 'Integer') {
              this.virtual_Int = true;
            } else {
              this.virtual_Int = false;
            }

            value = '2';
          } else {
            value = '';
          }
          // Set properties for the input node in the editor's data structure
          this.editor.drawflow.drawflow[name].data[connection.input_id]['pin'] =
            this.pin;

          this.editor.drawflow.drawflow[name].data[connection.input_id][
            'input_name'
          ] = method;

          this.editor.drawflow.drawflow[name].data[connection.input_id][
            'method'
          ] = value;
          console.log(
            this.editor.drawflow.drawflow[name].data[connection.input_id]
          );
        } else {
          // If the input node is not of type "Notifications"

          // Find the corresponding datafeeds entry for the connection's output node
          const found = this.routesdata?.find((x: any) => {
            return Number(x.datafeeds_Id) == Number(connection.output_id);
          });
          if (found) {
            if (found.dashboard_Id) {
              if (found.dashboard_Id?.length >= 1) {
                // If there are already multiple dashboard IDs associated with the datafeeds entry
                // Add the current input_id to the list of dashboard IDs
                found.dashboard_Id.push(Number(connection.input_id));
              } else {
                // If there is only one dashboard ID associated with the datafeeds entry
                // Create an array of dashboard IDs including the existing and current ones
                found.dashboard_Id = [
                  found.dashboard_Id,
                  Number(connection.input_id),
                ];
              }
              // Update the modified datafeeds entry in the routesdata array
              const index = this.routesdata.indexOf(found);
              this.routesdata[index] = { ...found };
            } else {
              // If the dashboard_Id property does not exist in the datafeeds entry
              // Create a new datafeeds entry with the dashboard_Id property set to the current input_id
              const index = this.routesdata.indexOf(found);
              this.routesdata[index] = {
                ...found,
                dashboard_Id: Number(connection.input_id),
              };
            }
          }
        }
        // widget to notification
        // Check if the pin property exists for the input node in the editor's data structure
        if (this.editor.drawflow.drawflow[name].data[connection.input_id].pin) {
          // Get the HTML element corresponding to the output node
          let output_node = document.querySelector(
            `#node-${connection.input_id}`
          ) as HTMLElement;
          // Get the pin value from the output node's pin tag
          let output_pin = output_node.querySelector('h3,h4, h5, h6');
          this.pintag = output_pin;
          this.pin = this.pintag.textContent;

          // Loop through the connections of the output node's output_1
          this.editor.drawflow.drawflow[name].data[
            connection.input_id
          ]?.outputs?.output_1?.connections?.map((x: any) => {
            // Get the HTML element corresponding to the connected node
            let node = document.querySelector(`#node-${x.node}`) as HTMLElement;
            node.childNodes[1].childNodes[1].appendChild(
              this.pintag.cloneNode(true)
            );
            // Get the method (input_name) from the input node's data
            let method =
              this.editor.drawflow.drawflow[name].data[connection.input_id]
                .input_name;
            let value;
            // Depending on the method, configure properties and values
            if (method === 'Digital') {
              this.switchprop = true;
              value = '0';
            } else if (method === 'Analog') {
              this.GaugeProp = true;
              this.event.controls.analog_type.setValue('0');
              value = '1';
            } else if (method === 'Virtual') {
              this.virtualprop = true;
              value = '2';
            } else {
              value = '';
            }
            // Set properties for the connected node in the editor's data structure

            if (multichartconnection) {
              this.editor.drawflow.drawflow[name].data[x.node]['pin'] = '*';
            } else {
              this.editor.drawflow.drawflow[name].data[x.node]['pin'] =
                this.pin;
            }
            this.editor.drawflow.drawflow[name].data[x.node]['method'] = value;

            // Set the input_name property for the connected node in the editor's data structure
            this.inputname =
              this.editor.drawflow.drawflow[name].data[
                connection.input_id
              ].class;
            this.editor.drawflow.drawflow[name].data[x.node]['input_name'] =
              this.inputname;
          });
        }
        // Check if the pin property exists for the output node in the editor's data structure
        if (
          this.editor.drawflow.drawflow[name].data[connection.output_id].pin
        ) {
          console.log('test1');
          let output_node = document.querySelector(
            `#node-${connection.output_id}`
          ) as HTMLElement;
          // Get the pin value from the output node's pin tag
          let output_pin = output_node.querySelector('h3,h4, h5, h6');
          this.pintag = output_pin;
          this.pin = this.pintag.textContent;
          // Loop through the connections of the output node's output_1
          this.editor.drawflow.drawflow[name].data[
            connection.output_id
          ].outputs.output_1.connections?.map((x: any) => {
            let node = document.querySelector(`#node-${x.node}`) as HTMLElement;
            node.childNodes[1].childNodes[1].appendChild(
              this.pintag.cloneNode(true)
            );
          });
        }
      }
      // Check if connection creation is successful and proceed
      else if (connectionCreated) {
        console.log('test2');
        // Check if the output node has a pin property
        if (
          this.editor.drawflow.drawflow[name].data[connection.output_id].pin
        ) {
          console.log('test3');
          // Get the HTML element corresponding to the output node
          let output_node = document.querySelector(
            `#node-${connection.output_id}`
          ) as HTMLElement;
          // Get the pin value from the output node's pin tag
          let output_pin = output_node.querySelector('h4, h5, h6');
          // Get the HTML element corresponding to the input node
          let node = document.querySelector(
            `#node-${connection.input_id}`
          ) as HTMLElement;
          this.pintag = output_pin;
          this.pin = this.pintag.textContent;
          // Append a clone of the pin tag to the input node's HTML structure
          node.childNodes[1].childNodes[1].appendChild(
            this.pintag.cloneNode(true)
          );
          // Get the method (input_name) from the output node's data
          let method =
            this.editor.drawflow.drawflow[name].data[connection.output_id]
              .input_name;
          // Initialize a variable to store a numeric value based on the method
          let value;
          if (method === 'Digital') {
            this.switchprop = true;
            value = '0';
          } else if (method === 'Analog') {
            this.event.controls.analog_type.setValue('0');
            this.GaugeProp = true;
            value = '1';
          } else if (method === 'Virtual') {
            value = '2';

            // Loop through input/output connections of the output node
            this.editor.drawflow.drawflow[name].data[
              connection.output_id
            ].inputs.input_1.connections?.map((x: any) => {
              this.editor.drawflow.drawflow[name].data[
                connection.output_id
              ].outputs.output_1.connections?.map((y: any) => {
                // Set datastream type properties for connected nodes
                this.editor.drawflow.drawflow[name].data[y.node]['ds_type'] =
                  this.editor.drawflow.drawflow[name].data[
                    x.node
                  ].data.datastream_type;
                if (
                  this.editor.drawflow.drawflow[name].data[y.node]['ds_type'] ==
                  'Integer'
                ) {
                  this.virtual_Int = true;
                } else {
                  this.virtual_Int = false;
                }
              });
            });
            this.virtualprop = true;
          } else {
            value = '';
          }
          // Set properties for the input node in the editor's data structur
          this.editor.drawflow.drawflow[name].data[connection.input_id]['pin'] =
            this.pin;
          this.editor.drawflow.drawflow[name].data[connection.input_id][
            'method'
          ] = value;
          // Set the input_name property for the input node in the editor's data structure
          this.inputname =
            this.editor.drawflow.drawflow[name].data[
              connection.output_id
            ].class;
          this.editor.drawflow.drawflow[name].data[connection.input_id][
            'input_name'
          ] = this.inputname;
          console.log(
            this.editor.drawflow.drawflow[name].data[connection.input_id]
          );
          let additional_option: any;
          if (
            this.editor.drawflow.drawflow[name].data[connection.output_id].data
              .additional_option
          ) {
            additional_option =
              this.editor.drawflow.drawflow[name].data[connection.output_id]
                .data.additional_option;
          }
          console.log(additional_option);
          this.editor.drawflow.drawflow[name].data[connection.input_id][
            'additional_option'
          ] = additional_option;
        } else {
          // Get the class (output_name) of the input and output nodes
          this.output_name =
            this.editor.drawflow.drawflow[name].data[connection.input_id].class;

          this.inputname =
            this.editor.drawflow.drawflow[name].data[
              connection.output_id
            ].class;
          // Check if the input and output nodes are 'Notifications'

          if (this.output_name === 'Notifications') {
            let value;
            // Set input_name property for input node based on output node class
            this.editor.drawflow.drawflow[name].data[connection.input_id][
              'input_name'
            ] = this.inputname;
            if (this.inputname === 'Digital') {
              value = '0';
              this.GaugeProp = false;
              this.switchprop = true;
              this.virtualprop = false;
            }
            if (this.inputname === 'Analog') {
              this.switchprop = false;
              this.GaugeProp = true;
              this.event.controls.analog_type.setValue('0');

              this.virtualprop = false;
              value = '1';
            }
            if (this.inputname === 'Virtual') {
              this.virtualprop = true;
              this.switchprop = false;
              this.GaugeProp = false;
              value = '2';
            } else {
              // Determine property values based on method if method is Digital, Analog, or Virtual
              let method =
                this.editor.drawflow.drawflow[name].data[connection.output_id]
                  .input_name;

              if (method === 'Digital') {
                this.GaugeProp = false;
                this.switchprop = true;
                this.virtualprop = false;
                value = '0';
              }
              if (method === 'Analog') {
                this.switchprop = false;
                this.GaugeProp = true;
                this.virtualprop = false;
                value = '1';
              }
              if (method === 'Virtual') {
                value = '2';
                this.virtualprop = true;
                this.switchprop = false;
                this.GaugeProp = false;
              }
            }
            // Set method property for input node in editor's data structure
            this.editor.drawflow.drawflow[name].data[connection.input_id][
              'method'
            ] = value;
          } else {
            if (this.output_name == 'Chart') {
              const Chartconnection = editor.getNodeFromId(connection.input_id);
              if (Chartconnection.inputs.input_1.connections?.length > 1) {
                this.widgetbox?.map((x: any) => {
                  if (Number(x.dashboard_Id) == Number(connection.input_id)) {
                    x.Multiconnection = true;
                  }
                });
                this.editor.drawflow.drawflow[name].data[
                  this.id
                ].data.Multiconnection = true;
                const node = document.querySelector(
                  `#node-${connection.input_id}`
                ) as HTMLElement;
                let removeelement = node.querySelector('h4,h5,h6');
                if (removeelement) {
                  removeelement?.parentNode!.removeChild(removeelement);
                }
                // Create a new <h4> element for displaying pin information
                const heading = document.createElement('h3') as HTMLElement;

                // Set the text content of the heading to the pin information
                heading.innerText = '*';
                // Append the heading element to the input node's UI
                node.childNodes[1].childNodes[1].appendChild(heading);
              }
            }
            // Set input_name property for input node based on output node class
            this.editor.drawflow.drawflow[name].data[connection.input_id][
              'input_name'
            ] = this.inputname;
            // Update routesdata with dashboard_Id based on output_id
            this.routesdata?.map((x: any) => {
              if (Number(x.datafeeds_Id) === Number(connection.output_id)) {
                if (x.dashboard_Id) {
                  if (x.dashboard_Id?.length >= 1) {
                    x.dashboard_Id.push(Number(connection.input_id));
                  } else {
                    x.dashboard_Id = [
                      x.dashboard_Id,
                      Number(connection.input_id),
                    ];
                  }
                } else {
                  x['dashboard_Id'] = Number(connection.input_id);
                }
              }
            });
          }
        }
      }
    });
    /**
     * Event listener for when a connection is removed from the editor.
     * @param connection - The connection that was removed.
     */
    editor.on('connectionRemoved', (connection: any) => {
      let multichartconnection: any = false;
      console.log(connectionRemoved);
      // Check if connection removal was intended
      if (connectionRemoved) {
        this.dataservice.RouterlinkInActive.next();
        this.lastRemove = true;
        console.log('Connection removed');
        // Get the name associated with the cluster ID from the product value

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        let node_name =
          this.editor.drawflow.drawflow[name].data[connection.input_id].name;
        console.log(node_name);

        // Remove pin information from the input node's HTML structure
        const node = document.querySelector(
          `#node-${connection.input_id}`
        ) as HTMLElement;
        if (chartconnectionRemoved) {
          const childNodeToRemove =
            node.childNodes[1].childNodes[1].childNodes[4];
          childNodeToRemove?.parentNode!.removeChild(childNodeToRemove);
          const Chartconnection = editor.getNodeFromId(connection.input_id);
          if (Chartconnection.inputs.input_1.connections?.length > 1) {
            this.widgetbox?.map((x: any) => {
              if (Number(x.dashboard_Id) == Number(connection.input_id)) {
                x.Multiconnection = true;
              }
            });
            this.editor.drawflow.drawflow[name].data[
              connection.input_id
            ].data.Multiconnection = true;
            let removeelement = node.querySelector('h3,h4,h5,h6');

            if (removeelement) {
              node?.parentNode!.removeChild(removeelement);
            }
            // Create a new <h6> element for displaying pin information
            const heading = document.createElement('h3') as HTMLElement;
            // Set the text content of the heading to the pin information
            heading.innerText = '*';
            // Append the heading element to the input node's UI
            node.childNodes[1].childNodes[1].appendChild(heading);
          } else {
            this.output_name =
              this.editor.drawflow.drawflow[name].data[
                connection.output_id
              ].class;
            this.editor.drawflow.drawflow[name].data[
              connection.input_id
            ].data.Multiconnection = false;
            if (this.output_name == 'Digital') {
              let removeelement = node.querySelector('h3,h4,h5,h6');

              if (removeelement) {
                const parentNode = removeelement.parentNode;
                if (parentNode) {
                  parentNode.removeChild(removeelement);
                }
                // removeelement?.parentNode!.removeChild(removeelement)
              }

              // Create a new <h6> element for displaying pin information
              const heading = document.createElement('h6') as HTMLElement;

              // Get the pin information from the output node's data in the editor's data structure
              this.pin =
                this.editor.drawflow.drawflow[name].data[
                  Chartconnection.inputs.input_1.connections[0].node
                ].data.pin;
              if (this.pin) {
                // Set the text content of the heading to the pin information
                heading.innerText = this.pin;

                // Append the heading element to the input node's UI
                node.childNodes[1].childNodes[1].appendChild(heading);
              }
            } else if (this.output_name == 'Analog') {
              let removeelement = node.querySelector('h3,h4,h5,h6');

              if (removeelement) {
                const parentNode = removeelement.parentNode;
                if (parentNode) {
                  parentNode.removeChild(removeelement);
                }
                // removeelement?.parentNode!.removeChild(removeelement)
              }
              // Create a new <h6> element for displaying pin information
              const heading = document.createElement('h5') as HTMLElement;

              // Get the pin information from the output node's data in the editor's data structure
              this.pin =
                this.editor.drawflow.drawflow[name].data[
                  Chartconnection.inputs.input_1.connections[0].node
                ].data.pin;
              // Set the text content of the heading to the pin information

              if (this.pin) {
                // Set the text content of the heading to the pin information
                heading.innerText = this.pin;

                // Append the heading element to the input node's UI
                node.childNodes[1].childNodes[1].appendChild(heading);
              }
            } else if (this.output_name == 'Virtual') {
              let removeelement = node.querySelector('h3,h4,h5,h6');
              if (removeelement) {
                removeelement?.parentNode!.removeChild(removeelement);
              }
              // Create a new <h6> element for displaying pin information
              const heading = document.createElement('h4') as HTMLElement;
              // Get the pin information from the output node's data in the editor's data structure
              this.pin =
                this.editor.drawflow.drawflow[name].data[
                  Chartconnection.inputs.input_1.connections[0].node
                ].data.pin;
              if (this.pin) {
                // Set the text content of the heading to the pin information
                heading.innerText = this.pin;
                // Append the heading element to the input node's UI
                node.childNodes[1].childNodes[1].appendChild(heading);
              }
            }
          }
        } else {
          const childNodeToRemove =
            node.childNodes[1].childNodes[1].childNodes[3];
          childNodeToRemove?.parentNode!.removeChild(childNodeToRemove);
        }

        // Remove method property from connected output nodes
        this.editor.drawflow.drawflow[name].data[
          connection.input_id
        ]?.outputs?.output_1?.connections?.map((x: any) => {
          const node = document.querySelector(`#node-${x.node}`) as HTMLElement;
          delete this.editor.drawflow.drawflow[name].data[x.node].method;
          if (chartconnectionRemoved) {
            const childNodeToRemove =
              node.childNodes[1].childNodes[1].childNodes[4];
            childNodeToRemove?.parentNode!.removeChild(childNodeToRemove);
          } else {
            const childNodeToRemove =
              node.childNodes[1].childNodes[1].childNodes[3];
            childNodeToRemove?.parentNode!.removeChild(childNodeToRemove);
          }
          // const childNodeToRemove = node.childNodes[1].childNodes[1].childNodes[3];
          // childNodeToRemove?.parentNode!.removeChild(childNodeToRemove);
        });

        // Handle removal of properties and HTML elements for Notifications node

        if (node_name == 'Notifications') {
          delete this.editor.drawflow.drawflow[name].data[connection.input_id]
            .pin;
          delete this.editor.drawflow.drawflow[name].data[connection.input_id]
            .input_name;
          delete this.editor.drawflow.drawflow[name].data[connection.input_id]
            .method;
          delete this.editor.drawflow.drawflow[name].data[connection.input_id]
            .additional_option;
          const node = document.querySelector(
            `#node-${connection.input_id}`
          ) as HTMLElement;

          const childNodeToRemove =
            node.childNodes[1].childNodes[1].childNodes[3];
          childNodeToRemove?.parentNode!.removeChild(childNodeToRemove);
        } else {
          // Handle removal of properties and routesdata updates for other nodes
          this.routesdata?.map((x: any) => {
            if (Number(x.datafeeds_Id) === Number(connection.output_id)) {
              if (x.dashboard_Id) {
                if (x.dashboard_Id?.length > 1) {
                  const index = x.dashboard_Id.indexOf(
                    Number(connection.input_id)
                  );
                  x.dashboard_Id.splice(index, 1);
                  if (x.dashboard_Id?.length == 1) {
                    x.dashboard_Id = x.dashboard_Id[0];
                  }
                } else if (x.dashboard_Id?.length == 1) {
                  const index = x.dashboard_Id.indexOf(
                    Number(connection.input_id)
                  );
                  x.dashboard_Id.splice(index, 1);
                  delete x.dashboard_Id;
                } else if (x.dashboard_Id?.length == 0) {
                  delete x.dashboard_Id;
                } else {
                  delete x.dashboard_Id;
                }
              }
            }
          });

          // Remove pin property from connected nodes if present
          if (
            this.editor.drawflow.drawflow[name].data[connection.input_id].pin
          ) {
            const node =
              this.editor.drawflow.drawflow[name].data[connection.input_id];
            if (node.pin) {
              delete node.pin;
              this.v1pin = false;
              this.v7pin = false;
            }
          }
        }
      } else {
        connectionRemoved = true;
      }
      console.log(this.routesdata);
    });
    /**
     * Event listener for mouse movement within the editor.
     * @param position - The current position of the mouse.
     */
    editor.on('mouseMove', (position: any) => {
      // this.dataservice.RouterlinkInActive.next();
      // this.lastRemove = true;
      // console.log("Position mouse x:" + position.x + " y:" + position.y);
    });
    /**
     * Event listener for adding a reroute point on a connection.
     * @param id - The ID of the added reroute point.
     */
    editor.on('addReroute', (id: string) => {
      this.dataservice.RouterlinkInActive.next();
      this.lastRemove = true;
      console.log('Reroute added ' + id);
    });
    /**
     * Event listener for removing a reroute point from a connection.
     * @param id - The ID of the removed reroute point.
     */
    editor.on('removeReroute', (id: string) => {
      this.dataservice.RouterlinkInActive.next();
      this.lastRemove = true;
      console.log('Reroute removed ' + id);
    });
    /**
     * Event listener for when a node is moved within the editor.
     * @param id - The ID of the moved node.
     */
    editor.on('nodeMoved', (id: string) => {
      this.dataservice.RouterlinkInActive.next();
      this.lastRemove = true;
      console.log('Node moved ' + id);
    });

    /**
     * Event listener for translation (panning) of the editor canvas.
     * @param position - The new translation position (x, y).
     */

    editor.on('translate', (position: { x: string; y: string }) => {
      this.dataservice.RouterlinkInActive.next();
      this.lastRemove = true;
      console.log('Translate x:' + position.x + ' y:' + position.y);
    });

    /**
     * Event listener for handling double-click events on a selected node. This listener is responsible for adding
     * and editing properties associated with the selected node.
     *
     * @param event - The double-click event triggered on the selected node.
     */
    editor.on('nodeSelected', (id: any) => {
      this.lastRemove = true;
      console.log('Node selected ' + id);

      const node = document.querySelector(`#node-${id}`) as HTMLElement;

      // Double-click event has occurred on the selected node
      node!.addEventListener('dblclick', async (event: Event) => {
        this.notify_type = [];
        this.propLoader = true;
        this.id = id;

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });

        this.nodename =
          this.editor.drawflow.drawflow[name].data[this.id].data.node_name;

        // updating the properties based on the nodename (selected node)
        if (this.nodename) {
          this.oldpin = false;
          if (this.nodename == 'Digital') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.digitaldatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.digitaldatastream.controls.alias.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.alias
              )
            );
            this.digitaldatastream.controls.datastream_Id.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .datastream_Id
              )
            );
            this.digitaldatastream.controls.datastream_type.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .datastream_type
              )
            );
            this.digitaldatastream.controls.pinmode.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.pinmode
              )
            );


            this.addresslist = [];


            this.addresscount =
              this.editor.drawflow.drawflow[name].data[this.id].data.addresscount;
            this.digitaldatastream.controls['addresscount'].setValue(this.addresscount);
            let addressdata =
              this.editor.drawflow.drawflow[name].data[this.id].data;
            await this.removeAddressControls('Digital');
            for (let i = 1; i <= this.addresscount; i++) {
              let type = addressdata['address-' + i].type;
              if (type == 'Single') {
                const newcontrol: AbstractControl = this.formbuilder.group({
                  address: [addressdata['address-' + i].address, Validators.required],
                  offset: [addressdata['address-' + i].offset, Validators.required],
                  params: [addressdata['address-' + i].params, Validators.required],
                  datatype: [addressdata['address-' + i].datatype, Validators.required],
                  program: [addressdata['address-' + i].program, Validators.required],
                  devices: [addressdata['address-' + i].devices, Validators.required],
                  holding_register: [addressdata['address-' + i].holding_register, Validators.required],
                  endianness:[addressdata['address-' + i].endianness,Validators.required],
                  type: [type]
                });

                this.digitaldatastream.addControl(
                  'address-' + i,
                  newcontrol
                )

                this.addresslist.push({
                  Groupname: 'address-' + i,
                  type: type
                })
              } else if (type == "Multiple") {
                const newcontrol: AbstractControl = this.formbuilder.group({
                  address1: [addressdata['address-' + i].address1, Validators.required],
                  address2: [addressdata['address-' + i].address2, Validators.required],
                  address3: [addressdata['address-' + i].address3, Validators.required],
                  offset1: [addressdata['address-' + i].offset1, Validators.required],
                  offset2: [addressdata['address-' + i].offset2, Validators.required],
                  offset3: [addressdata['address-' + i].offset3, Validators.required],
                  params: [addressdata['address-' + i].params, Validators.required],
                  params1: [addressdata['address-' + i].params1, Validators.required],
                  params2: [addressdata['address-' + i].params2, Validators.required],
                  params3: [addressdata['address-' + i].params3, Validators.required],
                  datatype: [addressdata['address-' + i].datatype, Validators.required],
                  program: [addressdata['address-' + i].program, Validators.required],
                  devices: [addressdata['address-' + i].devices, Validators.required],
                  holding_register: [addressdata['address-' + i].holding_register, Validators.required],
                  endianness:[addressdata['address-' + i].endianness,Validators.required],
                  type: [type]
                });

                this.digitaldatastream.addControl(
                  'address-' + i,
                  newcontrol
                )

                this.addresslist.push({
                  Groupname: 'address-' + i,
                  type: type
                })
              }
            }
            this.digitaldatastream.controls.isaddress.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.isaddress
              )
            );

            const pin = String(
              this.editor.drawflow.drawflow[name].data[this.id].data.pin
            );

            this.Digitalpin.forEach((x: any) => {
              if (this.disabledpin.includes(x.pin)) {
                x.isdisabled = true;
                if (x.pin == pin) {
                  x.isdisabled = false;
                  this.digitaldatastream.controls.pin.setValue(pin);
                }
              } else {
                x.isdisabled = false;
              }
            });
            this.Digitalpinpull.forEach((x: any) => {
              if (this.disabledpin.includes(x.pin)) {
                x.isdisabled = true;
                if (x.pin == pin) {
                  x.isdisabled = false;
                  this.digitaldatastream.controls.pin.setValue(pin);
                }
              } else {
                x.isdisabled = false;
              }
            });
            this.DigitalpinOut.forEach((x: any) => {
              if (this.disabledpin.includes(x.pin)) {
                x.isdisabled = true;
                if (x.pin == pin) {
                  x.isdisabled = false;
                  this.digitaldatastream.controls.pin.setValue(pin);
                }
              } else {
                x.isdisabled = false;
              }
            });

            this.digitaldatastream.controls.name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.name
              )
            );
          }
          if (this.nodename == 'Analog') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.analogdatastream.controls.alias.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.alias
              )
            );

            this.analogdatastream.controls.name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.name
              )
            );
            this.analogdatastream.controls.datastream_Id.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .datastream_Id
              )
            );
            this.analogdatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.analogdatastream.controls.datastream_type.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .datastream_type
              )
            );
            this.analogdatastream.controls.pinmode.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.pinmode
              )
            );

            this.analogdatastream.controls.default_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .default_value
              )
            );

            this.addresslist = [];

            this.addresscount =
              this.editor.drawflow.drawflow[name].data[this.id].data.addresscount;
            this.analogdatastream.controls['addresscount'].setValue(this.addresscount);
            let addressdata =
              this.editor.drawflow.drawflow[name].data[this.id].data;
            await this.removeAddressControls('Analog');
            for (let i = 1; i <= this.addresscount; i++) {
              let type = addressdata['address-' + i].type;
              if (type == 'Single') {
                const newcontrol: AbstractControl = this.formbuilder.group({
                  address: [addressdata['address-' + i].address, Validators.required],
                  offset: [addressdata['address-' + i].offset, Validators.required],
                  params: [addressdata['address-' + i].params, Validators.required],
                  datatype: [addressdata['address-' + i].datatype, Validators.required],
                  program: [addressdata['address-' + i].program, Validators.required],
                  devices: [addressdata['address-' + i].devices, Validators.required],
                  holding_register: [addressdata['address-' + i].holding_register, Validators.required],
                  endianness:[addressdata['address-' + i].endianness,Validators.required],
                  type: [type]
                });

                this.analogdatastream.addControl(
                  'address-' + i,
                  newcontrol
                )

                this.addresslist.push({
                  Groupname: 'address-' + i,
                  type: type
                })
              } else if (type == "Multiple") {
                const newcontrol: AbstractControl = this.formbuilder.group({
                  address1: [addressdata['address-' + i].address1, Validators.required],
                  address2: [addressdata['address-' + i].address2, Validators.required],
                  address3: [addressdata['address-' + i].address3, Validators.required],
                  offset1: [addressdata['address-' + i].offset1, Validators.required],
                  offset2: [addressdata['address-' + i].offset2, Validators.required],
                  offset3: [addressdata['address-' + i].offset3, Validators.required],
                  params: [addressdata['address-' + i].params, Validators.required],
                  params1: [addressdata['address-' + i].params1, Validators.required],
                  params2: [addressdata['address-' + i].params2, Validators.required],
                  params3: [addressdata['address-' + i].params3, Validators.required],
                  datatype: [addressdata['address-' + i].datatype, Validators.required],
                  program: [addressdata['address-' + i].program, Validators.required],
                  devices: [addressdata['address-' + i].devices, Validators.required],
                  holding_register: [addressdata['address-' + i].holding_register, Validators.required],
                  endianness:[addressdata['address-' + i].endianness,Validators.required],
                  type: [type]
                });

                this.analogdatastream.addControl(
                  'address-' + i,
                  newcontrol
                )

                this.addresslist.push({
                  Groupname: 'address-' + i,
                  type: type
                })
              }


            }

            this.analogdatastream.controls.isaddress.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.isaddress
              )
            );

            const pin = String(
              this.editor.drawflow.drawflow[name].data[this.id].data.pin
            );

            if (this.disabledpin.includes(pin)) {
              this.AnalogpinIn.map((x: any) => {
                if (this.disabledpin.includes(x.pin)) {
                  x.isdisabled = true;
                  if (x.pin == pin) {
                    x.isdisabled = false;
                    this.analogdatastream.controls.pin.setValue(pin);
                  }
                } else {
                  x.isdisabled = false;
                }
              });
              this.AnalogpinOut.map((x: any) => {
                if (this.disabledpin.includes(x.pin)) {
                  x.isdisabled = true;
                  if (x.pin == pin) {
                    x.isdisabled = false;
                    this.analogdatastream.controls.pin.setValue(pin);
                  }
                } else {
                  x.isdisabled = false;
                }
              });
            }

            this.analogdatastream.controls.units.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.units
              )
            );
          }
          if (this.nodename == 'Virtual') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.virtualpindatastream.controls.alias.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.alias
              )
            );
            this.virtualpindatastream.controls.name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.name
              )
            );
            this.virtualpindatastream.controls.datastream_Id.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .datastream_Id
              )
            );
            this.virtualpindatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.virtualpindatastream.controls.datastream_type.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .datastream_type
              )
            );
            this.virtualpindatastream.controls.pinmode.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.pinmode
              )
            );

            this.virtualpindatastream.controls.default_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .default_value
              )
            );

            this.addresslist = [];


            this.addresscount =
              this.editor.drawflow.drawflow[name].data[this.id].data.addresscount;
            this.virtualpindatastream.controls['addresscount'].setValue(this.addresscount);
            let addressdata =
              this.editor.drawflow.drawflow[name].data[this.id].data;
            await this.removeAddressControls('Virtual');
            for (let i = 1; i <= this.addresscount; i++) {
              let type = addressdata['address-' + i].type;
              if (type == 'Single') {
                const newcontrol: AbstractControl = this.formbuilder.group({
                  address: [addressdata['address-' + i].address, Validators.required],
                  offset: [addressdata['address-' + i].offset, Validators.required],
                  params: [addressdata['address-' + i].params, Validators.required],
                  datatype: [addressdata['address-' + i].datatype, Validators.required],
                  program: [addressdata['address-' + i].program, Validators.required],
                  devices: [addressdata['address-' + i].devices, Validators.required],
                  holding_register: [addressdata['address-' + i].holding_register, Validators.required],
                  endianness:[addressdata['address-' + i].endianness,Validators.required],
                  type: [type]
                });

                this.virtualpindatastream.addControl(
                  'address-' + i,
                  newcontrol
                )

                this.addresslist.push({
                  Groupname: 'address-' + i,
                  type: type
                })
              } else if (type == "Multiple") {
                const newcontrol: AbstractControl = this.formbuilder.group({
                  address1: [addressdata['address-' + i].address1, Validators.required],
                  address2: [addressdata['address-' + i].address2, Validators.required],
                  address3: [addressdata['address-' + i].address3, Validators.required],
                  offset1: [addressdata['address-' + i].offset1, Validators.required],
                  offset2: [addressdata['address-' + i].offset2, Validators.required],
                  offset3: [addressdata['address-' + i].offset3, Validators.required],
                  params: [addressdata['address-' + i].params, Validators.required],
                  params1: [addressdata['address-' + i].params1, Validators.required],
                  params2: [addressdata['address-' + i].params2, Validators.required],
                  params3: [addressdata['address-' + i].params3, Validators.required],
                  datatype: [addressdata['address-' + i].datatype, Validators.required],
                  program: [addressdata['address-' + i].program, Validators.required],
                  devices: [addressdata['address-' + i].devices, Validators.required],
                  holding_register: [addressdata['address-' + i].holding_register, Validators.required],
                  endianness:[addressdata['address-' + i].endianness,Validators.required],
                  type: [type]
                });

                this.virtualpindatastream.addControl(
                  'address-' + i,
                  newcontrol
                )

                this.addresslist.push({
                  Groupname: 'address-' + i,
                  type: type
                })
              }


            }

            this.virtualpindatastream.controls.isaddress.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.isaddress
              )
            );
            const pin = String(
              this.editor.drawflow.drawflow[name].data[this.id].data.pin
            );


            if (this.disabledpin.includes(pin)) {
              this.Virtualpin.map((x: any) => {
                if (this.disabledpin.includes(x.pin)) {
                  x.isdisabled = true;
                  if (x.pin == pin) {
                    x.isdisabled = false;
                    this.virtualpindatastream.controls.pin.setValue(pin);
                  }
                } else {
                  x.isdisabled = false;
                }
              });
            }

            this.event.controls.integer_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .integer_value
              )
            );
            this.event.controls.string_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .string_value
              )
            );
          }
          if (this.nodename == 'Switch') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.switchdatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );
            this.switchdatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
          }
          if (this.nodename == 'Chart') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.Chartdatastream.controls['title'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );
            this.Chartdatastream.controls['Min'].setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Min)
            );
            this.Chartdatastream.controls['Max'].setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Max)
            );
            this.Chartdatastream.controls['label'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.label
              )
            );
            this.Chartdatastream.controls['node_name'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.Chartdatastream.controls['chart_type'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .chart_type
              )
            );
            this.Chartdatastream.controls['timeseries'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .timeseries
              )
            );
            this.Chartdatastream.controls['color_scheme'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .color_scheme
              )
            );
            this.Chartdatastream.controls['xAxisLabel'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .xAxisLabel
              )
            );
            this.Chartdatastream.controls['yAxisLabel'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .yAxisLabel
              )
            );
            this.Chartdatastream.controls['chartfilter'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .chartfilter
              )
            );
            this.Chartdatastream.controls['autoScale'].setValue(
              this.editor.drawflow.drawflow[name].data[this.id].data.autoScale
            );
            console.log(this.Chartdatastream.value);
          }
          if (this.nodename == 'Slider') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.sliderdatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );
            this.sliderdatastream.controls.handlestep.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .handlestep
              )
            );
            this.sliderdatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.sliderdatastream.controls.Max.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Max)
            );
            this.sliderdatastream.controls.Min.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Min)
            );
            this.sliderdatastream.controls.Default_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .Default_value
              )
            );
          }
          if (this.nodename == 'Speedometer') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.speedometer.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );

            this.speedometer.controls.units.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.units
              )
            );


            if (this.editor.drawflow.drawflow[name].data[this.id].pin) {
              if (
                this.editor.drawflow.drawflow[name].data[this.id].pin == 'V1'
              ) {
                this.v1pin = true;
                if (
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .additional_option
                ) {
                  this.speedometer.controls.additional_option.setValue(
                    String(
                      this.editor.drawflow.drawflow[name].data[this.id].data
                        .additional_option
                    )
                  );
                } else {
                  this.speedometer.controls.additional_option.setValue(
                    'totalkwh'
                  );
                }
              } else {
                this.v1pin = false;
              }
            } else {
              this.v1pin = false;
            }

            this.speedometer.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.speedometer.controls.Max.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Max)
            );
            this.speedometer.controls.Min.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Min)
            );
            this.speedometer.controls.Min.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Min)
            );

          }
          if (this.nodename == 'Gauge') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.gaugedatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );
            this.gaugedatastream.controls.markers.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.markers
              )
            );
            this.gaugedatastream.controls.color.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.color
              )
            );
            this.gaugedatastream.controls.units.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.units
              )
            );

            this.gaugedatastream.controls.gaugeType.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.gaugeType
              )
            );
            if (this.editor.drawflow.drawflow[name].data[this.id].pin) {
              if (
                this.editor.drawflow.drawflow[name].data[this.id].pin == 'V1'
              ) {
                this.v1pin = true;
                if (
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .additional_option
                ) {
                  this.gaugedatastream.controls.additional_option.setValue(
                    String(
                      this.editor.drawflow.drawflow[name].data[this.id].data
                        .additional_option
                    )
                  );
                } else {
                  this.gaugedatastream.controls.additional_option.setValue(
                    'totalkwh'
                  );
                }
              } else {
                this.v1pin = false;
              }
            } else {
              this.v1pin = false;
            }

            this.gaugedatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.gaugedatastream.controls.Max.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Max)
            );
            this.gaugedatastream.controls.Min.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Min)
            );
            this.gaugedatastream.controls.Min.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Min)
            );
            this.color = String(
              this.editor.drawflow.drawflow[name].data[this.id].data.color
            );
          }
          if (this.nodename == 'Gauge3Phase') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.gauge3Phasedatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );
            this.gauge3Phasedatastream.controls.color.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.color
              )
            );
            this.gauge3Phasedatastream.controls.unit1.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.unit1
              )
            );
            this.gauge3Phasedatastream.controls.unit2.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.unit2
              )
            );
            this.gauge3Phasedatastream.controls.unit3.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.unit3
              )
            );

            this.gauge3Phasedatastream.controls.color.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.color
              )
            );
            this.gauge3Phasedatastream.controls.gaugeType.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.gaugeType
              )
            );
            this.gauge3Phasedatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );

            this.gauge3Phasedatastream.controls.Max.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Max)
            );
            this.gauge3Phasedatastream.controls.Min.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Min)
            );
            this.gauge3Phasedatastream.controls.Min.setValue(
              String(this.editor.drawflow.drawflow[name].data[this.id].data.Min)
            );
            this.color = String(
              this.editor.drawflow.drawflow[name].data[this.id].data.color
            );
          }
          if (this.nodename == 'piechart') {
            console.log(this.widgetbox);

            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.piechartdatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );

            this.piechartdatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
          }
          if (this.nodename == 'LED') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.leddatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );
            this.leddatastream.controls.color.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.color
              )
            );
            this.color = String(
              this.editor.drawflow.drawflow[name].data[this.id].data.color
            );

            this.leddatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
          }
          if (this.nodename == 'Label') {
            if (
              this.editor.drawflow.drawflow[name].data[this.id]?.pin == 'V1'
            ) {
              this.v1pin = true;
              this.v7pin = false;
              if (
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .additional_option
              ) {
                this.labeldatastream.controls.additional_option.setValue(
                  String(
                    this.editor.drawflow.drawflow[name].data[this.id].data
                      .additional_option
                  )
                );
              } else {
                this.editor.drawflow.drawflow[name].data[
                  this.id
                ].data.additional_option = 'totalkwh';
                this.labeldatastream.controls.additional_option.setValue(
                  'totalkwh'
                );
              }
            } else if (
              this.editor.drawflow.drawflow[name].data[this.id]?.pin == 'V7'
            ) {
              this.v7pin = true;
              this.v1pin = false;
              if (
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .emissionfactor
              ) {
                this.labeldatastream.controls.emissionfactor.setValue(
                  String(
                    this.editor.drawflow.drawflow[name].data[this.id].data
                      .emissionfactor
                  )
                );
              } else {
                this.editor.drawflow.drawflow[name].data[
                  this.id
                ].data.emissionfactor = '';
              }
            } else {
              this.v1pin = false;
              this.v7pin = false;
              this.editor.drawflow.drawflow[name].data[
                this.id
              ].data.additional_option = null;
              this.editor.drawflow.drawflow[name].data[
                this.id
              ].data.emissionfactor = null;
            }

            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.labeldatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );
            this.labeldatastream.controls['color'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.color
              )
            );
            this.labeldatastream.controls.units.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.units
              )
            );
            this.color =
              this.editor.drawflow.drawflow[name].data[this.id].data.color;
            this.labeldatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.labeldatastream.controls.additional_option.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .additional_option
              )
            );
            this.labeldatastream.controls.emissionfactor.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .emissionfactor
              )
            );
          }
          if (this.nodename == 'Cost') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.costdatastream.controls['title'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );
            this.costdatastream.controls['color'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.color
              )
            );
            this.costdatastream.controls['node_name'].setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.costlist = [];
            this.costcount =
              this.editor.drawflow.drawflow[name].data[this.id].data.costcount;
            this.costdatastream.controls['costcount'].setValue(this.costcount);
            let costdata =
              this.editor.drawflow.drawflow[name].data[this.id].data;
            for (let i = 1; i <= 7; i++) {
              this.costdatastream.removeControl('cost-' + i);
            }

            for (let i = 1; i <= this.costcount; i++) {
              let findType = costdata['cost-' + i].type;
              if (findType == 'Inbetween') {
                const newcontrol: AbstractControl = this.formbuilder.group({
                  fromkwh: [costdata['cost-' + i].fromkwh, Validators.required],
                  tokwh: [costdata['cost-' + i].tokwh, Validators.required],
                  cost: [costdata['cost-' + i].cost, Validators.required],
                  type: [costdata['cost-' + i].type],
                });

                this.costdatastream.addControl('cost' + '-' + i, newcontrol);
                this.costlist.push({
                  Groupname: 'cost' + '-' + i,
                  type: costdata['cost-' + i].type,
                });
              } else if (findType == 'Above') {
                const newcontrol: AbstractControl = this.formbuilder.group({
                  above_Kwh: [
                    costdata['cost-' + i].above_Kwh,
                    Validators.required,
                  ],
                  cost: [costdata['cost-' + i].cost, Validators.required],
                  type: [costdata['cost-' + i].type],
                });

                // this.costdatastream.addControl('cost' + '-' + i, newcontrol);
                this.costlist.push({
                  Groupname: 'cost' + '-' + i,
                  type: costdata['cost-' + i].type,
                });
                this.costdatastream.addControl('cost' + '-' + i, newcontrol);
              }
            }

            this.color = String(
              this.editor.drawflow.drawflow[name].data[this.id].data.color
            );
          }
          if (this.nodename == 'Terminal') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.terminaldatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );
            this.terminaldatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
            this.terminaldatastream.controls.inputhint.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.inputhint
              )
            );
            this.screencolor = String(
              this.editor.drawflow.drawflow[name].data[this.id].data.screencolor
            );
            this.textcolor = String(
              this.editor.drawflow.drawflow[name].data[this.id].data.textcolor
            );
          }
          if (this.nodename == 'Thermometer') {
            console.log(this.widgetbox);

            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.Thermometerdatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );

            this.Thermometerdatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
          }
          if (this.nodename == 'Weather') {
            console.log(this.widgetbox);

            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.Weatherdatastream.controls.title.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.title
              )
            );

            this.Weatherdatastream.controls.node_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.node_name
              )
            );
          }
          if (this.nodename == 'Notifications') {
            this.datastream_name =
              this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
            this.inputname =
              this.editor.drawflow.drawflow[name].data[this.id].input_name;

            // changing the datastream type of notification to show their respective fields

            /**
             * Determines the datastream type of a notification node and updates a boolean flag
             * to indicate whether the datastream type is 'Integer'. This flag is used to control
             * the display of specific fields related to 'Integer' datastream type.
             *
             * @param name - The name of the editor instance.
             * @param id - The ID of the notification node.
             */
            if (
              this.editor.drawflow.drawflow[name].data[this.id].ds_type ==
              'Integer'
            ) {
              this.virtual_Int = true; // Set the 'virtual_Int' flag to true if the datastream type is 'Integer'.
            } else {
              this.virtual_Int = false; // Set the 'virtual_Int' flag to false if the datastream type is 'String'.
            }

            // checking input name to show their respective properties

            /**
             * Checks the input name associated with a node to determine its type (e.g., 'Digital', 'Analog', 'Virtual'),
             * and updates boolean flags accordingly to control the display of specific properties for each type.
             * For instance, if the input name is 'Digital', the 'switchprop' flag is set to true and others are set to false.
             *
             * @param inputname - The input name of the node.
             */
            if (this.inputname == 'Digital') {
              this.switchprop = true; // Set the 'switchprop' flag to true to indicate 'Digital' input.
              this.GaugeProp = false; // Set the 'GaugeProp' flag to false.
              this.virtualprop = false; // Set the 'virtualprop' flag to false.
            }
            if (this.inputname == 'Analog') {
              this.GaugeProp = true; // Set the 'GaugeProp' flag to true to indicate 'Analog' input.
              this.switchprop = false; // Set the 'switchprop' flag to false.
              this.virtualprop = false; // Set the 'virtualprop' flag to false.
            }
            if (this.inputname == 'Virtual') {
              this.GaugeProp = false; // Set the 'GaugeProp' flag to false.
              this.switchprop = false; // Set the 'switchprop' flag to false.
              this.virtualprop = true; // Set the 'virtualprop' flag to true to indicate 'Virtual' input.
            }
            this.event.value.notify_type =
              this.editor.drawflow.drawflow[name].data[
                this.id
              ].data.notify_type;
            let splitvalue: any = this.event.value.notify_type?.split(',');
            this.notify_type = splitvalue;
            /**
             * Checks the notify_type associated with a node to determine their notification mode,
             * */
            this.notify_type.map((x: any) => {
              if (x === '1') {
                this.smstoogle = true;
              }
              if (x === '0') {
                this.emailToggle = true;
              }
            });
            /**
             * Checks the notify_type to set their respective values,
             * */
            if (this.emailToggle) {
              this.event.controls.user_email.setValue(
                String(
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .user_email
                )
              );
              this.user_Id =
                this.editor.drawflow.drawflow[name].data[
                  this.id
                ].data.user_email.split(',');

              this.user_Id = this.user_Id.map((x: any) => {
                return {
                  user_Id: x,
                };
              });

              this.user_Id = this.user_Id.map((x: any) => {
                const matchuseremail = this.useremail.find(
                  (z: any) => z.user_Id === Number(x.user_Id)
                );
                if (matchuseremail) {
                  return {
                    ...matchuseremail,
                  };
                }
              });
            }
            if (this.smstoogle) {
              this.event.controls.user_number.setValue(
                String(
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .user_number
                )
              );
              this.User_Number =
                this.editor.drawflow.drawflow[name].data[
                  this.id
                ].data.user_number.split(',');

              this.User_Number = this.User_Number.map((x: any) => {
                return {
                  user_Id: x,
                };
              });

              this.User_Number = this.User_Number.map((x: any) => {
                const matchusernumber = this.usernumber.find(
                  (z: any) => z.user_Id === Number(x.user_Id)
                );
                if (matchusernumber) {
                  return {
                    ...matchusernumber,
                  };
                }
              });
            }

            let connection =
              this.editor.drawflow.drawflow[name].data[this.id].method;

            if (connection === '0') {
              this.switchprop = true;
              this.GaugeProp = false;
              this.virtualprop = false;
            } else if (connection === '1') {
              this.GaugeProp = true;
              this.switchprop = false;
              this.virtualprop = false;
            } else if (connection === '2') {
              this.virtualprop = true;
              this.switchprop = false;
              this.GaugeProp = false;
            } else {
              this.switchprop = false;
              this.GaugeProp = false;
              this.virtualprop = false;
            }

            if (
              this.editor.drawflow.drawflow[name].data[this.id]
                .additional_option
            ) {
              this.event.controls.additional_option.setValue(
                String(
                  this.editor.drawflow.drawflow[name].data[this.id]
                    .additional_option
                )
              );
            }

            this.event.controls.event_name.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .event_name
              )
            );
            this.event.controls.event_type.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .event_type
              )
            );
            this.event.controls.devices.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .devices
              )
            );
            this.analog_type =
              this.editor.drawflow.drawflow[name].data[
                this.id
              ].data.analog_type;
            this.event.controls.analog_type.setValue(String(this.analog_type));
            if (this.analog_type == '0') {
              this.event.controls.analog_min.setValue(
                String(
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .analog_min
                )
              );
              this.event.controls.analog_max.setValue(
                String(
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .analog_max
                )
              );
            } else if (this.analog_type == '1') {
              this.event.controls.analog_leftmax.setValue(
                String(
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .analog_leftmax
                )
              );
              this.event.controls.analog_leftmin.setValue(
                String(
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .analog_leftmin
                )
              );

              this.event.controls.analog_rightmin.setValue(
                String(
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .analog_rightmin
                )
              );
              this.event.controls.analog_rightmax.setValue(
                String(
                  this.editor.drawflow.drawflow[name].data[this.id].data
                    .analog_rightmax
                )
              );
            }

            this.event.controls.color.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.color
              )
            );
            this.event.controls.start_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .start_value
              )
            );
            this.event.controls.end_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.end_value
              )
            );
            this.event.controls.St_Value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.St_Value
              )
            );
            this.event.controls.event_message_count.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .event_message_count
              )
            );
            this.event.controls.event_message_delay.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .event_message_delay
              )
            );
            this.event.controls.digital_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .digital_value
              )
            );

            this.event.controls.integer_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .integer_value
              )
            );
            this.event.controls.string_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .string_value
              )
            );
            this.event.controls.start_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .start_value
              )
            );
            this.event.controls.end_value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.end_value
              )
            );
            this.event.controls.St_Value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.St_Value
              )
            );
            this.event.controls.Int_Value.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data.Int_Value
              )
            );
            this.event.controls.virtual_min.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .virtual_min
              )
            );
            this.event.controls.virtual_max.setValue(
              String(
                this.editor.drawflow.drawflow[name].data[this.id].data
                  .virtual_max
              )
            );
          }

          this.openSidenav(this.id);
          this.propLoader = false;
        } else {
          // Set flags and properties for handling a node click event
          this.oldpin = true;
          this.datastream_name = '';
          this.openSidenav(this.id);
          const name = node.querySelector('p') as HTMLElement;
          this.nodename = String(name.innerText).trim();
          this.color = '#3633b7';
          if (this.nodename == 'Notifications') {
            // ... Code to handle properties and controls specific to 'Notifications' nodes
            this.user_Id = [];
            let clustername: any;
            this.productvalue.map((x: any) => {
              if (x.cluster_id === this.cluster_id) {
                clustername = x.cluster_api_Id;
              }
            });
            this.inputname =
              this.editor.drawflow.drawflow[clustername].data[
                this.id
              ].input_name;
            const connection =
              this.editor.drawflow.drawflow[clustername].data[this.id].method;
            if (connection === '0') {
              console.log(connection + 'work');
              this.switchprop = true;
              this.virtualprop = false;
              this.GaugeProp = false;
            } else if (connection === '1') {
              console.log(connection + 'work');
              this.GaugeProp = true;
              this.virtualprop = false;
              this.switchprop = false;
            } else if (connection === '2') {
              this.virtualprop = true;
              this.switchprop = false;
              this.GaugeProp = false;
            } else {
              console.log(connection + 'work');
              this.virtualprop = false;
              this.switchprop = false;
              this.GaugeProp = false;
            }
            if (
              this.editor.drawflow.drawflow[clustername].data[this.id]
                .ds_type == 'Integer'
            ) {
              this.virtual_Int = true;
            } else {
              this.virtual_Int = false;
            }
          }

          if (this.nodename == 'Digital' || this.nodename == 'Analog' || this.nodename == 'Virtual') {
            this.digitaldatastream.controls.isaddress.setValue('0');
            this.analogdatastream.controls.isaddress.setValue('0');
            this.virtualpindatastream.controls.isaddress.setValue('0');
          }

          if (this.nodename == 'Cost') {
            this.costdatastream.setValue({
              ['cost-' + 1]: {
                cost: null,
                tokwh: null,
              },
            });
            // this.costdatastream.value['cost-' + 1].cost = null
            // this.costdatastream.value['cost-' + 1].tokwh = null

            this.costcount = 1;
            this.costlist = [{ Groupname: 'cost' + '-' + this.costcount }];
            for (let i = 2; i <= 5; i++) {
              this.costdatastream.removeControl('cost-' + i);
            }
          }

          this.switchdatastream.controls.title.setValue(this.nodename);
          this.leddatastream.controls.title.setValue(this.nodename);
          this.gaugedatastream.controls.title.setValue(this.nodename);
          this.gauge3Phasedatastream.controls.title.setValue(this.nodename);
          this.piechartdatastream.controls.title.setValue(this.nodename);
          this.sliderdatastream.controls.title.setValue(this.nodename);
          this.terminaldatastream.controls.inputhint.setValue('Input here');
          this.terminaldatastream.controls.title.setValue(this.nodename);
          this.labeldatastream.controls.title.setValue(this.nodename);
          this.labeldatastream.controls.Max_kwh.setValue('0');
          this.Thermometerdatastream.controls.title.setValue(this.nodename);
          this.Weatherdatastream.controls.title.setValue(this.nodename);
          this.gauge3Phasedatastream.controls.title.setValue(this.nodename);
          this.speedometer.controls.title.setValue(this.nodename);
          this.gauge3Phasedatastream.controls.unit1.setValue('');
          this.gauge3Phasedatastream.controls.unit2.setValue('');
          this.gauge3Phasedatastream.controls.unit3.setValue('');
          this.gaugedatastream.controls.units.setValue('');
          this.gaugedatastream.controls.markers.setValue('1');
          this.labeldatastream.controls.units.setValue('');
          this.speedometer.controls.units.setValue('');
          this.Chartdatastream.controls['title'].setValue(this.nodename);
          this.Chartdatastream.controls['chartfilter'].setValue('Day');
          this.Chartdatastream.controls['autoScale'].setValue(false);
          this.event.controls.event_name.setValue(this.nodename);
          this.smstoogle = false;
          this.emailToggle = false;
          this.Chartdatastream.controls['chart_type'].reset('line-chart');
          this.event.controls.event_message_count.reset();
          this.event.controls.event_message_delay.reset();
          this.event.controls.analog_leftmax.reset();
          this.event.controls.analog_leftmin.reset();
          this.event.controls.digital_value.reset();
          this.event.controls.analog_max.reset();
          this.event.controls.analog_max.reset();
          this.event.controls.analog_rightmax.reset();
          this.event.controls.analog_rightmin.reset();
          this.event.controls.analog_type.reset();
          this.event.controls.additional_option.reset();
          this.event.controls.event_type.setValue('0');
          this.event.controls.devices.setValue('0');
          this.Chartdatastream.controls['color_scheme'].setValue('cool');

          if (this.hardware_name == 'Esp32') {
            this.digitaldatastream.controls.name.setValue('Digital');
            // this.digitaldatastream.controls.pin.setValue('2');
            this.digitaldatastream.controls.pinmode.setValue('Output');
            this.analogdatastream.controls.name.setValue('Analog');
            // this.analogdatastream.controls.pin.setValue('2');
            this.analogdatastream.controls.pinmode.setValue('Output');
            this.analogdatastream.controls.units.setValue('');

            this.virtualpindatastream.controls.name.setValue('Virtual');
            this.virtualpindatastream.controls.pin.setValue('V0');
            this.virtualpindatastream.controls.datastream_type.setValue(
              'Integer'
            );
            this.event.controls.integer_value.setValue('Greater than');
            this.event.controls.event_type.setValue('0');
            this.event.controls.devices.setValue('0');
            // this.virtualpindatastream.controls.units.setValue('None');

            // this.virtualpindatastream.controls.decimal_value.setValue('#.00000');
          } else if (this.hardware_name == 'Esp8266') {
            // this.digitaldatastream.controls.pin.setValue('D1');
            this.digitaldatastream.controls.pinmode.setValue('Output');
            // this.analogdatastream.controls.pin.setValue('D1');
            this.analogdatastream.controls.pinmode.setValue('Output');
            this.analogdatastream.controls.units.setValue('');
            // this.virtualpindatastream.controls.pin.setValue('V0');
            this.analogdatastream.controls.name.setValue('Analog');
            this.digitaldatastream.controls.name.setValue('Digital');
            this.virtualpindatastream.controls.name.setValue('Virtual');
            // this.virtualpindatastream.controls.units.setValue('None');
            this.virtualpindatastream.controls.datastream_type.setValue(
              'Integer'
            );
            this.event.controls.integer_value.setValue('Greater than');
            this.event.controls.event_type.setValue('0');
            this.event.controls.devices.setValue('0');
            // this.virtualpindatastream.controls.decimal_value.setValue('#.00000');
          }
          // Enable or disable based on whether the pin is in the 'disabledpin' array

          this.Digitalpin.forEach((x: any) => {
            if (this.disabledpin.includes(x.pin)) {
              x.isdisabled = true;
            } else {
              x.isdisabled = false;
            }
          });
          this.Digitalpinpull.forEach((x: any) => {
            if (this.disabledpin.includes(x.pin)) {
              x.isdisabled = true;
            } else {
              x.isdisabled = false;
            }
          });
          this.DigitalpinOut.forEach((x: any) => {
            if (this.disabledpin.includes(x.pin)) {
              x.isdisabled = true;
            } else {
              x.isdisabled = false;
            }
          });

          this.AnalogpinIn.map((x: any) => {
            if (this.disabledpin.includes(x.pin)) {
              x.isdisabled = true;
            } else {
              x.isdisabled = false;
            }
          });
          this.AnalogpinOut.map((x: any) => {
            if (this.disabledpin.includes(x.pin)) {
              x.isdisabled = true;
            } else {
              x.isdisabled = false;
            }
          });
          this.Virtualpin.map((x: any) => {
            if (this.disabledpin.includes(x.pin)) {
              x.isdisabled = true;
            } else {
              x.isdisabled = false;
            }
          });
          if (
            this.disabledpin.includes('2') ||
            this.disabledpin.includes('D1')
          ) {
            let D_Out_isdisabled = true;
            this.DigitalpinOut.map((x: any) => {
              if (!x.isdisabled && D_Out_isdisabled) {
                D_Out_isdisabled = false;
                this.digitaldatastream.controls.pin.setValue(x.pin);
              }
            });
          }

          if (
            this.disabledpin.includes('2') ||
            this.disabledpin.includes('D1')
          ) {
            let A_out_isdisabled = true;
            this.AnalogpinOut.map((x: any) => {
              if (!x.isdisabled && A_out_isdisabled) {
                A_out_isdisabled = false;
                this.analogdatastream.controls.pin.setValue(x.pin);
              }
            });
          }

          if (this.disabledpin.includes('V0')) {
            let V_isdisabled = true;
            this.Virtualpin.map((x: any) => {
              if (!x.isdisabled && V_isdisabled) {
                V_isdisabled = false;
                this.virtualpindatastream.controls.pin.setValue(x.pin);
              }
            });
          }
          console.log('node clicked');
          this.propLoader = false;
        }
      });
    });

    /**
     * Event listener for the "zoom" event in the editor.
     *
     * This event listener logs the current zoom level when the "zoom" event is triggered.
     *
     * @param {string} zoom - The current zoom level.
     */
    editor.on('zoom', (zoom: string) => {
      // Log the current zoom level
      console.log('Zoom level ' + zoom);
    });
    /**
     * Event listener for the "moduleChanged" event in the editor.
     * This event listener is triggered when the module is changed and handles updating the editor's data and UI.
     *
     * @param {string} name - The name of the module that was changed.
     */
    editor.on('moduleChanged', (name: string) => {
      // Fetch hardware data stream pins for the current hardware.
      this.gethardwaredatastreampin(this.hardware_name);
      // Clear/reset data and variables.
      this.newdatacome = [];
      this.disabledpin = [];
      this.widgetbox = [];
      this.routesdata = [];
      this.sidenav.close();
      this.cluster_name = name;
      this.CostNode = false;
      // Prepare drawflow object for the module.
      const drawflow = {
        drawflow: {
          [name]: {
            data: {},
          },
        },
      };
      // Fetch routes and data for the current module.
      this.dataservice.getRoutes(this.cluster_id).subscribe((res: any) => {
        if (res.status == '200') {
          if (res.data[1]?.length === 1) {
            // Process fetched data and populate editor.

            // Fetch drawflow, routes, and dashboard data.
            let drawflow = res?.data[1][0].drawflow_data;
            let routesdata = res?.data[1][0].routes_data;
            let dashboard = res.data[1][0].dashboard_data;

            this.costValue = JSON.parse(res.data[1][0].cost);

            this.events_data = res.data[0];


            this.events_data.forEach((x: any) => {
              x['node_name'] = 'Notifications';
            });
            // Parse routesdata and widgetbox.
            this.routesdata = JSON.parse(routesdata);
            this.widgetbox = JSON.parse(dashboard);
            drawflow = JSON.parse(drawflow);
            let {
              zoom,
              zoom_last_value,
              pos_x,
              pos_x_start,
              pos_y_start,
              pos_y,
              canvas_x,
              canvas_y,
              curvature,
              line_path,
            }: any = drawflow;
            // Check if drawflow data exists for the module.
            if (drawflow[name]?.data[1]) {
              // this.FirstSave = true;
              this.lastRemove = false;
            }
            this.editor.drawflow = '';

            // Import and populate the editor with drawflow data.
            this.editor.import(drawflow);
            this.editor.canvas_x = canvas_x;
            this.editor.canvas_y = canvas_y;
            this.editor.curvature = curvature;
            this.editor.line_path = line_path;
            this.editor.pos_x = pos_x;
            this.editor.pos_x_start = pos_x_start;
            this.editor.pos_y = pos_y;
            this.editor.pos_y_start = pos_y_start;
            this.editor.zoom = zoom;
            this.editor.zoom_last_value = zoom_last_value;
            editor.zoom_in();
            editor.zoom_out();

            let data: any = [];
            // Extract data from drawflow and process each node.
            this.editor.drawflow.drawflow[this.cluster_name].data;
            Object.values(
              this.editor.drawflow.drawflow[this.cluster_name].data
            ).map((x: any) => {
              data.push(x);
            });

            data.map((x: any) => {
              if (x.data.pin) {
                this.disabledpin.push(x.data.pin);
              }
              // Update node labels.
              const node = document.querySelector(
                `#node-${x.id}`
              ) as HTMLElement;
              const ptag = node.querySelector('p') as HTMLElement;
              if (x.data.name) {
                ptag.innerText = x.data.name;
              } else if (x.data.title) {
                ptag.innerText = x.data.title;
              } else if (x.data.event_name) {
                ptag.innerText = x.data.event_name;
              } else {
                ptag;
              }

              // Append pin information based on node type and method.
              if (x.data.node_name == 'Digital') {
                const heading = document.createElement('h6') as HTMLElement;
                heading.innerText = x.data.pin;
                node.childNodes[1].childNodes[1].appendChild(heading);
              }
              if (x.data.node_name == 'Analog') {
                const heading = document.createElement('h5') as HTMLElement;
                heading.innerText = x.data.pin;
                node.childNodes[1].childNodes[1].appendChild(heading);
              }
              if (x.data.node_name == 'Virtual') {
                const heading = document.createElement('h4') as HTMLElement;
                heading.innerText = x.data.pin;
                node.childNodes[1].childNodes[1].appendChild(heading);
              }
              if (x.data.node_name == 'Cost') {
                this.CostNode = true;
              }

              if (x.method == '0') {
                if (x.pin) {
                  const heading = document.createElement('h6') as HTMLElement;
                  heading.innerText = x.pin;
                  node.childNodes[1].childNodes[1].appendChild(heading);
                }
              }

              if (x.method == '1') {
                if (x.pin) {
                  const heading = document.createElement('h5') as HTMLElement;
                  heading.innerText = x.pin;
                  node.childNodes[1].childNodes[1].appendChild(heading);
                }
              }

              if (x.method == '2') {
                if (x.pin) {
                  const heading = document.createElement('h4') as HTMLElement;
                  heading.innerText = x.pin;
                  node.childNodes[1].childNodes[1].appendChild(heading);
                }
              }

              if (x.input_name == 'Digital') {
                if (x.data.Multiconnection) {
                  const heading = document.createElement('h3') as HTMLElement;
                  heading.innerText = '*';
                  node.childNodes[1].childNodes[1].appendChild(heading);
                } else {
                  if (x.pin) {
                    const heading = document.createElement('h6') as HTMLElement;
                    heading.innerText = x.pin;
                    node.childNodes[1].childNodes[1].appendChild(heading);
                  }
                }
              }
              if (x.input_name == 'Analog') {
                if (x.data.Multiconnection) {
                  const heading = document.createElement('h3') as HTMLElement;
                  heading.innerText = '*';
                  node.childNodes[1].childNodes[1].appendChild(heading);
                } else {
                  if (x.pin) {
                    const heading = document.createElement('h5') as HTMLElement;
                    heading.innerText = x.pin;
                    node.childNodes[1].childNodes[1].appendChild(heading);
                  }
                }
              }
              if (x.input_name == 'Virtual') {
                if (x.data.Multiconnection) {
                  const heading = document.createElement('h3') as HTMLElement;
                  heading.innerText = '*';
                  node.childNodes[1].childNodes[1].appendChild(heading);
                } else {
                  if (x.pin) {
                    const heading = document.createElement('h4') as HTMLElement;
                    heading.innerText = x.pin;
                    node.childNodes[1].childNodes[1].appendChild(heading);
                  }
                }
              }
            });

            this.Getdatafeedsdata(this.cluster_id);
          } else {
            this.editor.drawflow = '';
            this.editor.drawflow = drawflow;
          }
        } else {
          this.toastr.error('Error occurred');
        }
      });
      this.dataservice
        .geslavedevice(this.cluster_id)
        .subscribe(async (res: any) => {
          if (res.status == 200) {

            this.slavedevices = res?.data

            // this.gethardwaredatastreampin(this.hardware_name);
          } else {
            this.toastr.error('Error occurred');
          }
        });
      console.log('Module Changed ' + name);
    });

    // } else if (!this.iscreate) {
    //   this.toastr.info("User not permitted")
    // }
  }

  /**
   * Function to create a new Digital node in the editor.
   * This function handles creating a Digital node and updating relevant data and UI.
   */
  Digital() {

    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      this.digitaldatastream.value.name =
        this.digitaldatastream.value.name?.replace(/[^\S\r\n]{2,}/g, ' ');
      this.digitaldatastream.value.alias =
        this.digitaldatastream.value.alias?.replace(/[^\S\r\n]{2,}/g, ' ');
      if (this.digitaldatastream.invalid) {
        return;
      } else {
        // Get the pin value entered by the user
        this.datass = this.digitaldatastream.value;
        this.disabledpin.push(this.datass.pin);
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });

        this.datafeedsdata.push({
          ...this.digitaldatastream.value,
          datafeeds_Id: this.id,
        });
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;
        this.pin = this.editor.drawflow.drawflow[name].data[this.id].data.pin;
        // Update node name in the UI.
        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.digitaldatastream.value.name);

        // Create and append the pin heading element.
        const heading = document.createElement('h6') as HTMLElement;
        heading.innerText = this.pin;
        node.childNodes[1].childNodes[1].appendChild(heading);
        node.classList.remove('shake-border');

        this.editor
          .getNodeFromId(this.id)
          .outputs.output_1.connections?.map((y: any) => {



            const node1 = document.querySelector(
              `#node-${y.node}`
            ) as HTMLElement;
            node1.classList.remove('shake-border');
          });

        const found = this.routesdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });
        if (found) {
          const index = this.routesdata.indexOf(found);

          this.routesdata[index] = {
            ...found,
            ...this.digitaldatastream.value,
            datafeeds_Id: Number(this.id),
          };
        } else {
          this.routesdata.push({
            ...this.digitaldatastream.value,
            datafeeds_Id: Number(this.id),
          });
        }
        // this.routesdata[this.id] = this.digitaldatastream.value;
        // Set the text content of the heading


        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs.output_1.connections?.map((x: any) => {
          const foundItem = this.events_data?.find((z: any) => {
            return Number(z.event_node_Id) === Number(x.node);
          });
          if (foundItem) {
            const index = this.events_data.indexOf(foundItem);
            this.events_data.splice(index, 1);
            this.events_data.push({ ...foundItem, pin: this.pin });
          }

          this.editor.drawflow.drawflow[name].data[x.node]['pin'] = this.pin;
          this.editor.drawflow.drawflow[name].data[
            x.node
          ].outputs.output_1?.connections?.map((y: any) => {
            const foundItem = this.events_data?.find((z: any) => {
              return Number(z.event_node_Id) === Number(x.node);
            });
            if (foundItem) {
              const index = this.events_data.indexOf(foundItem);
              this.events_data.splice(index, 1);
              this.events_data.push({ ...foundItem, pin: this.pin });
            }

            this.editor.drawflow.drawflow[name].data[y.node]['pin'] = this.pin;
            const node = document.querySelector(
              `#node-${y.node}`
            ) as HTMLElement;
            const heading = document.createElement('h6') as HTMLElement;
            heading.innerText = this.pin;
            node.childNodes[1].childNodes[1].appendChild(heading);
          });
          const node = document.querySelector(`#node-${x.node}`) as HTMLElement;
          const heading = document.createElement('h6') as HTMLElement;

          heading.innerText =
            this.editor.drawflow.drawflow[name].data[x.node]['pin'];
          node.childNodes[1].childNodes[1].appendChild(heading);
        });

        this.sidenav.close();
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function to update Digital node in the editor.
   * This function handles updating a Digital node and updating relevant data and UI.
   */
  UpdateDigital() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.digitaldatastream.invalid) {
        return;
      } else {
        // Get the pin value entered by the user
        this.datass = this.digitaldatastream.value;
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        const remove_pin =
          this.editor.drawflow.drawflow[name].data[this.id].data.pin;
        const remove_pin_index = this.disabledpin.indexOf(remove_pin);
        this.disabledpin.splice(remove_pin_index, 1, this.datass.pin);

        const foundItem = this.datafeedsdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });

        const index = this.datafeedsdata.indexOf(foundItem);
        this.datafeedsdata.splice(index, 1);
        this.digitaldatastream.value.datastream_Id = foundItem?.datastream_Id;

        this.datafeedsdata.push({
          ...this.digitaldatastream.value,
          datafeeds_Id: this.id

        });

        const found = this.routesdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });
        if (found) {
          const index = this.routesdata.indexOf(found);
          this.routesdata[index] = {
            ...found,
            ...this.digitaldatastream.value,
            datafeeds_Id: Number(this.id)
          };
        } else {
          this.routesdata.push({
            ...this.digitaldatastream.value,
            datafeeds_Id: Number(this.id)
          });
        }
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;
        this.pin = this.datass.pin;
        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        node.classList.remove('shake-border');
        // if (this.datass.pinmode == "Output") {
        //   node.childNodes[1].childNodes[1].childNodes[1].childNodes[3].textContent = "Out"
        // } else if (this.datass.pinmode == "Input") {
        //   node.childNodes[1].childNodes[1].childNodes[1].childNodes[3].textContent = "In"
        // } else if (this.datass.pinmode == "Input_pullup") {
        //   node.childNodes[1].childNodes[1].childNodes[1].childNodes[3].textContent = "InU"
        // }

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.digitaldatastream.value.name);
        node.childNodes[1].childNodes[1].childNodes[3].textContent = this.pin;

        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs.output_1?.connections?.map((x: any) => {
          const foundItem = this.events_data?.find((z: any) => {
            return Number(z.event_node_Id) === Number(x.node);
          });
          if (foundItem) {
            const index = this.events_data.indexOf(foundItem);
            this.events_data.splice(index, 1);
            foundItem.pin = this.pin;
            this.events_data.push({ ...foundItem });
          }
          this.editor.drawflow.drawflow[name].data[
            x.node
          ].outputs?.output_1?.connections?.map((y: any) => {
            const foundItem = this.events_data?.find((z: any) => {
              return Number(z.event_node_Id) === Number(y.node);
            });
            if (foundItem) {
              const index = this.events_data.indexOf(foundItem);
              this.events_data.splice(index, 1);
              foundItem.pin = this.pin;
              this.events_data.push({ ...foundItem });
            }
            this.editor.drawflow.drawflow[name].data[y.node]['pin'] = this.pin;
            const node = document.querySelector(
              `#node-${y.node}`
            ) as HTMLElement;
            node.childNodes[1].childNodes[1].childNodes[3].textContent =
              this.pin;
          });
          const node = document.querySelector(`#node-${x.node}`) as HTMLElement;
          this.editor.drawflow.drawflow[name].data[x.node]['pin'] = this.pin;

          node.childNodes[1].childNodes[1].childNodes[3].textContent = this.pin;
        });

        this.sidenav.close();
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function to create a new Analog node in the editor.
   * This function handles creating a Analog node and updating relevant data and UI.
   */

  Analog() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      this.analogdatastream.value.name =
        this.analogdatastream.value.name?.replace(/[^\S\r\n]{2,}/g, ' ');
      this.analogdatastream.value.alias =
        this.analogdatastream.value.alias?.replace(/[^\S\r\n]{2,}/g, ' ');

      if (this.analogdatastream.invalid) {
        return;
      } else {
        // Get the pin value entered by the user
        this.datass = this.analogdatastream.value;
        this.disabledpin.push(this.datass.pin);
        const found = this.routesdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });
        if (found) {
          const index = this.routesdata.indexOf(found);
          this.routesdata[index] = {
            ...found,
            ...this.analogdatastream.value,
            datafeeds_Id: Number(this.id),
          };
        } else {
          this.routesdata.push({
            ...this.analogdatastream.value,
            datafeeds_Id: Number(this.id),
          });
        }

        this.datafeedsdata.push({
          ...this.analogdatastream.value,
          datafeeds_Id: this.id,
        });
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });

        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        this.pin = this.editor.drawflow.drawflow[name].data[this.id].data.pin;
        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
          String(this.analogdatastream.value.name);
        node.classList.remove('shake-border');

        this.editor
          .getNodeFromId(this.id)
          .outputs?.output_1?.connections?.map((y: any) => {
            const node1 = document.querySelector(
              `#node-${y.node}`
            ) as HTMLElement;
            node1.classList.remove('shake-border');
          });
        // Create the heading element
        const heading = document.createElement('h5') as HTMLElement;
        heading.innerText = this.pin;

        // Set the text content of the heading
        node.childNodes[1].childNodes[1].appendChild(heading);
        // if (this.datass.pinmode == "Output") {
        //   const pinmode = document.createElement('h1') as HTMLElement;
        //   pinmode.innerText = "Out";
        //   node.childNodes[1].childNodes[1].appendChild(pinmode)
        //   console.log(node.childNodes[1].childNodes[1])
        // } else if (this.datass.pinmode == "Input") {
        //   const pinmode = document.createElement('h2') as HTMLElement;
        //   pinmode.innerText = "In"
        //   node.childNodes[1].childNodes[1].appendChild(pinmode)
        // }
        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs?.output_1?.connections?.map((x: any) => {
          const foundItem = this.events_data?.find((z: any) => {
            return Number(z.event_node_Id) === Number(x.node);
          });
          if (foundItem) {
            const index = this.events_data.indexOf(foundItem);
            this.events_data.splice(index, 1);
            this.events_data.push({ ...foundItem, pin: this.pin });
          }
          this.editor.drawflow.drawflow[name].data[
            x.node
          ].outputs?.output_1?.connections?.map((y: any) => {
            const foundItem = this.events_data?.find((z: any) => {
              return Number(z.event_node_Id) === Number(x.node);
            });
            if (foundItem) {
              const index = this.events_data.indexOf(foundItem);
              this.events_data.splice(index, 1);
              this.events_data.push({ ...foundItem, pin: this.pin });
            }
            this.editor.drawflow.drawflow[name].data[y.node]['pin'] = this.pin;
            const node = document.querySelector(
              `#node-${y.node}`
            ) as HTMLElement;
            const heading = document.createElement('h5') as HTMLElement;
            heading.innerText = this.pin;

            node.childNodes[1].childNodes[1].appendChild(heading);
          });

          this.editor.drawflow.drawflow[name].data[x.node]['pin'] = this.pin;
          const node = document.querySelector(`#node-${x.node}`) as HTMLElement;
          const heading = document.createElement('h5') as HTMLElement;
          heading.innerText =
            this.editor.drawflow.drawflow[name].data[x.node]['pin'];
          node.childNodes[1].childNodes[1].appendChild(heading);
        });

        this.sidenav.close();
        // this.toastr.success('Analog Data feeds Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Function to update Analog node in the editor.
   * This function handles updating a Analog node and updating relevant data and UI.
   */
  UpdateAnalog() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.analogdatastream.invalid) {
        return;
      } else {
        // Get the pin value entered by the user
        this.datass = this.analogdatastream.value;
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        const remove_pin =
          this.editor.drawflow.drawflow[name].data[this.id].data.pin;
        const remove_pin_index = this.disabledpin.indexOf(remove_pin);
        this.disabledpin.splice(remove_pin_index, 1, this.datass.pin);
        const foundItem = this.datafeedsdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });

        const found = this.routesdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });
        if (found) {
          const index = this.routesdata.indexOf(found);
          this.routesdata[index] = {
            ...found,
            ...this.analogdatastream.value,
            datafeeds_Id: Number(this.id),
          };
        } else {
          this.routesdata.push({
            ...this.analogdatastream.value,
            datafeeds_Id: Number(this.id),
          });
        }

        const index = this.datafeedsdata.indexOf(foundItem);
        this.datafeedsdata.splice(index, 1);
        this.analogdatastream.value.datastream_Id = foundItem?.datastream_Id;
        this.datafeedsdata.push({
          ...this.analogdatastream.value,
          datafeeds_Id: this.id

        });
        // this.routesdata[this.id] = this.analogdatastream.value

        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;
        this.pin = this.editor.drawflow.drawflow[name].data[this.id].data.pin;
        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        node.classList.remove('shake-border');
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
          String(this.analogdatastream.value.name);
        node.childNodes[1].childNodes[1].childNodes[3].textContent = this.pin;
        // if (this.datass.pinmode == "Output") {
        //   node.childNodes[1].childNodes[1].childNodes[1].childNodes[4].textContent = "Out"
        // } else if (this.datass.pinmode == "Input") {
        //   node.childNodes[1].childNodes[1].childNodes[1].childNodes[4].textContent = "In"
        // }
        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs?.output_1?.connections?.map((x: any) => {
          const foundItem = this.events_data?.find((z: any) => {
            return Number(z.event_node_Id) === Number(x.node);
          });
          if (foundItem) {
            const index = this.events_data.indexOf(foundItem);
            this.events_data.splice(index, 1);
            this.events_data.push({ ...foundItem, pin: this.pin });
          }
          this.editor.drawflow.drawflow[name].data[
            x.node
          ]?.outputs?.output_1?.connections?.map((y: any) => {
            const foundItem = this.events_data?.find((z: any) => {
              return Number(z.event_node_Id) === Number(y.node);
            });
            if (foundItem) {
              const index = this.events_data.indexOf(foundItem);
              this.events_data.splice(index, 1);
              this.events_data.push({ ...foundItem, pin: this.pin });
            }
            this.editor.drawflow.drawflow[name].data[y.node]['pin'] = this.pin;
            const node = document.querySelector(
              `#node-${y.node}`
            ) as HTMLElement;

            node.childNodes[1].childNodes[1].childNodes[3].textContent =
              this.pin;
          });

          const node = document.querySelector(`#node-${x.node}`) as HTMLElement;
          this.editor.drawflow.drawflow[name].data[x.node]['pin'] = this.pin;
          node.childNodes[1].childNodes[1].childNodes[3].textContent = this.pin;
        });
        this.sidenav.close();
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Function to create a new Virtual node in the editor.
   * This function handles creating a Virtual node and updating relevant data and UI.
   */
  Virtual() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      this.virtualpindatastream.value.name =
        this.virtualpindatastream.value.name?.replace(/[^\S\r\n]{2,}/g, ' ');
      this.virtualpindatastream.value.alias =
        this.virtualpindatastream.value.alias?.replace(/[^\S\r\n]{2,}/g, ' ');

      if (this.virtualpindatastream.invalid) {
        return;
      } else {
        // Get the pin value entered by the user
        this.datass = this.virtualpindatastream.value;
        this.disabledpin.push(this.datass.pin);
        this.datafeedsdata.push({
          ...this.virtualpindatastream.value,
          datafeeds_Id: this.id,
        });
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });

        // append dstype via widget

        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs?.output_1?.connections?.map((x: any) => {
          //  console.log(this.editor.drawflow.drawflow[name].data[x.node].data.datastream_type);
          this.editor.drawflow.drawflow[name].data[
            x.node
          ].outputs.output_1.connections?.map((y: any) => {
            this.editor.drawflow.drawflow[name].data[y.node]['ds_type'] =
              this.datass.datastream_type;
          });
        });

        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs.output_1.connections?.map((x: any) => {
          this.editor.drawflow.drawflow[name].data[x.node]['ds_type'] =
            this.datass.datastream_type;
        });
        const found = this.routesdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });

        if (found) {
          const index = this.routesdata.indexOf(found);
          this.routesdata[index] = {
            ...found,
            ...this.virtualpindatastream.value,
            datafeeds_Id: Number(this.id),
          };
          // this.routesdata.splice(index, 1);
          // this.routesdata.push({ ...this.virtualpindatastream.value, "datafeeds_Id": this.id })
        } else {
          this.routesdata.push({
            ...this.virtualpindatastream.value,
            datafeeds_Id: Number(this.id),
          });
        }
        // this.routesdata[this.id] = this.virtualpindatastream.value

        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;
        this.pin = this.datass.pin;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        node.classList.remove('shake-border');

        this.editor
          .getNodeFromId(this.id)
          .outputs?.output_1?.connections?.map((y: any) => {
            const node1 = document.querySelector(
              `#node-${y.node}`
            ) as HTMLElement;
            node1.classList.remove('shake-border');
          });
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
          String(this.virtualpindatastream.value.name);
        // Create the heading element
        const heading = document.createElement('h4') as HTMLElement;
        heading.innerText = this.pin;

        // Set the text content of the heading
        node.childNodes[1].childNodes[1].appendChild(heading);

        // if (this.datass.datastream_type == "Integer") {
        //   const pinmode = document.createElement('h1') as HTMLElement;
        //   pinmode.innerText = "I";
        //   node.childNodes[1].childNodes[1].appendChild(pinmode)
        //   console.log(node.childNodes[1].childNodes[1])
        // } else if (this.datass.datastream_type == "String") {
        //   const pinmode = document.createElement('h2') as HTMLElement;
        //   pinmode.innerText = "S"
        //   node.childNodes[1].childNodes[1].appendChild(pinmode)
        // }
        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs.output_1.connections?.map((x: any) => {
          this.editor.drawflow.drawflow[name].data[
            x.node
          ].outputs?.output_1?.connections?.map((y: any) => {
            this.editor.drawflow.drawflow[name].data[y.node]['pin'] = this.pin;
            const node = document.querySelector(
              `#node-${y.node}`
            ) as HTMLElement;
            const heading = document.createElement('h4') as HTMLElement;
            heading.innerText =
              this.editor.drawflow.drawflow[name].data[y.node]['pin'];
            node.childNodes[1].childNodes[1].appendChild(heading);
          });
          this.editor.drawflow.drawflow[name].data[x.node]['pin'] = this.pin;
          const node = document.querySelector(`#node-${x.node}`) as HTMLElement;

          const heading = document.createElement('h4') as HTMLElement;
          heading.innerText = this.pin;
          node.childNodes[1].childNodes[1].appendChild(heading);
        });

        this.sidenav.close();
        // this.toastr.success('Virtual Data feeds Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Function to update Virtual node in the editor.
   * This function handles updating a Virtual node and updating relevant data and UI.
   */
  UpdateVirtual() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.virtualpindatastream.invalid) {
        return;
      } else {
        // Get the pin value entered by the user
        this.datass = this.virtualpindatastream.value;

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs?.output_1?.connections?.map((x: any) => {
          this.editor.drawflow.drawflow[name].data[
            x.node
          ].outputs?.output_1?.connections?.map((y: any) => {
            this.editor.drawflow.drawflow[name].data[y.node]['ds_type'] =
              this.datass.datastream_type;
          });
        });
        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs.output_1.connections?.map((x: any) => {
          if (this.editor.drawflow.drawflow[name].data[x.node].ds_type) {
            this.editor.drawflow.drawflow[name].data[x.node].ds_type =
              this.datass.datastream_type;
          }
        });
        const remove_pin =
          this.editor.drawflow.drawflow[name].data[this.id].data.pin;
        const remove_pin_index = this.disabledpin.indexOf(remove_pin);
        this.disabledpin.splice(remove_pin_index, 1, this.datass.pin);

        const foundItem = this.datafeedsdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });

        const index = this.datafeedsdata.indexOf(foundItem);
        this.datafeedsdata.splice(index, 1);
        this.virtualpindatastream.value.datastream_Id =
          foundItem?.datastream_Id;
        this.datafeedsdata.push({
          ...this.virtualpindatastream.value,
          datafeeds_Id: this.id
        });

        const found = this.routesdata?.find((x: any) => {
          return Number(x.datafeeds_Id) === Number(this.id);
        });
        if (found) {
          const index = this.routesdata.indexOf(found);
          this.routesdata[index] = {
            ...found,
            ...this.virtualpindatastream.value,
            datafeeds_Id: Number(this.id),
          };
        } else {
          this.routesdata.push({
            ...this.virtualpindatastream.value,
            datafeeds_Id: Number(this.id),
          });
        }

        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;
        this.pin = this.editor.drawflow.drawflow[name].data[this.id].data.pin;
        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        node.classList.remove('shake-border');
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
          String(this.virtualpindatastream.value.name);
        node.childNodes[1].childNodes[1].childNodes[3].textContent = this.pin;
        // if (this.datass.datastream_type == "Integer") {
        //   node.childNodes[1].childNodes[1].childNodes[4].textContent = "I"
        // } else if (this.datass.datastream_type == "String") {
        //   node.childNodes[1].childNodes[1].childNodes[4].textContent = "S"
        // }
        this.editor.drawflow.drawflow[name].data[
          this.id
        ].outputs.output_1.connections?.map((x: any) => {
          const foundItem = this.events_data?.find((z: any) => {
            return Number(z.event_node_Id) === Number(x.node);
          });
          if (foundItem) {
            const index = this.events_data.indexOf(foundItem);
            this.events_data.splice(index, 1);
            this.events_data.push({ ...foundItem, pin: this.pin });
          }

          this.editor.drawflow.drawflow[name].data[
            x.node
          ].outputs?.output_1?.connections?.map((y: any) => {
            const foundItem = this.events_data?.find((z: any) => {
              return Number(z.event_node_Id) === Number(y.node);
            });
            if (foundItem) {
              const index = this.events_data.indexOf(foundItem);
              this.events_data.splice(index, 1);
              this.events_data.push({ ...foundItem, pin: this.pin });
            }
            this.editor.drawflow.drawflow[name].data[y.node]['pin'] = this.pin;
            const node = document.querySelector(
              `#node-${y.node}`
            ) as HTMLElement;
            node.childNodes[1].childNodes[1].childNodes[3].textContent =
              this.pin;
          });

          const node = document.querySelector(`#node-${x.node}`) as HTMLElement;
          this.editor.drawflow.drawflow[name].data[x.node]['pin'] = this.pin;
          node.childNodes[1].childNodes[1].childNodes[3].textContent = this.pin;
        });
        this.sidenav.close();
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function to create a switch node in the editor.
   * This function handles creating and updating a  switch node and updating relevant data and UI.
   */
  createswitch() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.switchdatastream.invalid) {
        return;
      } else {
        this.switchdatastream.value.title =
          this.switchdatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');
        // Get the pin value entered by the user
        this.datass = this.switchdatastream.value;
        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });
        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);
          this.widgetbox.push({
            ...this.switchdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.switchdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });

        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.switchdatastream.value.title);
        this.sidenav.close();
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function to create a slider node in the editor.
   * This function handles creating and updating a  slider node and updating relevant data and UI.
   */
  createSlider() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.sliderdatastream.invalid) {
        return;
      } else {
        this.sliderdatastream.value.title =
          this.sliderdatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');

        // Get the pin value entered by the user
        this.datass = this.sliderdatastream.value;
        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });
        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);
          this.widgetbox.push({
            ...this.sliderdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.sliderdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1.connections?.map((x: any) => {
        //   // this.routesdata[x.node] = { ...this.sliderdatastream.value, ...this.editor.drawflow.drawflow[name].data[x.node].data }
        // })
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.sliderdatastream.value.title);
        console.log(this.dashboardwidget);
        this.sidenav.close();
        // this.toastr.success('Sldier Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Function to create a Led node in the editor.
   * This function handles creating and updating a  Led node and updating relevant data and UI.
   */
  createLed() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.leddatastream.invalid) {
        return;
      } else {
        this.leddatastream.value.title =
          this.leddatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');
        this.leddatastream.value.color = this.color;
        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });
        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);
          this.widgetbox.push({
            ...this.leddatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.leddatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }

        // Get the pin value entered by the user
        this.datass = this.leddatastream.value;
        this.leddatastream.value.color = this.color;
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1.connections?.map((x: any) => {
        //   // this.routesdata[x.node] = { ...this.leddatastream.value, ...this.editor.drawflow.drawflow[name].data[x.node].data }
        // })
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.leddatastream.value.title);
        this.sidenav.close();
        // this.toastr.success('Sldier Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function to create a Chart node in the editor.
   * This function handles creating and updating a  Chart node and updating relevant data and UI.
   */
  createChart() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.Chartdatastream.invalid) {
        return;
      } else {
        this.Chartdatastream.value.title =
          this.Chartdatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');
        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });

        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);
          if (foundItem?.KwhDay == 'V1') {
            if (this.Chartdatastream.value.chartfilter == 'Day') {
              this.widgetbox.push({
                ...this.Chartdatastream.value,
                dashboard_Id: Number(this.id),
                Multiconnection: foundItem?.Multiconnection,
                KwhDay: foundItem.KwhDay,
                x: foundItem?.x,
                y: foundItem?.y,
                cols: foundItem?.cols,
                rows: foundItem?.rows,
              });
            } else {
              this.widgetbox.push({
                ...this.Chartdatastream.value,
                dashboard_Id: Number(this.id),
                Multiconnection: foundItem?.Multiconnection,
                x: foundItem?.x,
                y: foundItem?.y,
                cols: foundItem?.cols,
                rows: foundItem?.rows,
              });
            }
          } else {
            this.widgetbox.push({
              ...this.Chartdatastream.value,
              dashboard_Id: Number(this.id),
              Multiconnection: foundItem?.Multiconnection,
              x: foundItem?.x,
              y: foundItem?.y,
              cols: foundItem?.cols,
              rows: foundItem?.rows,
            });
          }
        } else {
          this.widgetbox.push({
            ...this.Chartdatastream.value,
            dashboard_Id: Number(this.id),
            Multiconnection: foundItem?.Multiconnection,
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }
        // Get the pin value entered by the user
        this.datass = {
          ...this.Chartdatastream.value,
          dashboard_Id: Number(this.id),
          Multiconnection: foundItem?.Multiconnection,
        };
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1.connections?.map((x: any) => {
        //   // this.routesdata[x.node] = { ...this.Chartdatastream.value, ...this.editor.drawflow.drawflow[name].data[x.node].data }
        // })
        this.editor.drawflow.drawflow[name].data[this.id].data = {
          ...this.datass,
        };

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
          String(this.Chartdatastream.value.title);
        this.sidenav.close();
        // this.toastr.success('Sldier Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }

    console.log(this.Chartdatastream.value);
  }


  /**
   * Function to create a Gauge node in the editor.
   * This function handles creating and updating a  Gauge node and updating relevant data and UI.
   */
  createspeedometer() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.speedometer.invalid) {
        return;
      } else {
        this.speedometer.value.title =
          this.speedometer.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');
        this.speedometer.value.color = this.color;

        // Get the pin value entered by the user
        this.datass = this.speedometer.value;

        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });

        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);

          this.widgetbox.push({
            ...this.speedometer.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.speedometer.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1.connections?.map((x: any) => {
        //   // this.routesdata[x.node] = { ...this.gaugedatastream.value, ...this.editor.drawflow.drawflow[name].data[x.node].data }
        // })
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.speedometer.value.title);

        this.sidenav.close();
        // this.toastr.success('Sldier Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }


  /**
   * Function to create a Gauge node in the editor.
   * This function handles creating and updating a  Gauge node and updating relevant data and UI.
   */
  createGauge() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.gaugedatastream.invalid) {
        return;
      } else {
        this.gaugedatastream.value.title =
          this.gaugedatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');
        this.gaugedatastream.value.color = this.color;

        // Get the pin value entered by the user
        this.datass = this.gaugedatastream.value;

        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });

        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);

          this.widgetbox.push({
            ...this.gaugedatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.gaugedatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1.connections?.map((x: any) => {
        //   // this.routesdata[x.node] = { ...this.gaugedatastream.value, ...this.editor.drawflow.drawflow[name].data[x.node].data }
        // })
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.gaugedatastream.value.title);

        this.sidenav.close();
        // this.toastr.success('Sldier Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  createGauge3Phase() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.gauge3Phasedatastream.invalid) {
        return;
      } else {
        this.gauge3Phasedatastream.value.title =
          this.gauge3Phasedatastream.value.title?.replace(
            /[^\S\r\n]{2,}/g,
            ' '
          );
        this.gauge3Phasedatastream.value.color = this.color;

        // Get the pin value entered by the user
        this.datass = this.gauge3Phasedatastream.value;

        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });


        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);

          this.widgetbox.push({
            ...this.gauge3Phasedatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.gauge3Phasedatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1.connections?.map((x: any) => {
        //   // this.routesdata[x.node] = { ...this.gaugedatastream.value, ...this.editor.drawflow.drawflow[name].data[x.node].data }
        // })
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.gauge3Phasedatastream.value.title);

        this.sidenav.close();
        // this.toastr.success('Sldier Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  createpiechart() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.piechartdatastream.invalid) {
        return;
      } else {
        this.piechartdatastream.value.title =
          this.piechartdatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');

        // Get the pin value entered by the user
        this.datass = this.piechartdatastream.value;

        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });


        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);

          this.widgetbox.push({
            ...this.piechartdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.piechartdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1.connections?.map((x: any) => {
        //   // this.routesdata[x.node] = { ...this.gaugedatastream.value, ...this.editor.drawflow.drawflow[name].data[x.node].data }
        // })
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.piechartdatastream.value.title);

        this.sidenav.close();
        // this.toastr.success('Sldier Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Function to create a Chart node in the editor.
   * This function handles creating and updating a  Chart node and updating relevant data and UI.
   */
  createlabel() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.labeldatastream.invalid) {
        return;
      } else {
        this.labeldatastream.value.title =
          this.labeldatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');
        this.labeldatastream.value.color = this.color;
        this.datass = this.labeldatastream.value;
        console.log(this.datass);

        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });
        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);
          this.widgetbox.push({
            ...this.labeldatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.labeldatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;
        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
          String(this.labeldatastream.value.title);
        this.sidenav.close();
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  createcost() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.costdatastream.invalid) {
        return;
      } else {
        this.costdatastream.value.title =
          this.costdatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');
        this.costdatastream.value.color = this.color;
        this.datass = this.costdatastream.value;
        this.costValue = this.costdatastream.value;
        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });
        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);
          this.widgetbox.push({
            ...this.costdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.costdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;
        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
          String(this.costdatastream.value.title);
        this.sidenav.close();
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Function to create a Terminal node in the editor.
   * This function handles creating and updating a  Terminal node and updating relevant data and UI.
   */
  createterminal() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.terminaldatastream.invalid) {
        return;
      } else {
        this.terminaldatastream.value.title =
          this.terminaldatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');
        this.terminaldatastream.value.inputhint =
          this.terminaldatastream.value.inputhint?.replace(
            /[^\S\r\n]{2,}/g,
            ' '
          );
        this.terminaldatastream.value.textcolor = this.textcolor;
        this.terminaldatastream.value.screencolor = this.screencolor;

        this.datass = this.terminaldatastream.value;
        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });
        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);
          this.widgetbox.push({
            ...this.terminaldatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.terminaldatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }
        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;
        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
          String(this.terminaldatastream.value.title);
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }
        this.sidenav.close();
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Function to create a Notification node in the editor.
   * This function handles creating and updating a  Notification node and updating relevant data and UI.
   */
  // createEmail() {
  //   if (this.iscreate) {
  //     this.dataservice.RouterlinkInActive.next();
  //     if (this.event.invalid) {
  //       return;
  //     } else {
  //       this.event.value.event_name = this.event.value.event_name?.replace(
  //         /[^\S\r\n]{2,}/g,
  //         ' '
  //       );

  //       let name: any;
  //       this.productvalue.map((x: any) => {
  //         if (x.cluster_id === this.cluster_id) {
  //           name = x.cluster_api_Id;
  //         }
  //       });

  //       this.event.value.color = this.eventcolor;
  //       let user_Id = [];
  //       user_Id = this.user_Id;
  //       user_Id = user_Id.map((x) => x.user_Id);
  //       this.event.value.user_email = user_Id.join(',');
  //       user_Id = this.User_Number;
  //       user_Id = user_Id.map((x) => x.user_Id);
  //       this.event.value.user_number = user_Id.join(',');
  //       this.event.value.notify_type = this.notify_type.join(',');
  //       this.event.value.method =
  //         this.editor.drawflow.drawflow[name].data[this.id].method;
  //       this.event.value.virtual_datatype =
  //         this.editor.drawflow.drawflow[name].data[this.id]?.ds_type;
  //       if (this.event.value.string_value)
  //       {
  //         this.event.value.virtual_type = this.event.value.string_value;
  //         this.event.value.virtual_value = String(this.event.value.St_Value);
  //       }
  //       else if (this.event.value.integer_value) {
  //         this.event.value.virtual_type = this.event.value.integer_value;
  //         this.event.value.virtual_value = String(this.event.value.Int_Value);
  //       }

  //       if(this.event.value.additional_option){
  //         this.event.value.additional_option = this.event.value.additional_option
  //       }
  //       else
  //       {
  //         if(this.editor.drawflow.drawflow[name].data[this.id]?.additional_option){
  //           this.event.value.additional_option = this.editor.drawflow.drawflow[name].data[this.id]?.additional_option
  //         }
  //       }
  //       const foundItem = this.events_data?.find((x: any) => {
  //         return Number(x.event_node_Id) == Number(this.id);
  //       });

  //       if (foundItem) {
  //         const index = this.events_data.indexOf(foundItem);
  //         this.events_data.splice(index, 1);
  //         if (foundItem.pin) {
  //           this.event.value.pin = foundItem.pin;
  //         }else if(!foundItem.pin){
  //           if(this.editor.drawflow.drawflow[name].data[this.id].pin){
  //             this.event.value.pin = this.editor.drawflow.drawflow[name].data[this.id].pin;
  //           }
  //         }
  //         if (foundItem.event_Id != 0 || !foundItem.event_Id) {
  //           this.event.value.event_Id = foundItem.event_Id;
  //        }
  //         this.events_data.push({
  //           ...this.event.value,
  //           event_node_Id: Number(this.id),
  //         });
  //       } else {
  //         this.events_data.push({
  //           ...this.event.value,
  //           event_node_Id: Number(this.id),
  //         });
  //       }

  //       this.datass = this.event.value;
  //       this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;
  //       const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
  //       node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
  //         String(this.event.value.event_name);
  //       node.classList.remove('shake-border');

  //     }
  //   } else if (!this.iscreate) {
  //     this.toastr.info('User not permitted');
  //   }
  // }

  createEmail() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.event.invalid) {
        return;
      } else {
        this.event.value.event_name = this.event.value.event_name?.replace(
          /[^\S\r\n]{2,}/g,
          ' '
        );

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });


        this.event.value.color = this.eventcolor;
        let user_Id = [];
        user_Id = this.user_Id;
        user_Id = user_Id.map((x) => x.user_Id);
        this.event.value.user_email = user_Id.join(',');
        user_Id = this.User_Number;
        user_Id = user_Id.map((x) => x.user_Id);
        this.event.value.user_number = user_Id.join(',');
        this.event.value.notify_type = this.notify_type.join(',');
        let previousnodeid = Number(
          this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1
            .connections[0].node
        );
        if (!previousnodeid) {
          let parentnodeid = Number(
            this.editor.drawflow.drawflow[name].data[previousnodeid].inputs
              .input_1.connections[0].node
          );
          this.event.value.pin =
            this.editor.drawflow.drawflow[name].data[parentnodeid].data.pin;
        } else {
          if (this.editor.drawflow.drawflow[name].data[this.id].pin) {
            this.event.value.pin =
              this.editor.drawflow.drawflow[name].data[this.id].pin;
          } else if (this.editor.getNodeFromId(previousnodeid).pin) {
            this.event.value.pin =
              this.editor.drawflow.drawflow[name].data[this.id].pin;
          }
        }

        this.event.value.method =
          this.editor.drawflow.drawflow[name].data[this.id].method;
        this.event.value.virtual_datatype =
          this.editor.drawflow.drawflow[name].data[this.id]?.ds_type;
        if (this.event.value.string_value) {
          this.event.value.virtual_type = this.event.value.string_value;
          this.event.value.virtual_value = String(this.event.value.St_Value);
        } else if (this.event.value.integer_value) {
          this.event.value.virtual_type = this.event.value.integer_value;
          this.event.value.virtual_value = String(this.event.value.Int_Value);
        }

        if (this.event.value.additional_option) {
          this.event.value.additional_option =
            this.event.value.additional_option;
        } else {
          if (
            this.editor.drawflow.drawflow[name].data[this.id]?.additional_option
          ) {
            this.event.value.additional_option =
              this.editor.drawflow.drawflow[name].data[
                this.id
              ]?.additional_option;
          }
        }
        const foundItem = this.events_data?.find((x: any) => {
          return Number(x.event_node_Id) === Number(this.id);
        });



        if (foundItem) {
          const index = this.events_data.indexOf(foundItem);
          this.events_data.splice(index, 1);
          console.log('founditem', foundItem);

          if (foundItem.pin) {
            this.event.value.pin = foundItem.pin;
          } else if (!foundItem.pin) {
            let previousnodeid = Number(
              this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1
                .connections[0].node
            );
            if (!previousnodeid) {
              let parentnodeid = Number(
                this.editor.drawflow.drawflow[name].data[previousnodeid].inputs
                  .input_1.connections[0].node
              );
              this.event.value.pin =
                this.editor.drawflow.drawflow[name].data[parentnodeid].data.pin;
            } else {
              if (this.editor.drawflow.drawflow[name].data[this.id].pin) {
                this.event.value.pin =
                  this.editor.drawflow.drawflow[name].data[this.id].pin;
              } else if (this.editor.getNodeFromId(previousnodeid).pin) {
                this.event.value.pin =
                  this.editor.drawflow.drawflow[name].data[this.id].pin;
              }
            }
          } else {
            console.log('pin not found');
          }

          if (foundItem.event_Id != 0 || !foundItem.event_Id) {
            this.event.value.event_Id = foundItem.event_Id;
          }
          // if (!foundItem.pin) {
          //   this.event.value.pin =
          //     this.editor.drawflow.drawflow[name].data[this.id].pin;
          // }
          this.events_data.push({
            ...this.event.value,
            event_node_Id: Number(this.id),
          });
        } else {
          console.log('else runsss');

          this.events_data.push({
            ...this.event.value,
            event_node_Id: Number(this.id),
          });
        }
        this.datass = this.event.value;

        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        node.childNodes[1].childNodes[1].childNodes[1].childNodes[1].textContent =
          String(this.event.value.event_name);
        node.classList.remove('shake-border');
        if (
          !this.event.value.pin ||
          this.event.value.pin == null ||
          this.event.value.pin == 'null' ||
          this.event.value.pin == 'null'
        ) {
          this.toastr.error('Notification pin not saved');
        } else {
          this.sidenav.close();
        }

      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  createThermometer() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.Thermometerdatastream.invalid) {
        return;
      } else {
        this.Thermometerdatastream.value.title =
          this.Thermometerdatastream.value.title?.replace(
            /[^\S\r\n]{2,}/g,
            ' '
          );

        // Get the pin value entered by the user
        this.datass = this.Thermometerdatastream.value;

        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });


        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);

          this.widgetbox.push({
            ...this.Thermometerdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.Thermometerdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,

            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1.connections?.map((x: any) => {
        //   // this.routesdata[x.node] = { ...this.gaugedatastream.value, ...this.editor.drawflow.drawflow[name].data[x.node].data }
        // })
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.Thermometerdatastream.value.title);

        this.sidenav.close();
        // this.toastr.success('Sldier Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  createWeather() {
    if (this.iscreate) {
      this.dataservice.RouterlinkInActive.next();
      if (this.Weatherdatastream.invalid) {
        return;
      } else {
        this.Weatherdatastream.value.title =
          this.Weatherdatastream.value.title?.replace(/[^\S\r\n]{2,}/g, ' ');

        // Get the pin value entered by the user
        this.datass = this.Weatherdatastream.value;

        const foundItem = this.widgetbox?.find((x: any) => {
          return Number(x.dashboard_Id) === Number(this.id);
        });

        if (foundItem) {
          const index = this.widgetbox.indexOf(foundItem);
          this.widgetbox.splice(index, 1);

          this.widgetbox.push({
            ...this.Weatherdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        } else {
          this.widgetbox.push({
            ...this.Weatherdatastream.value,
            dashboard_Id: Number(this.id),
            x: foundItem?.x,
            y: foundItem?.y,
            cols: foundItem?.cols,
            rows: foundItem?.rows,
          });
        }

        let name: any;
        this.productvalue.map((x: any) => {
          if (x.cluster_id === this.cluster_id) {
            name = x.cluster_api_Id;
          }
        });
        // this.editor.drawflow.drawflow[name].data[this.id].inputs.input_1.connections?.map((x: any) => {
        //   // this.routesdata[x.node] = { ...this.gaugedatastream.value, ...this.editor.drawflow.drawflow[name].data[x.node].data }
        // })
        this.editor.drawflow.drawflow[name].data[this.id].data = this.datass;

        const node = document.querySelector(`#node-${this.id}`) as HTMLElement;
        if (this.editor.getNodeFromId(this.id).pin) {
          node.classList.remove('shake-border');
        }

        node.childNodes[1].childNodes[1].childNodes[1].childNodes[2].textContent =
          String(this.Weatherdatastream.value.title);

        this.sidenav.close();
        // this.toastr.success('Sldier Created');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Updates the last mobile touch position for drag-and-drop functionality.
   *
   * @param {any} ev - The touch event object.
   */
  positionMobile(ev: any) {
    this.mobile_last_move = ev;
  }

  /**
   * Prevents the default behavior of the drag-over event.
   *
   * @param {any} ev - The event object.
   */
  allowDrop(ev: any) {
    ev.preventDefault();
  }
  /**
   * Initiates the drag action for a node element.
   *
   * @param {any} ev - The event object.
   */
  drag(ev: any) {
    // console.log('drag', ev);
    if (ev.type === 'touchstart') {
      this.mobile_item_selec = ev.target
        .closest('.drag-drawflow')
        .getAttribute('data-node');
    } else {
      ev.dataTransfer.setData('node', ev.target.getAttribute('data-node'));
    }
  }
  /**
   * Handles the dropping of a node on the DrawFlow canvas.
   *
   * @param {any} ev - The event object.
   */
  drop(ev: any) {
    console.log(ev);
    if (this.iscreate) {
      if (ev.type === 'touchend') {
        let parentdrawflow = document
          .elementFromPoint(
            this.mobile_last_move.touches[0].clientX,
            this.mobile_last_move.touches[0].clientY
          )
          ?.closest('#drawflow');
        if (parentdrawflow != null) {
          this.addNodeToDrawFlow(
            this.mobile_item_selec,
            this.mobile_last_move.touches[0].clientX,
            this.mobile_last_move.touches[0].clientY
          );
        }
        this.mobile_item_selec = '';
      } else {
        ev.preventDefault();
        let data = ev.dataTransfer.getData('node');
        if (data) {
          this.nodename = data;
          this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
        }
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }

  /**
   * Adds a new node to the DrawFlow canvas based on the provided parameters.
   *
   * @param {string} name - The type of node to be added (e.g., 'Digital', 'Analog').
   * @param {number} pos_x - The x-coordinate position for the new node.
   * @param {number} pos_y - The y-coordinate position for the new node.
   */
  addNodeToDrawFlow(name: string, pos_x: number, pos_y: number) {
    pos_x =
      pos_x *
      (this.editor.precanvas.clientWidth /
        (this.editor.precanvas.clientWidth * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().x *
      (this.editor.precanvas.clientWidth /
        (this.editor.precanvas.clientWidth * this.editor.zoom));
    pos_y =
      pos_y *
      (this.editor.precanvas.clientHeight /
        (this.editor.precanvas.clientHeight * this.editor.zoom)) -
      this.editor.precanvas.getBoundingClientRect().y *
      (this.editor.precanvas.clientHeight /
        (this.editor.precanvas.clientHeight * this.editor.zoom));
    // Handle different node types
    switch (name) {
      // FEEDS
      case 'Digital':
        let Digital = `
  <div >
  <div id="node" class="title-box"><i class="fa-solid fa-gear"></i> <p> Digital</p> </div>
  
  </div>
  `;

        this.editor.addNode(
          'Digital',
          0,
          1,
          pos_x,
          pos_y,
          'Digital',
          { name: '' },
          Digital
        );

        break;

      case 'Analog':
        let Analog = `
  <div>
    <div id="node" class="title-box"><i class="fa fa-light fa-gauge-simple"></i><p> Analog </p></div>
  
  </div>
  `;
        this.editor.addNode(
          'Analog',
          0,
          1,
          pos_x,
          pos_y,
          'Analog',
          { name: '' },
          Analog
        );
        break;
      case 'Virtual':
        let Virtual = `
  <div>
    <div id="node" class="title-box"><i class="fa-solid fa-microchip"></i><p> Virtual</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'Virtual',
          0,
          1,
          pos_x,
          pos_y,
          'Virtual',
          { name: '' },
          Virtual
        );
        break;

      // widgets

      case 'Switch':
        let Switch = `
  <div>
 <div id="node" class="title-box"> <i class="fa-sharp fa-solid fa-toggle-on"></i><p> Switch</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'Switch',
          1,
          1,
          pos_x,
          pos_y,
          'Switch',
          { name: '' },
          Switch
        );
        break;
      case 'Slider':
        let Slider = `
  <div>
 <div id="node" class="title-box"> <i class="fa-solid fa-sliders"></i><p> Slider</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'Slider',
          1,
          1,
          pos_x,
          pos_y,
          'Slider',
          { name: '' },
          Slider
        );
        break;
      case 'LED':
        let LED = `
  <div>
 <div id="node" class="title-box">  <i class="fa-solid fa-lightbulb"></i><p> LED</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'LED',
          1,
          1,
          pos_x,
          pos_y,
          'LED',
          { name: '' },
          LED
        );
        break;
      case 'Speedometer':
        let Speedometer = `
  <div>
 <div id="node" class="title-box"><iconify-icon icon="ion:speedometer"></iconify-icon> <p> Speedometer</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'Speedometer',
          1,
          1,
          pos_x,
          pos_y,
          'Speedometer',
          { name: '' },
          Speedometer
        );
        break;
      case 'Gauge':
        let Gauge = `
  <div>
 <div id="node" class="title-box"> <i class="fa-solid fa-gauge"></i><p> Gauge</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'Gauge',
          1,
          1,
          pos_x,
          pos_y,
          'Gauge',
          { name: '' },
          Gauge
        );
        break;
      case 'Gauge3Phase':
        let Gauge3Phase = `
  <div>
 <div id="node" class="title-box"> <i class="fa-solid fa-gauge"></i><p> 3 Phase</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'Gauge3Phase',
          1,
          1,
          pos_x,
          pos_y,
          'Gauge3Phase',
          { name: '' },
          Gauge3Phase
        );
        break;
      case 'piechart':
        let piechart = `
  <div>
 <div id="node" class="title-box"> <i class="fa-solid fa-gauge"></i><p>Pie Chart</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'piechart',
          1,
          1,
          pos_x,
          pos_y,
          'piechart',
          { name: '' },
          piechart
        );
        break;
      case 'Chart':
        let Chart = `
  <div>
    <div id="node" class="title-box"><i class="fa fa-area-chart"></i><p> Chart</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'Chart',
          1,
          1,
          pos_x,
          pos_y,
          'Chart',
          { name: '' },
          Chart
        );
        break;
      case 'Label':
        let Label = `
    <div>
      <div id="node" class="title-box"><i class="fa-solid fa-font"></i><p> Label</p></div>
    
    </div>
    `;
        this.editor.addNode(
          'Label',
          1,
          1,
          pos_x,
          pos_y,
          'Label',
          { name: '' },
          Label
        );
        break;
      case 'Cost':
        let Cost = `
    <div>
      <div id="node" class="title-box"><i class="fa-solid fa-indian-rupee-sign"></i><p> Cost</p></div>
    
    </div>
    `;
        this.editor.addNode(
          'Cost',
          1,
          1,
          pos_x,
          pos_y,
          'Cost',
          { name: '' },
          Cost
        );
        break;
      case 'Thermometer':
        let Thermometer = `
    <div>
   <div id="node" class="title-box"> <i class="fa-solid fa-gauge"></i><p> Thermometer</p></div>
    
    </div>
    `;
        this.editor.addNode(
          'Thermometer',
          1,
          1,
          pos_x,
          pos_y,
          'Thermometer',
          { name: '' },
          Thermometer
        );
        break;
      case 'Weather':
        let Weather = `
    <div>
   <div id="node" class="title-box"> <i class="fa-solid fa-gauge"></i><p> Weather</p></div>
    
    </div>
    `;
        this.editor.addNode(
          'Weather',
          1,
          1,
          pos_x,
          pos_y,
          'Weather',
          { name: '' },
          Weather
        );
        break;
      case 'Terminal':
        let Terminal = `
  <div>
    <div id="node" class="title-box"><i class="fas fa-code"></i><p> Terminal</p></div>
  
  </div>
  `;
        this.editor.addNode(
          'Terminal',
          1,
          1,
          pos_x,
          pos_y,
          'Terminal',
          { name: '' },
          Terminal
        );
        break;

      // EVENTS
      case 'Custom Events':
        let Custom_Events = `
        <div>
          <div id="node" class="title-box"><i class="fas fa-sms"></i><p> SMS</p></div>
        
        </div>
        `;
        this.editor.addNode(
          'Custom Events',
          1,
          1,
          pos_x,
          pos_y,
          'Custom Events',
          { name: '' },
          Custom_Events
        );
        break;
      case 'Notifications':
        let Notifications = `
        <div>
          <div class="title-box"><i class="fa-sharp fa-solid fa-envelope"></i><p> Notifications</p></div>
        
        </div>
        `;
        this.editor.addNode(
          'Notifications',
          1,
          0,
          pos_x,
          pos_y,
          'Notifications',
          { name: '' },
          Notifications
        );
        break;

      // CLOUDS
      case 'AWS':
        let AWS = `
        <div>
          <div class="title-box">	<i class="fa-brands fa-aws"></i><p> AWS</p></div>
        
        </div>
        `;
        this.editor.addNode(
          'AWS',
          1,
          1,
          pos_x,
          pos_y,
          'AWS',
          { name: '' },
          AWS
        );
        break;
      case 'Azure':
        let Azure = `
        <div>
          <div class="title-box"><i class="fa-brands fa-microsoft"></i><p> Azure</p></div>
        
        </div>
        `;
        this.editor.addNode(
          'Azure',
          1,
          1,
          pos_x,
          pos_y,
          'Azure',
          { name: '' },
          Azure
        );
        break;

      default:
    }
    // Get the newly added node element
  }

  /**
   * Displays a popup for the selected node.
   *
   * @param {MouseEvent} e - The mouse event object that triggered the function.
   */

  showpopup(e: {
    target: {
      closest: (arg0: string) => {
        (): any;
        new(): any;
        style: { (): any; new(): any; zIndex: string };
      };
      children: { style: { display: string } }[];
    };
  }) {
    e.target.closest('.drawflow-node').style.zIndex = '9999';
    e.target.children[0].style.display = 'block';

    this.transform = this.editor.precanvas.style.transform;
    this.editor.precanvas.style.transform = '';
    this.editor.precanvas.style.left = this.editor.canvas_x + 'px';
    this.editor.precanvas.style.top = this.editor.canvas_y + 'px';
    this.editor.editor_mode = 'fixed';
  }
  /**
   * Closes the popup for the selected node.
   *
   * @param {MouseEvent} e - The mouse event object that triggered the function.
   */
  closemodal(e: {
    target: {
      closest: (arg0: string) => {
        (): any;
        new(): any;
        style: { (): any; new(): any; zIndex: string };
      };
      parentElement: { parentElement: { style: { display: string } } };
    };
  }) {
    e.target.closest('.drawflow-node').style.zIndex = '2';
    e.target.parentElement.parentElement.style.display = 'none';
    //document.getElementById("modalfix").style.display = "none";
    this.editor.precanvas.style.transform = this.transform;
    this.editor.precanvas.style.left = '0px';
    this.editor.precanvas.style.top = '0px';
    this.editor.editor_mode = 'edit';
  }

  /**
   * Generates the initial structure for Drawflow data.
   * This structure defines the initial configuration for different Drawflow sections.
   *
   * @returns {object} The initial Drawflow structure.
   */

  drawflow(): any {
    let value = name;

    const drawflow = {
      drawflow: {
        Home: {
          data: {
            // '1': {
            //   id: 1,
            //   name: 'welcome',
            //   data: {},
            //   class: 'welcome',
            //   html: '\n    <div>\n      <div class="title-box">👏 Welcome!!</div>\n      <div class="box">\n        <p>Simple flow library <b>demo</b>\n        <a href="https://github.com/jerosoler/Drawflow" target="_blank">Drawflow</a> by <b>Jero Soler</b></p><br>\n\n        <p>Multiple input / outputs<br>\n           Data sync nodes<br>\n           Import / export<br>\n           Modules support<br>\n           Simple use<br>\n           Type: Fixed or Edit<br>\n           Events: view console<br>\n           Pure Javascript<br>\n        </p>\n        <br>\n        <p><b><u>Shortkeys:</u></b></p>\n   <p>🖱️ Double click to add</p>     <p>🎹 <b>Delete</b> for remove selected<br>\n        💠 Mouse Left Click == Move<br>\n        ❌ Mouse Right == Delete Option<br>\n        🔍 Ctrl + Wheel == Zoom<br>\n        📱 Mobile support<br>\n        ...</p>\n      </div>\n    </div>\n    ',
            //   typenode: false,
            //   inputs: {},
            //   outputs: {},
            //   pos_x: 50,
            //   pos_y: 50,
            // },
          },
        },
        Other: {
          data: {},
        },

        // [value]: {
        //   data: {
        //     '1': {
        //       id: 1,
        //       name: 'welcome',
        //       data: {},
        //       class: 'welcome',
        //       html: '\n    <div>\n      <div class="title-box">👏 Welcome!!</div>\n      <div class="box">\n        <p>Simple flow library     ',
        //       typenode: false,
        //       inputs: {},
        //       outputs: {},
        //       pos_x: 50,
        //       pos_y: 50,
        //     },
        //   },
        // },
      },
    };

    return drawflow;
  }

  /**
   * Fetches data stream information for a specific cluster ID.
   *
   * @param {any} cluster_Id - The ID of the cluster.
   */
  Getdatafeedsdata(cluster_Id: any) {
    this.dataservice.getdatastreamdata(cluster_Id).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.datafeedsdata = this.data

      } else {
        this.toastr.error('error occurred');
      }
    });
  }

  /**
   * Opens a modal dialog to create a new product.
   */
  newproduct() {
    const MatDialogConfig = this.matdialog.open(newproduct, {
      disableClose: true,
    });
  }
  /**
   * Handles the change of selected module in the UI.
   *
   * @param {any} event - The click event triggering the module change.
   */
  changeModule(event: any) {
    console.log(event);
    console.log(this.lastmoduleindex);

    let all = document.querySelectorAll('.menu ul li');

    if (Number(this.lastmoduleindex) >= 0) {
      if (!event) {
        all[this.lastmoduleindex].classList.add('selected');
      } else if (event) {
        for (let i = 0; i < all.length; i++) {
          all[i].classList.remove('selected');
        }
        event.target.classList.add('selected');
      }
    } else if (!this.lastmoduleindex || this.lastmoduleindex == undefined) {
      if (!event) {
        all[0].classList.add('selected');
      } else if (event) {
        for (let i = 0; i < all.length; i++) {
          all[i].classList.remove('selected');
        }
        event.target.classList.add('selected');
      }
    }
  }
  /**
   * Opens the side navigation.
   *
   * @param {any} id - The ID of the side navigation.
   */
  openSidenav(id: any) {

    this.sidenav.open();


  }
  /**
   * Handles the creation or clearing of routes based on the event type.
   *
   * @param {any} event - The type of event ("Clear" or "Create").
   */
  async Createroutes(event: any) {

    // Check if the user has permission to create routes
    if (this.iscreate) {
      // Check the type of event triggered
      if (event == 'Clear') {
        // Check if there are changes to clear or the last remove flag is set
        if (this.newdatacome.length >= 1 || this.lastRemove) {
          // Retrieve user data for authentication
          const user = this.authentication.getUserData();
          const user_Id = user.user_Id;
          // Prepare data for clearing routes
          this.dashboardwidget = this.widgetbox;
          let routes_data: any = [];
          this.device_dashboard = [];
          this.events_data = this.events_data.map((x) => {
            x.event_type = Number(x.event_type);
            x.devices = Number(x.devices);
            x.event_message_delay = Number(x.event_message_delay);
            x.method = Number(x.method);
            x.virtual_type = Number(x.virtual_type);
            Object.keys(x).forEach((key) => {
              if (
                x[key] === null ||
                x[key] === '' ||
                x[key] === 'null' ||
                x[key] === 'undefined' ||
                typeof x[key] === 'undefined'
              ) {
                delete x[key];
              }
            });
            x.virtual_datatype = Number(x.isactive);
            return x;
          });

          const data = {
            cluster_Id: this.cluster_id,
            drawflow: this.editor.drawflow,
            routesdata: routes_data,
            dashboard: this.dashboardwidget,
            device_dashboard: this.device_dashboard,
            events_data: this.events_data,
            datafeedsdata: this.datafeedsdata,
            user_Id,
          };
          // Update cluster Id and call the service to clear routes
          this.lastcluster_Id = await this.cluster_id;

          this.dataservice.createroutes(data).subscribe((res: any) => {
            if (res.status == '201') {
              this.routesrefresh = true;
              this.lastRemove = false;
              this.newdatacome = [];
              this.toastr.success('Routes Cleared');
              this.dataservice.RouterlinkActive.next();
            } else {
              this.toastr.error('Error occurred');
            }
          });
        } else {
          this.toastr.info('No Changes Detected');
        }
      } else if (event == 'Create') {
        // Check if there are changes to create or the last remove flag is set
        if (this.newdatacome.length >= 1 || this.lastRemove) {
          let notvalid: any[] = [];
          let Costnode = {};
          // Iterate over each node in the current cluster's data
          Object.values(
            this.editor.drawflow.drawflow[this.cluster_name].data
          ).forEach((x: any) => {
            // Iterate over each node in the current cluster's data
            if (
              x?.name == 'Digital' ||
              x?.name == 'Analog' ||
              x?.name == 'Virtual'
            ) {
              if (x?.outputs?.output_1.connections?.length === 0) {
                notvalid.push(x?.id);
                // Apply visual feedback to the invalid node
                notvalid.map((y) => {
                  const node = document.querySelector(
                    `#node-${y}`
                  ) as HTMLElement;

                  node.classList.add('shake-border');
                  setTimeout(() => {
                    // node.classList.add('shake-border');
                    node.classList.add('shake-animation');

                    setTimeout(() => {
                      node.classList.remove('shake-animation');
                    }, 500);
                  }, 100);
                });
              }
            }
            // Check if the node is of type "Notifications"
            if (x?.name == 'Notifications') {
              if (x?.inputs?.input_1.connections?.length === 0) {
                notvalid.push(x?.id);
                // Apply visual feedback to the invalid node
                notvalid.map((y) => {
                  const node = document.querySelector(
                    `#node-${y}`
                  ) as HTMLElement;

                  node.classList.add('shake-border');
                  setTimeout(() => {
                    node.classList.add('shake-border');
                    node.classList.add('shake-animation');

                    setTimeout(() => {
                      node.classList.remove('shake-animation');
                    }, 500);
                  }, 100);
                });
              }
            }
          });
          this.routesdata.forEach((x: any) => {
            if (!x.pin) {
              notvalid.push(x.datafeeds_Id);
              // Apply shaking border effect
              notvalid.map((y) => {
                const node = document.querySelector(
                  `#node-${y}`
                ) as HTMLElement;

                node.classList.add('shake-border');
                setTimeout(() => {
                  node.classList.add('shake-border');
                  node.classList.add('shake-animation');

                  setTimeout(() => {
                    node.classList.remove('shake-animation');
                  }, 500);
                }, 100);
              });
            }
          });
          this.widgetbox.forEach((x: any) => {
            let data = this.editor.getNodeFromId(x.dashboard_Id);
            if (
              data.class == 'Slider' ||
              data.class == 'Gauge' ||
              data.class == 'Gauge3Phase' ||
              data.class == 'Thermometer' ||
              data.class == 'Weather' ||
              data.class == 'piechart' ||
              data.class == 'Chart' ||
              data.class == 'Speedometer'
            ) {
              if (!data.data.node_name || !data.pin) {
                notvalid.push(x.dashboard_Id);
                notvalid.map((y) => {
                  const node = document.querySelector(
                    `#node-${y}`
                  ) as HTMLElement;

                  node.classList.add('shake-border');
                  setTimeout(() => {
                    node.classList.add('shake-border');
                    node.classList.add('shake-animation');

                    setTimeout(() => {
                      node.classList.remove('shake-animation');
                    }, 500);
                  }, 100);
                });
              }
            } else {
              if (data.class != 'Chart' && !data.pin) {
                notvalid.push(x.dashboard_Id);
                notvalid.map((y) => {
                  const node = document.querySelector(
                    `#node-${y}`
                  ) as HTMLElement;

                  node.classList.add('shake-border');
                  setTimeout(() => {
                    node.classList.add('shake-border');
                    node.classList.add('shake-animation');

                    setTimeout(() => {
                      node.classList.remove('shake-animation');
                    }, 500);
                  }, 100);
                });
              }
            }
          });
          this.events_data.forEach((x: any) => {
            if (x.event_node_Id != 0) {
              if (!x?.node_name) {
                notvalid.push(x?.event_node_Id);
                notvalid.map((y) => {
                  const node = document.querySelector(
                    `#node-${y}`
                  ) as HTMLElement;

                  node.classList.add('shake-border');
                  setTimeout(() => {
                    node.classList.add('shake-border');
                    node.classList.add('shake-animation');

                    setTimeout(() => {
                      node.classList.remove('shake-animation');
                    }, 500);
                  }, 100);
                });
              }
              //
            }
          });
          if (notvalid.length === 0) {
            // Get user data for authentication
            const user = this.authentication.getUserData();
            const user_Id = user.user_Id;
            this.dashboardwidget = this.widgetbox;
            let routes_data: any = [];

            routes_data = this.routesdata;
            // Update dashboard widget layout settings (gridster)
            this.dashboardwidget?.map((x: any) => {
              if (x.dashboard_Id) {
                let widgetname = x?.widgetname;
                if (!x?.cols) {
                  if (widgetname == 'Gauge') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Speedometer') {
                    x['cols'] = 4;
                    x['rows'] = 3;
                  }
                  if (widgetname == 'piechart') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Gauge3Phase') {
                    x['cols'] = 8;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Slider') {
                    x['cols'] = 4;
                    x['rows'] = 1;
                  }
                  if (widgetname == 'Switch') {
                    x['cols'] = 2;
                    x['rows'] = 1;
                  }
                  if (widgetname == 'Terminal') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'LED') {
                    x['cols'] = 2;
                    x['rows'] = 1;
                  }
                  if (widgetname == 'Label') {
                    x['cols'] = 4;
                    x['rows'] = 1;
                  }

                  if (widgetname == 'Weather') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Thermometer') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Cost') {
                    x['cols'] = 4;
                    x['rows'] = 1;
                  }
                  if (widgetname == 'Chart') {
                    if (x?.KwhDay) {
                      x['cols'] = 16;
                      x['rows'] = 2;
                    } else if (x?.chartfilter == 'Month') {
                      x['cols'] = 16;
                      x['rows'] = 2;
                    } else {
                      x['cols'] = 8;
                      x['rows'] = 2;
                    }
                  }
                  
                }
              }
              if (x.node_name == 'Cost') {
                Costnode = { ...x };
              }
            });
            let one_length_data: any = [];
            let two_lenght_data: any = [];
            let multiple_con_widget: any = [];

            for (let i = 0; i < this.dashboardwidget.length; i++) {
              let widget_id: any = this.dashboardwidget[i]?.dashboard_Id;
              let multiconnection: any =
                this.dashboardwidget[i]?.Multiconnection;
              for (let j = 0; j < routes_data.length; j++) {
                if (Array.isArray(routes_data[j]?.dashboard_Id)) {
                  for (
                    let k = 0;
                    k < routes_data[j]?.dashboard_Id.length;
                    k++
                  ) {
                    if (routes_data[j]?.dashboard_Id[k] === widget_id) {
                      if (multiconnection) {
                        if (multiple_con_widget?.length >= 1) {
                          const findwidget_Id = multiple_con_widget.find(
                            (x: any) => x.widget_Id == widget_id
                          );

                          if (findwidget_Id) {
                            multiple_con_widget.map((x: any) => {
                              if (x.widget_Id == widget_id) {
                                if (x.Multiconnection) {
                                  if (Array.isArray(x.pin)) {
                                    x.pin.push(routes_data[j]?.pin);
                                  } else {
                                    x.pin = [routes_data[j]?.pin, x.pin];
                                  }
                                }
                              }
                            });
                          } else {
                            this.dashboardwidget[i].widget_Id = widget_id;
                            multiple_con_widget.push({
                              ...this.dashboardwidget[i],
                              ...routes_data[j],
                            });
                          }
                        } else {
                          this.dashboardwidget[i].widget_Id = widget_id;
                          multiple_con_widget.push({
                            ...this.dashboardwidget[i],
                            ...routes_data[j],
                          });
                        }
                      } else {
                        this.dashboardwidget[i].widget_Id = widget_id;
                        multiple_con_widget.push({
                          ...this.dashboardwidget[i],
                          ...routes_data[j],
                        });
                      }
                    }
                  }
                } else {
                  if (routes_data[j]?.dashboard_Id === widget_id) {
                    if (multiconnection) {
                      if (multiple_con_widget?.length >= 1) {
                        const findwidget_Id = multiple_con_widget.find(
                          (x: any) => x.widget_Id == widget_id
                        );

                        if (findwidget_Id) {
                          multiple_con_widget.map((x: any) => {
                            if (x.widget_Id == widget_id) {
                              if (x.Multiconnection) {
                                if (Array.isArray(x.pin)) {
                                  x.pin.push(routes_data[j]?.pin);
                                } else {
                                  x.pin = [routes_data[j]?.pin, x.pin];
                                }
                              }
                            }
                          });
                        } else {
                          this.dashboardwidget[i].widget_Id = widget_id;
                          multiple_con_widget.push({
                            ...this.dashboardwidget[i],
                            ...routes_data[j],
                          });
                        }
                      } else {
                        this.dashboardwidget[i].widget_Id = widget_id;
                        multiple_con_widget.push({
                          ...this.dashboardwidget[i],
                          ...routes_data[j],
                        });
                      }
                    } else {
                      this.dashboardwidget[i].widget_Id = widget_id;
                      multiple_con_widget.push({
                        ...this.dashboardwidget[i],
                        ...routes_data[j],
                      });
                    }
                  }
                }
              }
            }

            let {
              zoom,
              zoom_last_value,
              pos_x,
              pos_x_start,
              pos_y_start,
              pos_y,
              canvas_x,
              canvas_y,
              curvature,
              line_path,
            }: any = this.editor;
            this.editor.drawflow = {
              ...this.editor.drawflow,
              zoom,
              zoom_last_value,
              pos_x,
              pos_x_start,
              pos_y_start,
              pos_y,
              canvas_x,
              canvas_y,
              curvature,
              line_path,
            };

            this.device_dashboard = [...multiple_con_widget];
            this.events_data = this.events_data.map((x) => {
              x.event_type = Number(x.event_type);
              x.event_message_delay = Number(x.event_message_delay);
              x.method = Number(x.method);
              x.virtual_type = Number(x.virtual_type);
              Object.keys(x).forEach((key) => {
                if (
                  x[key] === null ||
                  x[key] === '' ||
                  x[key] === 'null' ||
                  x[key] === 'undefined' ||
                  typeof x[key] === 'undefined'
                ) {
                  delete x[key];
                }
              });
              x.virtual_datatype = Number(x.isactive);
              return x;
            });

            const data = {
              cluster_Id: this.cluster_id,
              drawflow: this.editor.drawflow,
              routesdata: routes_data,
              dashboard: this.dashboardwidget,
              device_dashboard: this.device_dashboard,
              events_data: this.events_data,
              datafeedsdata: this.datafeedsdata,
              user_Id,
              costvalue: this.costValue,
            };

            this.lastcluster_Id = await this.cluster_id;

            // Make an API request to create routes

            this.dataservice.createroutes(data).subscribe((res: any) => {
              if (res.status == '201') {
                this.routesrefresh = true;
                if (this.lastRemove) {
                  this.lastRemove = false;
                }
                this.toastr.success('Routes Created');
                this.dataservice.RouterlinkActive.next();
              } else {
                this.toastr.error('Error occurred');
              }
            });
          } else {
            this.toastr.error('Some of the nodes are not updated');
          }
        } else {
          this.toastr.info('No Changes Detected');
        }
      } else {
        this.toastr.info('Cant create routes, Error occured');
      }
    } else if (!this.iscreate) {
      this.toastr.info('User not permitted');
    }
  }
  /**
   * Handles the clearing of routes
   * */

  clearroutes() {
    if (this.iscreate) {
      const MatDialogConfig = this.matdialog.open(deletepopup, {
        disableClose: true,
        data: 'Routes',
      });
      MatDialogConfig.afterClosed().subscribe((result: any) => {
        if (result.confirmation == true) {
          // Clear module selection in the editor
          this.editor.clearModuleSelected();
          // Clear route data, widget data, and reset events data
          this.routesdata = [];
          this.widgetbox = [];
          this.dashboardwidget = [];
          this.events_data.forEach((x: any) => {
            x.isactive = 1;
            x.event_node_Id = 0;
          });

          this.datafeedsdata.forEach((x: any) => {
            x.isdelete = 1;
            x.datafeeds_Id = 0;
          });
          // this.events_data = [];
          this.disabledpin = [];
          this.lastRemove = true;
          this.Createroutes('Clear');
        }
      });
    } else if (!this.isdelete) {
      this.toastr.info('User not permitted');
    }
  }
  /**
 Handles the creation of dashboard page.
 * */

  designdb(cluster_id: any) {
    let notvalid: any[] = [];
    let Costnode = {};
    // Iterate over each node in the current cluster's data
    Object.values(
      this.editor.drawflow.drawflow[this.cluster_name].data
    ).forEach((x: any) => {
      // Iterate over each node in the current cluster's data
      if (x?.name == 'Digital' || x?.name == 'Analog' || x?.name == 'Virtual') {
        if (x?.outputs?.output_1.connections?.length === 0) {
          notvalid.push(x?.id);
          // Apply visual feedback to the invalid node
          notvalid.map((y) => {
            const node = document.querySelector(`#node-${y}`) as HTMLElement;

            node.classList.add('shake-border');
            setTimeout(() => {
              // node.classList.add('shake-border');
              node.classList.add('shake-animation');

              setTimeout(() => {
                node.classList.remove('shake-animation');
              }, 500);
            }, 100);
          });
        }
      }
      // Check if the node is of type "Notifications"
      if (x?.name == 'Notifications') {
        if (x?.inputs?.input_1.connections?.length === 0) {
          notvalid.push(x?.id);
          // Apply visual feedback to the invalid node
          notvalid.map((y) => {
            const node = document.querySelector(`#node-${y}`) as HTMLElement;

            node.classList.add('shake-border');
            setTimeout(() => {
              node.classList.add('shake-border');
              node.classList.add('shake-animation');

              setTimeout(() => {
                node.classList.remove('shake-animation');
              }, 500);
            }, 100);
          });
        }
      }
    });
    this.routesdata.forEach((x: any) => {
      if (!x.pin) {
        notvalid.push(x.datafeeds_Id);
        // Apply shaking border effect
        notvalid.map((y) => {
          const node = document.querySelector(`#node-${y}`) as HTMLElement;

          node.classList.add('shake-border');
          setTimeout(() => {
            node.classList.add('shake-border');
            node.classList.add('shake-animation');

            setTimeout(() => {
              node.classList.remove('shake-animation');
            }, 500);
          }, 100);
        });
      }
    });
    this.widgetbox.forEach((x: any) => {
      let data = this.editor.getNodeFromId(x.dashboard_Id);
      if (
        data.class == 'Slider' ||
        data.class == 'Gauge' ||
        data.class == 'Gauge3Phase' ||
        data.class == 'Thermometer' ||
        data.class == 'Weather' ||
        data.class == 'piechart' ||
        data.class == 'Chart' ||
        data.class =='Speedometer'
      ) {
        if (!data.data.node_name || !data.pin) {
          notvalid.push(x.dashboard_Id);
          notvalid.map((y) => {
            const node = document.querySelector(`#node-${y}`) as HTMLElement;

            node.classList.add('shake-border');
            setTimeout(() => {
              node.classList.add('shake-border');
              node.classList.add('shake-animation');

              setTimeout(() => {
                node.classList.remove('shake-animation');
              }, 500);
            }, 100);
          });
        }
      } else {
        if (data.class != 'Chart' && !data.pin) {
          notvalid.push(x.dashboard_Id);
          notvalid.map((y) => {
            const node = document.querySelector(`#node-${y}`) as HTMLElement;

            node.classList.add('shake-border');
            setTimeout(() => {
              node.classList.add('shake-border');
              node.classList.add('shake-animation');

              setTimeout(() => {
                node.classList.remove('shake-animation');
              }, 500);
            }, 100);
          });
        }
      }
    });
    this.events_data.forEach((x: any) => {
      if (x.event_node_Id != 0) {
        if (!x?.node_name) {
          notvalid.push(x?.event_node_Id);
          notvalid.map((y) => {
            const node = document.querySelector(`#node-${y}`) as HTMLElement;

            node.classList.add('shake-border');
            setTimeout(() => {
              node.classList.add('shake-border');
              node.classList.add('shake-animation');

              setTimeout(() => {
                node.classList.remove('shake-animation');
              }, 500);
            }, 100);
          });
        }
        //
      }
    });

    if (notvalid.length === 0) {
      if (this.iscreate) {
        if (this.newdatacome.length >= 1) {
          let notvalid: any[] = [];

          this.routesdata.forEach((x: any) => {
            if (!x.pin) {
              notvalid.push(x.datafeeds_Id);
              notvalid.map((y) => {
                const node = document.querySelector(
                  `#node-${y}`
                ) as HTMLElement;

                node.classList.add('shake-border');
                setTimeout(() => {
                  node.classList.add('shake-border');
                  node.classList.add('shake-animation');
                  this.toastr.error('Some of the nodes are not updated');
                  setTimeout(() => {
                    node.classList.remove('shake-animation');
                  }, 500);
                }, 100);
              });
            }
          });
          this.widgetbox.forEach((x: any) => {
            let data = this.editor.getNodeFromId(x.dashboard_Id);
            // validation
            if (
              data.class == 'Slider' ||
              data.class == 'Gauge' ||
              data.class == 'Gauge3Phase' ||
              data.class == 'Thermometer' ||
              data.class == 'Weather' ||
              data.class == 'piechart' ||
              data.class == 'Chart' ||
              data.class == 'Speedometer'
            ) {
              if (!data.data.node_name || !data.pin) {
                notvalid.push(x.dashboard_Id);
                notvalid.map((y) => {
                  const node = document.querySelector(
                    `#node-${y}`
                  ) as HTMLElement;

                  node.classList.add('shake-border');
                  setTimeout(() => {
                    node.classList.add('shake-border');
                    node.classList.add('shake-animation');
                    this.toastr.error('Some of the nodes are not updated');
                    setTimeout(() => {
                      node.classList.remove('shake-animation');
                    }, 500);
                  }, 100);
                });
              }
            } else {
              if (!data.pin) {
                notvalid.push(x.dashboard_Id);
                notvalid.map((y) => {
                  const node = document.querySelector(
                    `#node-${y}`
                  ) as HTMLElement;

                  node.classList.add('shake-border');
                  setTimeout(() => {
                    node.classList.add('shake-border');
                    node.classList.add('shake-animation');
                    this.toastr.error('Some of the nodes are not updated');
                    setTimeout(() => {
                      node.classList.remove('shake-animation');
                    }, 500);
                  }, 100);
                });
              }
            }
          });
          this.events_data.forEach((x: any) => {
            if (!x.node_name) {
              if (x.event_node_Id != 0) {
                notvalid?.push(x.event_node_Id);
                notvalid?.map((y) => {
                  console.log(y);
                  const node = document.querySelector(
                    `#node-${y}`
                  ) as HTMLElement;
                  console.log(node);
                  node?.classList.add('shake-border');
                  setTimeout(() => {
                    node?.classList.add('shake-border');
                    node?.classList.add('shake-animation');
                    this.toastr.error('Some of the nodes are not updated');
                    setTimeout(() => {
                      node?.classList.remove('shake-animation');
                    }, 500);
                  }, 100);
                });
              }
            }
          });
          if (notvalid.length == 0) {
            this.sidenav_dbdesign.open();
            this.dashboardwidget = [];
            this.dashboardwidget = this.widgetbox;

            this.Sidenavclose();

            this.dashboardwidget?.map((x: any) => {
              if (x.dashboard_Id) {
                let widgetname = x?.widgetname;
                if (!x?.cols) {
                  if (widgetname == 'Gauge') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Speedometer') {
                    x['cols'] = 4;
                    x['rows'] = 3;
                  }
                  if (widgetname == 'piechart') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Gauge3Phase') {
                    x['cols'] = 8;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Slider') {
                    x['cols'] = 4;
                    x['rows'] = 1;
                  }
                  if (widgetname == 'Switch') {
                    x['cols'] = 2;
                    x['rows'] = 1;
                  }
                  if (widgetname == 'Terminal') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'LED') {
                    x['cols'] = 2;
                    x['rows'] = 1;
                  }
                  if (widgetname == 'Label') {
                    x['cols'] = 4;
                    x['rows'] = 1;
                  }
                  if (widgetname == 'Cost') {
                    x['cols'] = 4;
                    x['rows'] = 1;
                  }
                  if (widgetname == 'Thermometer') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Weather') {
                    x['cols'] = 4;
                    x['rows'] = 2;
                  }
                  if (widgetname == 'Chart') {
                    if (x?.KwhDay) {
                      x['cols'] = 16;
                      x['rows'] = 2;
                    } else if (x?.chartfilter == 'Month') {
                      x['cols'] = 16;
                      x['rows'] = 2;
                    } else {
                      x['cols'] = 8;
                      x['rows'] = 2;
                    }
                  }
                }
              }
            });
            this.options.draggable = !this.options.draggable;
            this.options.resizable = !this.options.resizable;
            this.gridsteredit.optionsChanged();
          } else {
          }
        } else {
          this.sidenav_dbdesign.open();
          this.dashboardwidget = [];
          console.log(this.widgetbox);
          this.dashboardwidget = this.widgetbox;
          this.Sidenavclose();

          // sets the position and size of the widgets
          this.dashboardwidget?.map((x: any) => {
            if (x.dashboard_Id) {
              let widgetname = x?.widgetname;
              if (!x?.cols) {
                if (widgetname == 'Gauge') {
                  x['cols'] = 4;
                  x['rows'] = 2;
                }
                if (widgetname == 'Speedometer') {
                  x['cols'] = 4;
                  x['rows'] = 3;
                }
                if (widgetname == 'piechart') {
                  x['cols'] = 4;
                  x['rows'] = 2;
                }
                if (widgetname == 'Gauge3Phase') {
                  x['cols'] = 8;
                  x['rows'] = 2;
                }
                if (widgetname == 'Slider') {
                  x['cols'] = 4;
                  x['rows'] = 1;
                }
                if (widgetname == 'Switch') {
                  x['cols'] = 2;
                  x['rows'] = 1;
                }
                if (widgetname == 'Terminal') {
                  x['cols'] = 4;
                  x['rows'] = 2;
                }
                if (widgetname == 'Thermometer') {
                  x['cols'] = 4;
                  x['rows'] = 2;
                }
                if (widgetname == 'Weather') {
                  x['cols'] = 4;
                  x['rows'] = 2;
                }
                if (widgetname == 'LED') {
                  x['cols'] = 2;
                  x['rows'] = 1;
                }
                if (widgetname == 'Label') {
                  x['cols'] = 4;
                  x['rows'] = 1;
                }
                if (widgetname == 'Cost') {
                  x['cols'] = 4;
                  x['rows'] = 1;
                }
                if (widgetname == 'Chart') {
                  if (x?.KwhDay) {
                    x['cols'] = 16;
                    x['rows'] = 2;
                  } else if (x?.chartfilter == 'Month') {
                    x['cols'] = 16;
                    x['rows'] = 2;
                  } else {
                    x['cols'] = 8;
                    x['rows'] = 2;
                  }
                }
              }
            }
          });
          this.options.draggable = !this.options.draggable;
          this.options.resizable = !this.options.resizable;
          this.gridsteredit.optionsChanged();
        }
      } else if (!this.iscreate) {
        this.toastr.info('User not permitted');
      }
    } else {
      this.toastr.error('Some of the nodes are not updated or connected');
    }
  }

  /**
method to close the dashboard page
 * */
  closedashboard() {
    // Close the dashboard design sidenav
    this.sidenav_dbdesign.close();

    // Toggle the draggable and resizable options
    this.options.draggable = !this.options.draggable;
    this.options.resizable = !this.options.resizable;

    // Notify the gridsteredit component about the changed options
    this.gridsteredit.optionsChanged();
  }

  /**
   * Saves the dashboard configuration if there are changes to save or if in design mode.
   * @returns void
   */
  Createdashbord() {
    let multiple_con_widget: any[] = [];
    // Check if there are changes to save or the design flag is set
    if (this.newdatacome.length >= 1 || this.design) {
      // Initialize arrays to hold route data
      let routes_data: any = [];
      let one_length_data: any = [];
      let two_lenght_data: any = [];

      routes_data = this.routesdata;
      for (let i = 0; i < this.dashboardwidget.length; i++) {
        let widget_id: any = this.dashboardwidget[i]?.dashboard_Id;
        let multiconnection: any = this.dashboardwidget[i]?.Multiconnection;
        for (let j = 0; j < routes_data.length; j++) {
          if (Array.isArray(routes_data[j]?.dashboard_Id)) {
            for (let k = 0; k < routes_data[j]?.dashboard_Id.length; k++) {
              if (routes_data[j]?.dashboard_Id[k] === widget_id) {
                if (multiconnection) {
                  if (multiple_con_widget?.length >= 1) {
                    const findwidget_Id = multiple_con_widget.find(
                      (x: any) => x.widget_Id == widget_id
                    );

                    if (findwidget_Id) {
                      multiple_con_widget.map((x: any) => {
                        if (x.widget_Id == widget_id) {
                          if (x.Multiconnection) {
                            if (Array.isArray(x.pin)) {
                              x.pin.push(routes_data[j]?.pin);
                            } else {
                              x.pin = [routes_data[j]?.pin, x.pin];
                            }
                          }
                        }
                      });
                    } else {
                      this.dashboardwidget[i].widget_Id = widget_id;
                      multiple_con_widget.push({
                        ...this.dashboardwidget[i],
                        ...routes_data[j],
                      });
                    }
                  } else {
                    this.dashboardwidget[i].widget_Id = widget_id;
                    multiple_con_widget.push({
                      ...this.dashboardwidget[i],
                      ...routes_data[j],
                    });
                  }
                } else {
                  this.dashboardwidget[i].widget_Id = widget_id;
                  multiple_con_widget.push({
                    ...this.dashboardwidget[i],
                    ...routes_data[j],
                  });
                }
              }
            }
          } else {
            // console.log("pin", routes_data[j]?.pin)
            // console.log(routes_data[j]?.dashboard_Id);
            // console.log(widget_id);
            if (routes_data[j]?.dashboard_Id === widget_id) {
              if (multiconnection) {
                if (multiple_con_widget?.length >= 1) {
                  // let condition: any = true;
                  // for (let m = 0; m < multiple_con_widget.length; m++) {
                  //   if (multiple_con_widget[m]?.widget_Id == widget_id) {
                  //     if (multiple_con_widget[m]?.Multiconnection) {
                  //       if (Array.isArray(multiple_con_widget[m].pin)) {
                  //         multiple_con_widget[m].pin.push(routes_data[j]?.pin);
                  //       } else {
                  //         multiple_con_widget[m].pin = [multiple_con_widget[m]?.pin, routes_data[j]?.pin];
                  //       }
                  //     }
                  //   }
                  // }
                  const findwidget_Id = multiple_con_widget.find(
                    (x: any) => x.widget_Id == widget_id
                  );

                  if (findwidget_Id) {
                    multiple_con_widget.map((x: any) => {
                      if (x.widget_Id == widget_id) {
                        if (x.Multiconnection) {
                          if (Array.isArray(x.pin)) {
                            x.pin.push(routes_data[j]?.pin);
                          } else {
                            x.pin = [routes_data[j]?.pin, x.pin];
                          }
                        }
                      }
                    });
                  } else {
                    this.dashboardwidget[i].widget_Id = widget_id;
                    multiple_con_widget.push({
                      ...this.dashboardwidget[i],
                      ...routes_data[j],
                    });
                  }
                } else {
                  this.dashboardwidget[i].widget_Id = widget_id;
                  multiple_con_widget.push({
                    ...this.dashboardwidget[i],
                    ...routes_data[j],
                  });
                }
              } else {
                this.dashboardwidget[i].widget_Id = widget_id;
                multiple_con_widget.push({
                  ...this.dashboardwidget[i],
                  ...routes_data[j],
                });
              }
            }
          }
        }
      }

      // Merge one-length and two-length dashboard data
      this.device_dashboard = [...multiple_con_widget];

      // Get user information
      const user = this.authentication.getUserData();
      const user_Id = user.user_Id;
      let {
        zoom,
        zoom_last_value,
        pos_x,
        pos_x_start,
        pos_y_start,
        pos_y,
        canvas_x,
        canvas_y,
        curvature,
        line_path,
      }: any = this.editor;
      this.editor.drawflow = {
        ...this.editor.drawflow,
        zoom,
        zoom_last_value,
        pos_x,
        pos_x_start,
        pos_y_start,
        pos_y,
        canvas_x,
        canvas_y,
        curvature,
        line_path,
      };
      // Prepare data for API request
      const data = {
        cluster_Id: this.cluster_id,
        drawflow: this.editor.drawflow,
        routesdata: routes_data,
        dashboard: this.dashboardwidget,
        device_dashboard: this.device_dashboard,
        events_data: this.events_data,
        datafeedsdata: this.datafeedsdata,
        costvalue: this.costValue,
        user_Id,
      };
      this.lastcluster_Id = this.cluster_id;
      console.log(data.device_dashboard);

      // Call API to save the dashboard data
      this.dataservice.createroutes(data).subscribe((res: any) => {
        if (res.status == '201') {
          this.routesrefresh = true;
          // Display success message, close the design sidenav, and reset design flag
          this.toastr.success('Dashboard Saved');
          this.dataservice.RouterlinkActive.next();
          this.sidenav_dbdesign.close();
          this.design = false; // Reset the design flag
        } else {
          this.toastr.error('Error occurred');
        }
      });
    } else {
      // Display info message if no changes to save or exit design mode
      this.toastr.info('No Changes Detected');
    }
  }

  // AutoSelectNode(id: any) {
  //   const node = document.querySelector(`#node-${id}`) as HTMLElement;

  //   this.propLoader = true;
  //   this.id = id;
  //   let name: any;
  //   this.productvalue.map((x: any) => {
  //     if (x.cluster_id === this.cluster_id) {
  //       name = x.cluster_api_Id;
  //     }
  //   });
  //   let Node_name: any = node.querySelector('p') as HTMLElement;
  //   Node_name = String(Node_name.innerText).trim();

  //   if (
  //     Node_name == 'Digital' ||
  //     Node_name == 'Analog' ||
  //     Node_name == 'Virtual' ||
  //     Node_name == 'Notifications'
  //   ) {
  //     this.nodename =
  //       this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
  //     // updating the properties based on the nodename (selected node)
  //     if (this.nodename) {
  //       this.oldpin = false;
  //       if (this.nodename == 'Digital') {
  //         this.datastream_name =
  //           this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
  //         this.digitaldatastream.controls.node_name.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.node_name
  //           )
  //         );
  //         this.datastream_name =
  //           this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
  //         this.digitaldatastream.controls.alias.setValue(
  //           String(this.editor.drawflow.drawflow[name].data[this.id].data.alias)
  //         );
  //         this.digitaldatastream.controls.datastream_Id.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .datastream_Id
  //           )
  //         );
  //         this.digitaldatastream.controls.datastream_type.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .datastream_type
  //           )
  //         );
  //         this.digitaldatastream.controls.pinmode.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.pinmode
  //           )
  //         );

  //         const pin = String(
  //           this.editor.drawflow.drawflow[name].data[this.id].data.pin
  //         );

  //         this.Digitalpin.forEach((x: any) => {
  //           if (this.disabledpin.includes(x.pin)) {
  //             x.isdisabled = true;
  //             if (x.pin == pin) {
  //               x.isdisabled = false;
  //               this.digitaldatastream.controls.pin.setValue(pin);
  //             }
  //           } else {
  //             x.isdisabled = false;
  //           }
  //         });
  //         this.Digitalpinpull.forEach((x: any) => {
  //           if (this.disabledpin.includes(x.pin)) {
  //             x.isdisabled = true;
  //             if (x.pin == pin) {
  //               x.isdisabled = false;
  //               this.digitaldatastream.controls.pin.setValue(pin);
  //             }
  //           } else {
  //             x.isdisabled = false;
  //           }
  //         });
  //         this.DigitalpinOut.forEach((x: any) => {
  //           if (this.disabledpin.includes(x.pin)) {
  //             x.isdisabled = true;
  //             if (x.pin == pin) {
  //               x.isdisabled = false;
  //               this.digitaldatastream.controls.pin.setValue(pin);
  //             }
  //           } else {
  //             x.isdisabled = false;
  //           }
  //         });

  //         this.digitaldatastream.controls.name.setValue(
  //           String(this.editor.drawflow.drawflow[name].data[this.id].data.name)
  //         );
  //       }
  //       if (this.nodename == 'Analog') {
  //         this.datastream_name =
  //           this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
  //         this.analogdatastream.controls.alias.setValue(
  //           String(this.editor.drawflow.drawflow[name].data[this.id].data.alias)
  //         );

  //         this.analogdatastream.controls.name.setValue(
  //           String(this.editor.drawflow.drawflow[name].data[this.id].data.name)
  //         );
  //         this.analogdatastream.controls.datastream_Id.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .datastream_Id
  //           )
  //         );
  //         this.analogdatastream.controls.node_name.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.node_name
  //           )
  //         );
  //         this.analogdatastream.controls.datastream_type.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .datastream_type
  //           )
  //         );
  //         this.analogdatastream.controls.pinmode.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.pinmode
  //           )
  //         );

  //         this.analogdatastream.controls.default_value.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .default_value
  //           )
  //         );
  //         const pin = String(
  //           this.editor.drawflow.drawflow[name].data[this.id].data.pin
  //         );

  //         if (this.disabledpin.includes(pin)) {
  //           this.AnalogpinIn.map((x: any) => {
  //             if (this.disabledpin.includes(x.pin)) {
  //               x.isdisabled = true;
  //               if (x.pin == pin) {
  //                 x.isdisabled = false;
  //                 this.analogdatastream.controls.pin.setValue(pin);
  //               }
  //             } else {
  //               x.isdisabled = false;
  //             }
  //           });
  //           this.AnalogpinOut.map((x: any) => {
  //             if (this.disabledpin.includes(x.pin)) {
  //               x.isdisabled = true;
  //               if (x.pin == pin) {
  //                 x.isdisabled = false;
  //                 this.analogdatastream.controls.pin.setValue(pin);
  //               }
  //             } else {
  //               x.isdisabled = false;
  //             }
  //           });
  //         }

  //         this.analogdatastream.controls.units.setValue(
  //           String(this.editor.drawflow.drawflow[name].data[this.id].data.units)
  //         );
  //       }
  //       if (this.nodename == 'Virtual') {
  //         this.datastream_name =
  //           this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
  //         this.virtualpindatastream.controls.alias.setValue(
  //           String(this.editor.drawflow.drawflow[name].data[this.id].data.alias)
  //         );
  //         this.virtualpindatastream.controls.name.setValue(
  //           String(this.editor.drawflow.drawflow[name].data[this.id].data.name)
  //         );
  //         this.virtualpindatastream.controls.datastream_Id.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .datastream_Id
  //           )
  //         );
  //         this.virtualpindatastream.controls.node_name.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.node_name
  //           )
  //         );
  //         this.virtualpindatastream.controls.datastream_type.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .datastream_type
  //           )
  //         );
  //         this.virtualpindatastream.controls.pinmode.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.pinmode
  //           )
  //         );

  //         this.virtualpindatastream.controls.default_value.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .default_value
  //           )
  //         );
  //         const pin = String(
  //           this.editor.drawflow.drawflow[name].data[this.id].data.pin
  //         );
  //         if (this.disabledpin.includes(pin)) {
  //           this.Virtualpin.map((x: any) => {
  //             if (this.disabledpin.includes(x.pin)) {
  //               x.isdisabled = true;
  //               if (x.pin == pin) {
  //                 x.isdisabled = false;
  //                 this.virtualpindatastream.controls.pin.setValue(pin);
  //               }
  //             } else {
  //               x.isdisabled = false;
  //             }
  //           });
  //         }

  //         this.event.controls.integer_value.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .integer_value
  //           )
  //         );
  //         this.event.controls.string_value.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .string_value
  //           )
  //         );
  //       }
  //       if (this.nodename == 'Notifications') {
  //         this.datastream_name =
  //           this.editor.drawflow.drawflow[name].data[this.id].data.node_name;
  //         this.inputname =
  //           this.editor.drawflow.drawflow[name].data[this.id].input_name;
  //         // changing the datastream type of notification to show their respective fields

  //         /**
  //          * Determines the datastream type of a notification node and updates a boolean flag
  //          * to indicate whether the datastream type is 'Integer'. This flag is used to control
  //          * the display of specific fields related to 'Integer' datastream type.
  //          *
  //          * @param name - The name of the editor instance.
  //          * @param id - The ID of the notification node.
  //          */
  //         if (
  //           this.editor.drawflow.drawflow[name].data[this.id].ds_type ==
  //           'Integer'
  //         ) {
  //           this.virtual_Int = true; // Set the 'virtual_Int' flag to true if the datastream type is 'Integer'.
  //         } else {
  //           this.virtual_Int = false; // Set the 'virtual_Int' flag to false if the datastream type is 'String'.
  //         }

  //         // checking input name to show their respective properties

  //         /**
  //          * Checks the input name associated with a node to determine its type (e.g., 'Digital', 'Analog', 'Virtual'),
  //          * and updates boolean flags accordingly to control the display of specific properties for each type.
  //          * For instance, if the input name is 'Digital', the 'switchprop' flag is set to true and others are set to false.
  //          *
  //          * @param inputname - The input name of the node.
  //          */
  //         if (this.inputname == 'Digital') {
  //           this.switchprop = true; // Set the 'switchprop' flag to true to indicate 'Digital' input.
  //           this.GaugeProp = false; // Set the 'GaugeProp' flag to false.
  //           this.virtualprop = false; // Set the 'virtualprop' flag to false.
  //         }
  //         if (this.inputname == 'Analog') {
  //           this.GaugeProp = true; // Set the 'GaugeProp' flag to true to indicate 'Analog' input.
  //           this.switchprop = false; // Set the 'switchprop' flag to false.
  //           this.virtualprop = false; // Set the 'virtualprop' flag to false.
  //         }
  //         if (this.inputname == 'Virtual') {
  //           this.GaugeProp = false; // Set the 'GaugeProp' flag to false.
  //           this.switchprop = false; // Set the 'switchprop' flag to false.
  //           this.virtualprop = true; // Set the 'virtualprop' flag to true to indicate 'Virtual' input.
  //         }
  //         this.event.value.notify_type =
  //           this.editor.drawflow.drawflow[name].data[this.id].data.notify_type;
  //         let splitvalue: any = this.event.value.notify_type?.split(',');
  //         this.notify_type = splitvalue;
  //         /**
  //          * Checks the notify_type associated with a node to determine their notification mode,
  //          * */
  //         this.notify_type.map((x: any) => {
  //           if (x === '1') {
  //             this.smstoogle = true;
  //           }
  //           if (x === '0') {
  //             this.emailToggle = true;
  //           }
  //         });
  //         /**
  //          * Checks the notify_type to set their respective values,
  //          * */
  //         if (this.emailToggle) {
  //           this.event.controls.user_email.setValue(
  //             String(
  //               this.editor.drawflow.drawflow[name].data[this.id].data
  //                 .user_email
  //             )
  //           );
  //           this.user_Id =
  //             this.editor.drawflow.drawflow[name].data[
  //               this.id
  //             ].data.user_email.split(',');

  //           this.user_Id = this.user_Id.map((x: any) => {
  //             return {
  //               user_Id: x,
  //             };
  //           });

  //           this.user_Id = this.user_Id.map((x: any) => {
  //             const matchuseremail = this.useremail.find(
  //               (z: any) => z.user_Id === Number(x.user_Id)
  //             );
  //             if (matchuseremail) {
  //               return {
  //                 ...matchuseremail,
  //               };
  //             }
  //           });
  //         }
  //         if (this.smstoogle) {
  //           this.event.controls.user_number.setValue(
  //             String(
  //               this.editor.drawflow.drawflow[name].data[this.id].data
  //                 .user_number
  //             )
  //           );
  //           this.User_Number =
  //             this.editor.drawflow.drawflow[name].data[
  //               this.id
  //             ].data.user_number.split(',');

  //           this.User_Number = this.User_Number.map((x: any) => {
  //             return {
  //               user_Id: x,
  //             };
  //           });

  //           this.User_Number = this.User_Number.map((x: any) => {
  //             const matchusernumber = this.usernumber.find(
  //               (z: any) => z.user_Id === Number(x.user_Id)
  //             );
  //             if (matchusernumber) {
  //               return {
  //                 ...matchusernumber,
  //               };
  //             }
  //           });
  //         }

  //         let connection =
  //           this.editor.drawflow.drawflow[name].data[this.id].method;

  //         if (connection === '0') {
  //           this.switchprop = true;
  //           this.GaugeProp = false;
  //           this.virtualprop = false;
  //         } else if (connection === '1') {
  //           this.GaugeProp = true;
  //           this.switchprop = false;
  //           this.virtualprop = false;
  //         } else if (connection === '2') {
  //           this.virtualprop = true;
  //           this.switchprop = false;
  //           this.GaugeProp = false;
  //         } else {
  //           this.switchprop = false;
  //           this.GaugeProp = false;
  //           this.virtualprop = false;
  //         }

  //         this.event.controls.event_name.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.event_name
  //           )
  //         );
  //         this.event.controls.event_type.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.event_type
  //           )
  //         );
  //         this.analog_type =
  //           this.editor.drawflow.drawflow[name].data[this.id].data.analog_type;
  //         this.event.controls.analog_type.setValue(String(this.analog_type));
  //         if (this.analog_type == '0') {
  //           this.event.controls.analog_min.setValue(
  //             String(
  //               this.editor.drawflow.drawflow[name].data[this.id].data
  //                 .analog_min
  //             )
  //           );
  //           this.event.controls.analog_max.setValue(
  //             String(
  //               this.editor.drawflow.drawflow[name].data[this.id].data
  //                 .analog_max
  //             )
  //           );
  //         } else if (this.analog_type == '1') {
  //           this.event.controls.analog_leftmax.setValue(
  //             String(
  //               this.editor.drawflow.drawflow[name].data[this.id].data
  //                 .analog_leftmax
  //             )
  //           );
  //           this.event.controls.analog_leftmin.setValue(
  //             String(
  //               this.editor.drawflow.drawflow[name].data[this.id].data
  //                 .analog_leftmin
  //             )
  //           );
  //           this.event.controls.analog_rightmin.setValue(
  //             String(
  //               this.editor.drawflow.drawflow[name].data[this.id].data
  //                 .analog_rightmin
  //             )
  //           );
  //           this.event.controls.analog_rightmax.setValue(
  //             String(
  //               this.editor.drawflow.drawflow[name].data[this.id].data
  //                 .analog_rightmax
  //             )
  //           );
  //         }

  //         this.event.controls.color.setValue(
  //           String(this.editor.drawflow.drawflow[name].data[this.id].data.color)
  //         );

  //         this.event.controls.event_message_count.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .event_message_count
  //           )
  //         );
  //         this.event.controls.event_message_delay.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .event_message_delay
  //           )
  //         );
  //         this.event.controls.digital_value.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .digital_value
  //           )
  //         );

  //         this.event.controls.integer_value.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .integer_value
  //           )
  //         );
  //         this.event.controls.string_value.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data
  //               .string_value
  //           )
  //         );
  //         this.event.controls.St_Value.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.St_Value
  //           )
  //         );
  //         this.event.controls.Int_Value.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.Int_Value
  //           )
  //         );
  //         this.event.controls.virtual_min.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.virtual_min
  //           )
  //         );
  //         this.event.controls.virtual_max.setValue(
  //           String(
  //             this.editor.drawflow.drawflow[name].data[this.id].data.virtual_max
  //           )
  //         );
  //       }
  //       this.openSidenav(this.id);
  //       node.classList.remove('shake-border');
  //       node.classList.remove('shake-animation');
  //       node.classList.add('select-node');
  //       this.propLoader = false;
  //       console.log('node clicked');
  //     } else {
  //       // Set flags and properties for handling a node click event
  //       this.oldpin = true;
  //       this.datastream_name = '';
  //       this.openSidenav(this.id);
  //       const name = node.querySelector('p') as HTMLElement;
  //       setTimeout(() => {
  //         node.classList.remove('shake-border');
  //         node.classList.remove('shake-animation');
  //       }, 700);

  //       node.classList.add('select-node');
  //       this.nodename = String(name.innerText).trim();
  //       this.color = '#3633b7';
  //       if (this.nodename == 'Notifications') {
  //         // ... Code to handle properties and controls specific to 'Notifications' nodes
  //         this.user_Id = [];
  //         let clustername: any;
  //         this.productvalue.map((x: any) => {
  //           if (x.cluster_id === this.cluster_id) {
  //             clustername = x.cluster_api_Id;
  //           }
  //         });
  //         this.inputname =
  //           this.editor.drawflow.drawflow[clustername].data[this.id].input_name;
  //         const connection =
  //           this.editor.drawflow.drawflow[clustername].data[this.id].method;
  //         if (connection === '0') {
  //           console.log(connection + 'work');
  //           this.switchprop = true;
  //           this.virtualprop = false;
  //           this.GaugeProp = false;
  //         } else if (connection === '1') {
  //           console.log(connection + 'work');
  //           this.GaugeProp = true;
  //           this.virtualprop = false;
  //           this.switchprop = false;
  //         } else if (connection === '2') {
  //           this.virtualprop = true;
  //           this.switchprop = false;
  //           this.GaugeProp = false;
  //         } else {
  //           console.log(connection + 'work');
  //           this.virtualprop = false;
  //           this.switchprop = false;
  //           this.GaugeProp = false;
  //         }
  //         if (
  //           this.editor.drawflow.drawflow[clustername].data[this.id].ds_type ==
  //           'Integer'
  //         ) {
  //           this.virtual_Int = true;
  //         } else {
  //           this.virtual_Int = false;
  //         }
  //       }
  //       // this.gethardwaredatastreampin(this.hardware_name)
  //       this.event.controls.event_name.setValue(this.nodename);
  //       // this.event.controls.event_name.reset();
  //       this.smstoogle = false;
  //       this.emailToggle = false;
  //       this.event.controls.event_message_count.reset();
  //       this.event.controls.event_message_delay.reset();
  //       this.event.controls.analog_leftmax.reset();
  //       this.event.controls.analog_leftmin.reset();
  //       this.event.controls.digital_value.reset();
  //       this.event.controls.analog_max.reset();
  //       this.event.controls.analog_max.reset();
  //       this.event.controls.analog_rightmax.reset();
  //       this.event.controls.analog_rightmin.reset();
  //       this.event.controls.analog_type.reset();
  //       this.event.controls.event_type.setValue('0');
  //       if (this.hardware_name == 'Esp32') {
  //         this.digitaldatastream.controls.name.setValue('Digital');
  //         // this.digitaldatastream.controls.pin.setValue('2');
  //         this.digitaldatastream.controls.pinmode.setValue('Output');
  //         this.analogdatastream.controls.name.setValue('Analog');
  //         // this.analogdatastream.controls.pin.setValue('2');
  //         this.analogdatastream.controls.pinmode.setValue('Output');
  //         this.analogdatastream.controls.units.setValue('None');

  //         this.virtualpindatastream.controls.name.setValue('Virtual');
  //         this.virtualpindatastream.controls.pin.setValue('V0');
  //         this.virtualpindatastream.controls.datastream_type.setValue(
  //           'Integer'
  //         );
  //         this.event.controls.integer_value.setValue('Greater than');
  //         this.event.controls.event_type.setValue('0');
  //         // this.virtualpindatastream.controls.units.setValue('None');

  //         // this.virtualpindatastream.controls.decimal_value.setValue('#.00000');
  //       } else if (this.hardware_name == 'Esp8266') {
  //         // this.digitaldatastream.controls.pin.setValue('D1');
  //         this.digitaldatastream.controls.pinmode.setValue('Output');
  //         // this.analogdatastream.controls.pin.setValue('D1');
  //         this.analogdatastream.controls.pinmode.setValue('Output');
  //         this.analogdatastream.controls.units.setValue('None');
  //         // this.virtualpindatastream.controls.pin.setValue('V0');
  //         this.analogdatastream.controls.name.setValue('Analog');
  //         this.digitaldatastream.controls.name.setValue('Digital');
  //         this.virtualpindatastream.controls.name.setValue('Virtual');
  //         // this.virtualpindatastream.controls.units.setValue('None');
  //         this.virtualpindatastream.controls.datastream_type.setValue(
  //           'Integer'
  //         );
  //         this.event.controls.integer_value.setValue('Greater than');
  //         this.event.controls.event_type.setValue('0');
  //         // this.virtualpindatastream.controls.decimal_value.setValue('#.00000');
  //       }
  //       // Enable or disable based on whether the pin is in the 'disabledpin' array

  //       this.Digitalpin.forEach((x: any) => {
  //         if (this.disabledpin.includes(x.pin)) {
  //           x.isdisabled = true;
  //         } else {
  //           x.isdisabled = false;
  //         }
  //       });
  //       this.Digitalpinpull.forEach((x: any) => {
  //         if (this.disabledpin.includes(x.pin)) {
  //           x.isdisabled = true;
  //         } else {
  //           x.isdisabled = false;
  //         }
  //       });
  //       this.DigitalpinOut.forEach((x: any) => {
  //         if (this.disabledpin.includes(x.pin)) {
  //           x.isdisabled = true;
  //         } else {
  //           x.isdisabled = false;
  //         }
  //       });

  //       this.AnalogpinIn.map((x: any) => {
  //         if (this.disabledpin.includes(x.pin)) {
  //           x.isdisabled = true;
  //         } else {
  //           x.isdisabled = false;
  //         }
  //       });
  //       this.AnalogpinOut.map((x: any) => {
  //         if (this.disabledpin.includes(x.pin)) {
  //           x.isdisabled = true;
  //         } else {
  //           x.isdisabled = false;
  //         }
  //       });
  //       this.Virtualpin.map((x: any) => {
  //         if (this.disabledpin.includes(x.pin)) {
  //           x.isdisabled = true;
  //         } else {
  //           x.isdisabled = false;
  //         }
  //       });
  //       if (this.disabledpin.includes('2') || this.disabledpin.includes('D1')) {
  //         let D_Out_isdisabled = true;
  //         this.DigitalpinOut.map((x: any) => {
  //           if (!x.isdisabled && D_Out_isdisabled) {
  //             D_Out_isdisabled = false;
  //             this.digitaldatastream.controls.pin.setValue(x.pin);
  //           }
  //         });
  //       }

  //       if (this.disabledpin.includes('2') || this.disabledpin.includes('D1')) {
  //         let A_out_isdisabled = true;
  //         this.AnalogpinOut.map((x: any) => {
  //           if (!x.isdisabled && A_out_isdisabled) {
  //             A_out_isdisabled = false;
  //             this.analogdatastream.controls.pin.setValue(x.pin);
  //           }
  //         });
  //       }

  //       if (this.disabledpin.includes('V0')) {
  //         let V_isdisabled = true;
  //         this.Virtualpin.map((x: any) => {
  //           if (!x.isdisabled && V_isdisabled) {
  //             V_isdisabled = false;
  //             this.virtualpindatastream.controls.pin.setValue(x.pin);
  //           }
  //         });
  //       }
  //       console.log('node clicked');
  //       this.propLoader = false;
  //     }
  //   } else if (this.widget_list.includes(Node_name)) {
  //     // this.toastr.error("Some")
  //   }
  // }

  xAxisTickFormattingFunction = (value: any) => {
    let formatvalue;
    formatvalue = new Date(value).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    return formatvalue;
  };

  Import() {
    if (this.exportRoutes) {
      this.lastRemove = true;

      this.costValue = this.exportRoutes.costvalue;
      this.datafeedsdata = this.exportRoutes.datafeedsdata;
      this.routesdata = this.exportRoutes.routesdata;
      this.widgetbox = this.exportRoutes.dashboard;

      let {
        zoom,
        zoom_last_value,
        pos_x,
        pos_x_start,
        pos_y_start,
        pos_y,
        canvas_x,
        canvas_y,
        curvature,
        line_path,
      }: any = this.exportRoutes.drawflow;
      const renameclusterapi = Object.keys(
        this.exportRoutes.drawflow.drawflow
      )[0];
      this.editor.drawflow = '';
      const drawflow = {
        drawflow: {
          [this.cluster_name]: {
            ...this.exportRoutes.drawflow.drawflow[renameclusterapi],
          },
        },
      };
      this.editor.import(drawflow);

      this.editor.canvas_x = canvas_x;
      this.editor.canvas_y = canvas_y;
      this.editor.curvature = curvature;
      this.editor.line_path = line_path;
      this.editor.pos_x = pos_x;
      this.editor.pos_x_start = pos_x_start;
      this.editor.pos_y = pos_y;
      this.editor.pos_y_start = pos_y_start;
      this.editor.zoom = zoom;
      this.editor.zoom_last_value = zoom_last_value;
      this.editor.zoom_in();
      this.editor.zoom_out();

      let data: any = [];
      // Extract data from drawflow and process each node.
      this.editor.drawflow.drawflow[this.cluster_name].data;
      Object.values(this.editor.drawflow.drawflow[this.cluster_name].data).map(
        (x: any) => {
          data.push(x);
          this.editor.updateConnectionNodes('node-' + x.id);
        }
      );

      data.map((x: any) => {
        if (x.data.pin) {
          this.disabledpin.push(x.data.pin);
        }
        // Update node labels.
        const node = document.querySelector(`#node-${x.id}`) as HTMLElement;
        const ptag = node.querySelector('p') as HTMLElement;
        if (x.data.name) {
          ptag.innerText = x.data.name;
        } else if (x.data.title) {
          ptag.innerText = x.data.title;
        } else if (x.data.event_name) {
          ptag.innerText = x.data.event_name;
        } else {
          ptag;
        }

        // Append pin information based on node type and method.
        if (x.data.node_name == 'Digital') {
          const heading = document.createElement('h6') as HTMLElement;
          heading.innerText = x.data.pin;
          node.childNodes[1].childNodes[1].appendChild(heading);
        }
        if (x.data.node_name == 'Analog') {
          const heading = document.createElement('h5') as HTMLElement;
          heading.innerText = x.data.pin;
          node.childNodes[1].childNodes[1].appendChild(heading);
        }
        if (x.data.node_name == 'Virtual') {
          const heading = document.createElement('h4') as HTMLElement;
          heading.innerText = x.data.pin;
          node.childNodes[1].childNodes[1].appendChild(heading);
        }
        if (x.data.node_name == 'Cost') {
          this.CostNode = true;
        }

        if (x.method == '0') {
          if (x.pin) {
            const heading = document.createElement('h6') as HTMLElement;
            heading.innerText = x.pin;
            node.childNodes[1].childNodes[1].appendChild(heading);
          }
        }

        if (x.method == '1') {
          if (x.pin) {
            const heading = document.createElement('h5') as HTMLElement;
            heading.innerText = x.pin;
            node.childNodes[1].childNodes[1].appendChild(heading);
          }
        }

        if (x.method == '2') {
          if (x.pin) {
            const heading = document.createElement('h4') as HTMLElement;
            heading.innerText = x.pin;
            node.childNodes[1].childNodes[1].appendChild(heading);
          }
        }

        if (x.input_name == 'Digital') {
          if (x.data.Multiconnection) {
            const heading = document.createElement('h3') as HTMLElement;
            heading.innerText = '*';
            node.childNodes[1].childNodes[1].appendChild(heading);
          } else {
            if (x.pin) {
              const heading = document.createElement('h6') as HTMLElement;
              heading.innerText = x.pin;
              node.childNodes[1].childNodes[1].appendChild(heading);
            }
          }
        }
        if (x.input_name == 'Analog') {
          if (x.data.Multiconnection) {
            const heading = document.createElement('h3') as HTMLElement;
            heading.innerText = '*';
            node.childNodes[1].childNodes[1].appendChild(heading);
          } else {
            if (x.pin) {
              const heading = document.createElement('h5') as HTMLElement;
              heading.innerText = x.pin;
              node.childNodes[1].childNodes[1].appendChild(heading);
            }
          }
        }
        if (x.input_name == 'Virtual') {
          if (x.data.Multiconnection) {
            const heading = document.createElement('h3') as HTMLElement;
            heading.innerText = '*';
            node.childNodes[1].childNodes[1].appendChild(heading);
          } else {
            if (x.pin) {
              const heading = document.createElement('h4') as HTMLElement;
              heading.innerText = x.pin;
              node.childNodes[1].childNodes[1].appendChild(heading);
            }
          }
        }
      });
      Object.values(this.editor.drawflow.drawflow[this.cluster_name].data).map(
        (node: any) => {
          if (node.class == 'Notifications') {
            console.log(node.id);
            this.editor.removeNodeId('node-' + node.id);
          }
        }
      );
      this.toastr.success('Import Successfully');
      console.log(this.editor.drawflow);
    } else {
      this.toastr.info('Export Data Not Found');
    }
  }

  Export() {
    const renameclusterapi = Object.keys(this.editor.drawflow.drawflow)[0];
    //  this.editor.drawflow.drawflow[renameclusterapi].data= Object.values(this.editor.drawflow.drawflow[renameclusterapi].data).filter((x:any)=> x.class !="Notifications")
    // this.editor.drawflow.drawflow[renameclusterapi].data=Object.values(this.editor.drawflow.drawflow[renameclusterapi].data).map((x:any)=>{
    //   if(x.class=="Notifications"){
    //   x.data={}
    //   }
    //   return x;
    // })

    // this.events_data=this.events_data.map((x:any)=>{
    //    x.node_name=null
    // })
   
    this.exportRoutes = {
      drawflow: this.editor.drawflow,
      routesdata: this.routesdata,
      dashboard: this.widgetbox,
      device_dashboard: this.device_dashboard,
      events_data: this.events_data,
      datafeedsdata: this.datafeedsdata,
      costvalue: this.costValue,
    };
    this.toastr.success('Export Successfully');
  }
}
