<div class="content" *ngIf="isread == false">
  <div>
    <h1 style="font-weight: 500">Access to this page has been restricted</h1>
    <p style="font-weight: 500; text-transform: capitalize; margin: 0 !important">
      Sorry, you do not have the necessary privileges to access this feature.
      Please contact the Oraganization's administrator if you require additional
      permissions.
    </p>
  </div>
</div>
<mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="loading" id="spinner">
</mat-spinner>
<div class="content" *ngIf="productvalue?.length == 0 && isread == true &&!loading">
  <div>
    <h1 style="font-weight: 500">Start by creating your first Cluster</h1>
    <p style="font-weight: 500">
      Cluster is a digital model of a physical object. It is used in Devsbot
      platform as a Cluster to be assigned to devices.
    </p>
  </div>
  <!-- <div>
    <button class="btn btn-primary" (click)="open()">+ New Cluster</button>
  </div> -->
</div>






<div style="height: 100%;" *ngIf="!loading">
  <div class="main-head" *ngIf="this.ClusterAnalytics">
    <div class="heading-div">
      <h1 class="heading"> {{ this.clustername + " Analytics" }}</h1>
      <!-- <i (click)="ExportAnalytics()" matTooltip="Export -->
      <!-- Analytics(CSV)" id="more-vert1" class="fa-solid fa-file-export"></i> -->
    </div>
    <div class="heading-div-filter" [formGroup]="range">
      <mat-form-field *ngIf="this.vertical_type==2" appearance="fill">
        <mat-label>Select a Interval</mat-label>
        <mat-select formControlName="Interval">
          <mat-option value="{{ item.value }}" *ngFor="let item of interval">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Select a Cluster</mat-label>
          <mat-select panelClass="myPanelClass" formControlName="cluster_Id">
            <mat-option value="{{ cluster.group_id }}" *ngFor="let cluster of productvalue">{{ cluster.group_name }}
              <span>({{cluster.vertical_name}})</span></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <mat-form-field appearance="fill">
        <mat-label>Select a Cluster</mat-label>
        <mat-select panelClass="myPanelClass"  formControlName="cluster_Id">
          <mat-option
            value="{{ cluster.group_id }}"
            *ngFor="let cluster of productvalue"
            >{{ cluster.group_name }} <span>({{cluster.vertical_name}})</span></mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field appearance="fill">
        <mat-label>Enter a date range <span style="color: red;">*</span> </mat-label>
        <mat-date-range-input [rangePicker]="picker" [max]="this.enddate">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>

    </div>
  </div>

  <mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="Finding" id="spinner">
  </mat-spinner>

  <div class="button-insight">
    <button class="btn btn-primary insight_button" mat-icon-button
      aria-label="Example icon button with a vertical three dot icon" (click)="open()">

      <iconify-icon icon="fa-solid:chart-line"></iconify-icon> Insights

    </button>
    <!-- <button class="button" (click)="open()">
       </button> -->

  </div>


  <mat-sidenav [opened]="Insights" style="width: 100%" position="end" style="width: 100%">


    <div class="header-tag">
      <div><mat-icon class="close-icon" (click)="back()">arrow_back</mat-icon>
      </div>
      <div>
        <h2>Insights Status</h2>
      </div>
    </div>
    <!-- <div>
      <div>
        <p>This chart shows the current status for the total number of Pins</p>
      </div>
    </div> -->
    <div class="insight-main">
      <div *ngIf="this.bardata.labels.length==0 && configdata" class="content">
        <div>
          <iconify-icon _ngcontent-trb-c318 icon="lucide:chart-no-axes-combined" style="font-size: 40px;"></iconify-icon>
          <h1 style="font-weight: 500"> No Data Found</h1>
          <button class="btn btn-primary reconf_bottom" (click)="config()"> <iconify-icon
            icon="tdesign:edit"></iconify-icon>Re-Configure</button>
          
        </div>
      </div>
 

      <div *ngIf="this.bardata.labels.length==0 && !configdata" class="content">
        <div>
          <h1 style="font-weight: 500">Start by creating your Insights</h1>
          <p style="font-weight: 500">
            Insight Graph gives you the freedom to create graphs with blended metrics.
          </p>
        </div>
        <div>

          <button class="btn btn-primary conf_bottom" (click)="config()"> <iconify-icon
              icon="tdesign:edit"></iconify-icon> Configure</button>
        </div>
      </div>

      <div *ngIf="this.bardata.labels.length>0" class="insight-head">
        <!-- <div>
          Heading
        </div> -->
        <div>
          <!-- <button class="conf_bottom" (click)="config()"> Configure </button> -->
          <button class="btn btn-primary conf_bottom" (click)="config()"> <iconify-icon
              icon="tdesign:edit"></iconify-icon> Configure</button>
        </div>
      </div>


      <!-- <mat-spinner role="progressbar" diameter="50" aria-hidden="true" *ngIf="insightsloader" id="spinner">
      </mat-spinner> -->

      <div *ngIf="this.bardata.labels.length>0 " class="card">
        <p-chart #insightschart type="{{this.chartconfig.controls.charttype.value}}" [data]="bardata"
          [options]="options"></p-chart>
      </div>


    </div>



    <mat-sidenav [opened]="Config" mode="over" style="width: 25%" position="end">

      <div class="config-h1">
        <h2 class="config-line">Configure Chart</h2>
        <mat-icon _ngcontent-che-c319="" role="img"
          class="mat-icon notranslate close-nav materinsial-icons mat-icon-no-color" style="margin-top: 10px;"
          aria-hidden="true" data-mat-icon-type="font" (click)="backconfig()">close</mat-icon>
      </div>



      <div class="drop-down" [formGroup]="chartconfig">
        <mat-form-field appearance="fill">
          <mat-label>Clusters</mat-label>
          <mat-select panelClass="myPanelClass" formControlName="group_Id">
            <mat-option value="{{ cluster.group_id }}" *ngFor="let cluster of productvalue">{{ cluster.group_name }}
              <span>({{cluster.vertical_name}})</span></mat-option>
          </mat-select>
        </mat-form-field>



        <mat-form-field appearance="fill">
          <mat-label>Devices</mat-label>
          <mat-select panelClass="myPanelClass" formControlName="device_Id">
            <mat-option value="{{ device.device_id}}" *ngFor="let device of clusterdevice">{{ device.device_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Pins</mat-label>
          <mat-select multiple panelClass="myPanelClass" formControlName="Pins">
            <mat-option value="{{ pins.heading}}" *ngFor="let pins of virtualpin">{{ (pins.heading )+" "+ this.showParams(pins.heading,this.chartconfig.controls.device_Id.value) || ''  }}
       
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field *ngIf="!multiplepin" appearance="fill">
          <mat-label>Pins</mat-label>
          <mat-select panelClass="myPanelClass" formControlName="Pins">
            <mat-option value="{{ pins.heading}}" *ngFor="let pins of virtualpin">{{ pins.heading }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field appearance="fill">
          <mat-label>Chart Type</mat-label>
          <mat-select panelClass="myPanelClass" formControlName="charttype">
            <mat-option value="bar">Bar Chart</mat-option>
            <mat-option value="line">Line Chart</mat-option>

            <!-- <mat-option value="radar">radar</mat-option> -->
          </mat-select>
        </mat-form-field>



      </div>


      <div class="sub-button">
        <!-- <button class="conf_bottom" (click)="config()"> Configure </button> -->
        <button class="btn btn-primary save_bottom" [disabled]="this.chartconfig.invalid" (click)="saveconfig()">Config</button>
      </div>

    </mat-sidenav>

  </mat-sidenav>




  <div class="card-table" *ngIf="!this.nodata">
    <div class="main-body">
      <table>
        <thead style="top: -1px;">
          <tr>
            <th *ngFor="let header of this.Tabledata[0]?.headers">{{ header.heading }}</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of this.Tabledata[0]?.rows">
            <td *ngFor="let header of this.Tabledata[0]?.headers;let i=index;">
              <!-- {{ row[(header.key)] (i>=2 ? this.showParams(header.key,row['device_id']):'') || ''  }} -->
              {{ (row[header.key] +" " || '') + (i >= 2 ? showParams(header.key, row['device_id']) : '') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>




  </div>
  <div class="content" *ngIf="nodata">
    <iconify-icon _ngcontent-trb-c318 icon="lucide:chart-no-axes-combined" style="font-size: 40px;"></iconify-icon>
    <h1>No Data Found</h1>
  </div>

</div>