import { Component, OnInit } from '@angular/core';
import { Dataservice } from '../services/dataservice';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {
   
  fullscreen:any=true

  constructor(private dataservice:Dataservice){

  }

  ngOnInit(){
    this.dataservice.RemovefullScreen.next()
  }


  ngOnDestroy() {
    this.dataservice.fullScreen.next()
  }

}
