import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Dataservice } from '../services/dataservice';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AuthenticationService } from '../Authentication/authentication.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {
  LegendPosition,
  ScaleType,
  colorSets,
  escapeLabel,
  formatLabel,
} from '@swimlane/ngx-charts';
import { HomeComponent } from '../home/home.component';
import { newproduct } from '../product/product.component';
import { Overlay } from '@angular/cdk/overlay';
import { CustomSelectOverlay } from './custom-select-overlay';
import { ActivatedRoute, Router } from '@angular/router';
import { UIChart } from 'primeng/chart';
import { filter } from 'rxjs';
// import { createcluster } from '../cluster/cluster.component';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
  viewProviders: [
    {
      provide: Overlay,
      useClass: CustomSelectOverlay,
    },
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AnalyticsComponent implements OnInit {
  @ViewChild('insightschart') insightschart!: UIChart;
  headers: string[] = [];
  tableGrid: any[] = [];

  data1 = [{}];

  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  reports: any[] = [];
  data: any[] = [];
  nodata: boolean = false;
  options: any = {};
  bardata: {
    labels: any[];
    datasets: {
      label: any;
      extra:any;
      backgroundColor: any;
      borderColor: any;
      data: any[];
    }[];
  };
  productvalue: any;
  clustername: any;
  loading!: boolean;
  colorSets: any = colorSets;
  Xaxisticks: any[] = [];
  Xaxismin: any;
  Xaxismax: any;
  ClusterAnalytics!: boolean;
  devicedata: any;
  Insights: boolean = false;
  Config: boolean = false;
  Submit: boolean = false;

  cluster_id!: Number;
  Finding!: boolean;
  Clusterpiechartdata: any[] = [];
  LineChartData: any[] = [];
  colorScheme: any;
  schemeType = ScaleType.Ordinal;
  selectedColorScheme!: string;
  legendPosition = LegendPosition.Right;
  TotalKwh: any = 0;

  TotalCo2: any = 0;
  TotalCost: any = 0;
  AvgKwh: any = 0;
  chartfilter: any = 'hour';
  Tabledata: any[] = [];
  novalue: any;
  noco2: boolean = false;
  vertical_Id: any;
  vertical_type: any;
  insightsloader: boolean = false;
  addressconfig:any[]=[]
  interval: any[] = [
    { name: '1 Minute', value: 1 },
    { name: '5 Minutes', value: 5 },
    { name: '30 Minutes', value: 30 },
    { name: 'Hour', value: 60 },
  ];
  Startdate = new Date().setDate(1);
  enddate = new Date();
  range = this.formbuilder.group({
    Interval: ['', Validators.required],
    cluster_Id: ['', Validators.required],
    start: [new Date(this.Startdate), [Validators.required]],
    end: [
      new Date(this.enddate.setDate(this.enddate.getDate() - 1)),
      [Validators.required],
    ],
  });

  chartconfig = this.formbuilder.group({
    group_Id: ['', Validators.required],
    device_Id: ['', Validators.required],
    Pins: ['', Validators.required],
    charttype: ['bar'],
  });

  initMulti: any[] = [];
  result: any;
  constructor(
    public matdialog: MatDialog,
    private dataservice: Dataservice,
    private authentication: AuthenticationService,
    private homecomponent: HomeComponent,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private formbuilder: FormBuilder,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.loading = false;
    this.novalue = false;

    this.ClusterAnalytics = true;
    this.vertical_type = this.authentication.getUserData().vertical_type;
    if (this.vertical_type == 2) {
      this.range.controls.Interval.enable();
      this.range.controls.Interval.setValue(String(this.interval[3].value));
    } else {
      this.range.controls.Interval.disable();
    }

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.bardata = {
      labels: [],
      datasets: [],
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
            autoSkip: true, // Enables autoSkip
            maxRotation: 0,

            // callback: (value: string) => {
            // const date = new Date(value);
            // const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

            callback: (value: any, index: any, ticks: any) => {
              const dateStr = this.bardata.labels[index]; // Assuming date is in dd/mm/yyyy format

              if(dateStr){
                     // Split the date string into day, month, and year
              const [day, month, year] = dateStr
              ?.split('/')
              ?.map((x: any, i: any) => {
                if (i == 2) {
                  x = x.split(' ');
                }
                return x;
              }); // Extract day, month, year as numbers

            // Create a valid date object (months are 0-based in JS, so we subtract 1 from the month)
            const date = new Date(year[0], month - 1, day);

            // Format the date to short month (MMM) and numeric day (dd)
            const options: Intl.DateTimeFormatOptions = {
              month: 'short',
              day: 'numeric',
            };
            const formattedDate = date.toLocaleDateString('en-US', options); // "Sep 25"

            // Check if the current label is the same as the previous one
            if (index > 0) {
              const prevDateStr = this.bardata.labels[index - 1];
              const [prevDay, prevMonth, prevYear] = prevDateStr
                ?.split('/')
                ?.map((x: any, i: any) => {
                  if (i == 2) {
                    x = x.split(' ');
                  }
                  return x;
                });
              const prevDate = new Date(prevYear[0], prevMonth - 1, prevDay);

              const prevFormattedDate = prevDate.toLocaleDateString(
                'en-US',
                options
              );

              if (formattedDate === prevFormattedDate) {
                return ''; // Skip the label if it's the same as the previous one
              }
            }

            return formattedDate;
              }else{
                const formattedDate = '';
                return formattedDate;
              }
          // Return formatted date if no duplicate
            },
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };
  }

  // range = this.formbuilder. FormGroup({
  //   cluster_Id: new FormControl<Number | null>(null, [Validators.required]),
  //   start: new FormControl<Date | null>(new Date(), [Validators.required]),
  //   end: new FormControl<Date | null>(new Date(), [Validators.required]),
  // });

  async updateRouteParams() {
    this.vertical_type = this.route.snapshot.paramMap.get('id1');
    if (this.vertical_type == 2) {
      this.range.controls.Interval.enable();
      this.range.controls.Interval.setValue(String(this.interval[3].value));
    } else {
      this.range.controls.Interval.disable();
    }
  }
  open() {
     this.Insights = true;
     this.Config = false;
    this.chartconfig.reset({ charttype: 'bar' });
    this.bardata = {
      labels: [],
      datasets: [],
    };
    this.clusterdevice = [];
  }
  config() {
    //this.insightsloader=true
    this.Config = true;
  }

  back() {
    this.Insights = false;
    this.configdata=false;
  }
  backconfig() {
    this.Config = false;
    this.configdata=false;
  }


   configdata :boolean =false;

  saveconfig() {
    this.configdata=false;
    this.bardata = {
      labels: [],
      datasets: [],
    };
    let Totalinsights: any = [];
    let userDeviceconfig: any = [];
    let deviceid: any;
    let Pins: any = [];
    deviceid = this.chartconfig.controls.device_Id.value;
    Pins = this.chartconfig.controls.Pins.value;

    const filterdevice = this.Tabledata[0].rows.filter(
      (y: any) => y.device_id == deviceid
    );
    
    userDeviceconfig.push(...filterdevice);

    // console.log(userDeviceconfig)

    if (Array.isArray(Pins)) {
      Pins.map((pin: any) => {
        const filterpinsvalue = userDeviceconfig.filter((x: any) => pin in x);
        Totalinsights.push(...filterpinsvalue);
      });

      const documentStyle = getComputedStyle(document.documentElement);
      const seenDates = new Set();
      Totalinsights.forEach((entry: any) => {
        // Extract only the date part from the updated_time (e.g., "09/09/2024")
        const datePart = entry.updated_time.split(' ')[0];

        // Check if the date has already been seen
        if (!seenDates.has(datePart)) {
          // If the date is new, add it to the unique entries and mark the date as seen
          this.bardata.labels.push(entry.updated_time);
          seenDates.add(datePart);
        } else {
          // Optional: Handle duplicates if needed (e.g., log them)
        }
      });
      this.bardata.labels = this.bardata.labels.sort();

      this.bardata.datasets = [];
      if (deviceid) {
        Pins.map((pin: any) => {
          this.bardata.datasets.push({
            label: this.showParams(pin,deviceid),
            extra:pin,
            backgroundColor: documentStyle.getPropertyValue('--blue-500'),
            borderColor: documentStyle.getPropertyValue('--blue-500'),
            data: [],
          });
        });
        this.bardata.labels.map((t: any) => {
          this.bardata.datasets.map((dt: any) => {
            // const find = Totalinsights.find(
            //   (time: any) => time.updated_time == t
            // );
            //   dt.data.push(find[dt.label]);
            //   dt.data = [...new Set(dt.data)]
            // const Maxvalue = Math.max(...dt.data);
            // const maxarray = dt.data.filter((value:any)=> value == Maxvalue)
            // dt.data =maxarray

            const matchingEntries = Totalinsights.filter(
              (time: any) => time.updated_time == t
            );

            if (matchingEntries.length > 0) {
              // Get the values for the current dataset's label (Pins)
              const values = matchingEntries.map(
                (entry: any) => entry[dt.extra]
              );

              // Find the maximum value for the current label (t)
              const maxValue = Math.max(...values);

              // Push only the maximum value into the data array for the current label (t)
              dt.data.push(maxValue);
            }
          });
        });
      }
    } else {
      const filterpinsvalue = userDeviceconfig.filter((x: any) => Pins in x);
      Totalinsights.push(...filterpinsvalue);
      const documentStyle = getComputedStyle(document.documentElement);
      const seenDates = new Set();
      Totalinsights.forEach((entry: any) => {
        // Extract only the date part from the updated_time (e.g., "09/09/2024")
        const datePart = entry.updated_time.split(' ')[0];

        // Check if the date has already been seen
        if (!seenDates.has(datePart)) {
          // If the date is new, add it to the unique entries and mark the date as seen
          this.bardata.labels.push(entry);
          seenDates.add(datePart);
        } else {
          // Optional: Handle duplicates if needed (e.g., log them)
        }
      });
      this.bardata.labels = this.bardata.labels.sort();

      this.bardata.datasets = [];

      deviceid.map((deviceids: any) => {
        this.bardata.datasets.push({
          label: Totalinsights.find((x: any) => x.device_id == deviceids)
            ?.device_name,
            extra:'',
          backgroundColor: documentStyle.getPropertyValue('--blue-500'),
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          data: [],
        });
      });
      this.bardata.labels.map((t: any) => {
        this.bardata.datasets.map((dt: any) => {
          const matchingEntries = Totalinsights.filter(
            (time: any) => time.updated_time == t
          );

          if (matchingEntries.length > 0) {
            // Get the values for the current dataset's label (Pins)
            const values = matchingEntries.map((entry: any) => entry[dt.label]);

            // Find the maximum value for the current label (t)
            const maxValue = Math.max(...values);

            // Push only the maximum value into the data array for the current label (t)
            dt.data.push(maxValue);
          }
          // const find = Totalinsights.find(
          //   (time: any) => time.updated_time == t
          // );
          // dt.data.push(find[Pins]);
          // dt.data = [...new Set(dt.data)]
          // const Maxvalue = Math.max(...dt.data);
          // const maxarray = dt.data.filter((value:any)=> value == Maxvalue)
          // dt.data =maxarray
        });
      });
    }

    this.insightsloader = false;
    //this.insightschart.type= String(this.chartconfig.controls.charttype.value);
    this.insightschart?.refresh();
    this.Config = false;
    if(this.bardata.labels.length==0){
      this.configdata=true
    }
    
  }

  private styleElement!: HTMLStyleElement;

  clusterdevice: any[] = [];

  getclusterdevice(cluster_Id: any) {
    this.clusterdevice = [];

    this.dataservice.Getclusterdevice(cluster_Id).subscribe((res: any) => {
      if (res.status == 200) {
        this.clusterdevice = res.data;
      } else if (res.status == 0) {
        this.toastr.info('No Data Found');
      } else {
        this.toastr.error('Error Occured');
      }

      console.log(res);
    });
  }
  multiplepin: any = true;
  ngOnInit(): void {
    this.chartconfig.controls.Pins.disable();
    this.chartconfig.controls.group_Id.valueChanges.subscribe((res) => {
      if (res) {
        this.chartconfig.controls.device_Id.reset();
        this.range.controls.cluster_Id.setValue(res);

        const data = { ...this.range.value };
        this.GetAnalyticsData(data, this.loading);

        this.getclusterdevice(res);
      }
    });
    this.chartconfig.controls.device_Id.valueChanges.subscribe((res: any) => {
      this.chartconfig.controls.Pins.reset();

      if (res?.length > 0) {
        this.chartconfig.controls.Pins.enable();
      } else if (res?.length <= 0) {
        this.chartconfig.controls.Pins.disable();
      }

      if (this.chartconfig.controls.device_Id.value?.length == 1) {
        this.multiplepin = true;
      } else {
        this.multiplepin = false;
      }
    });

    this.processData(this.data1);
  }
  processData(result: any[]): void {
    const headersSet = new Set<string>();

    this.headers = Array.from(headersSet);
    this.tableGrid = result;

    result.forEach((row) => {
      Object.keys(row).forEach((key) => headersSet.add(key));
    });

    this.route.paramMap.subscribe(async (param: any) => {
      await this.updateRouteParams();
    });
    this.vertical_type = this.route.snapshot.paramMap.get('id1');

    this.setColorScheme('cool');
    // this.homecomponent.routes();
    this.dataservice.setPaginationState(null, 'all');
    this.roles = this.authentication.getUserRole();
    this.roles = this.roles[10];
    this.roles.map((x: any, i: any) => {
      if (i == 0) {
        if (x == 1) {
          this.iscreate = true;
        } else {
          this.iscreate = false;
        }
      }
      if (i == 1) {
        if (x == 1) {
          this.isread = true;
        } else {
          this.isread = false;
        }
      }
      if (i == 2) {
        if (x == 1) {
          this.isdelete = true;
        } else {
          this.isdelete = false;
        }
      }
    });
    this.dataservice.MyDevicestatus.next();
    this.dataservice.AllDevicestatus.next();
    this.dataservice.singleDevicestatus.next();

    // Fetch user data for the current session
    const data = this.authentication.getUserData();
    this.vertical_Id = data.vertical_Id;
    this.loading = true;
    this.Finding = true;
    // Fetch the list of products
    // this.dataservice.getproduct(data).subscribe((res: any) => {
    //   if (res.status == '200') {
    //     this.productvalue = res.data;
    //     if(this.productvalue.length>=1){
    //       this.clustername = this.productvalue[0].cluster_name;
    //       this.cluster_id = this.productvalue[0].cluster_id;
    //       this.range.controls.cluster_Id.setValue(String(this.cluster_id));
    //       const Firstcluster = { ...this.range.value };
    //       this.GetAnalyticsData(Firstcluster, this.loading);
    //     }else{
    //       this.loading=false;
    //       this.Finding=false;
    //     }

    //   } else {
    //     this.toastr.error('Error occurred');
    //   }
    // });

    this.dataservice.getclusterdata(data.vertical_Id).subscribe((res: any) => {
      if (res.status == '200') {
        this.productvalue = res.data;
        // this.productvalue = this.productvalue.filter((x:any) => x.vertical_type == this.vertical_type)

        if (this.productvalue.length >= 1) {
          this.clustername = this.productvalue[0].group_name;

          this.cluster_id = this.productvalue[0].group_id;

          this.range.controls.cluster_Id.setValue(String(this.cluster_id));
          this.Getparams(this.range.value.cluster_Id)
          const Firstcluster = { ...this.range.value };
          this.GetAnalyticsData(Firstcluster, this.loading);
        } else {
          this.loading = false;
          this.Finding = false;
        }
      } else {
        this.toastr.error('Error occurred');
      }
    });

   

    this.range.controls.cluster_Id.valueChanges.subscribe((res: any) => {
      this.productvalue.map((x: any) => {
        if (x.group_id == res) {
          this.clustername = x.group_name;
          // console.log(this.clustername);
        }
      });
      this.Getparams(res)
      if (this.range.valid) {
        this.range.value.cluster_Id = res;
        const data = { ...this.range.value };
        this.GetAnalyticsData(data, this.loading);
        
      }
    });

    this.range.controls.start.valueChanges.subscribe((res: any) => {
      if (!this.range.controls.start.hasError('matStartDateInvalid')) {
        this.range.value.start = res;
        const data = { ...this.range.value };
        this.GetAnalyticsData(data, this.loading);
      }
    });
    this.range.controls.end.valueChanges.subscribe((res: any) => {
      if (!this.range.controls.start.hasError('matEndDateInvalid')) {
        this.range.value.end = res;
        const data = { ...this.range.value };
        this.GetAnalyticsData(data, this.loading);
      }
    });

    this.range.controls.Interval.valueChanges.subscribe((res: any) => {
      if (!this.range.controls.start.hasError('matEndDateInvalid')) {
        this.range.value.Interval = res;
        const data = { ...this.range.value };
        this.GetAnalyticsData(data, this.loading);
      }
    });
  }

  Getparams(data: any) {
    this.dataservice.Getparams(data).subscribe((res: any) => {
      if (res.status == 200) {
       this.addressconfig=res.data
       if(this.addressconfig.length>0){
        this.addressconfig.map((x:any)=>{
          x.address =JSON.parse(x.address)
        })
       }
       
     

      } else {
        this.toastr.error('error occurred');
      }
    });
  }


  showParams(pin:string,device_Id:any){
    // console.log(pin,device_Id)
    let pinconfig: any = null;
  

  this.addressconfig.forEach((x: any) => {
    if (x.address) {
      Object.keys(x.address).forEach((key) => {
        const addressConfig = x.address[key];
        // console.log(addressConfig)
        
        if (addressConfig.pin == pin) {
      
         
          Object.keys(addressConfig).forEach((x:any)=>{
            if(x.startsWith('address-')){
              pinconfig= addressConfig[x]
              if(pinconfig.devices.includes(device_Id)){
                pinconfig =pinconfig
              }
            }
          })
          
         
        }
      });
    }
  });

  // Will log the found configuration or null if not found.
  return pinconfig.params;

    


  }

  // open() {
  //   if (this.iscreate == true) {
  //     const MatDialogConfig = this.matdialog.open(createcluster, {
  //       width: '600px',
  //       disableClose: true,
  //     });
  //   } else if (this.iscreate == false) {
  //     this.toastr.info("User not permitted")
  //   }
  // }

  virtualpin: any[] = [];

  GetAnalyticsData(data: any, condition: any) {
    // console.log(data,condition);

    this.Clusterpiechartdata = [];
    this.LineChartData = [];
    this.Xaxisticks = [];
    this.Finding = true;
    let startDate;
    let endDate;
    this.data = [];
    this.Tabledata = [];
    this.TotalCo2 = null;
    this.TotalKwh = null;
    this.TotalCost = null;
    this.AvgKwh = null;
    startDate = new Date(String(this.range.value.start));
    endDate = new Date(String(this.range.value.end));

    startDate = startDate.getDate();
    endDate = endDate.getDate();
    if (startDate == endDate) {
      this.chartfilter = 'hour';
      // Start from 00:00 on the start date
      let currentTimestamp = new Date(String(this.range.value.start));
      currentTimestamp.setHours(0, 0, 0, 0);
      // End at 23:59 on the start date
      const endOfDay = new Date(startDate);
      endOfDay.setHours(23, 59, 59, 999);
      for (let i = 0; i <= 6; i++) {
        this.Xaxisticks.push(+new Date(currentTimestamp) + i * (240 * 60000));
      }
      this.Xaxismin = this.Xaxisticks[0];
      this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
      this.Xaxisticks.shift();
      this.Xaxisticks = [...this.Xaxisticks];
      this.LineChartData = [...this.LineChartData];
    } else if (+endDate - +startDate <= 5) {
      let Diff = +endDate - startDate;

      this.chartfilter = 'Day';
      // Start from 00:00 on the start date
      let currentTimestamp = new Date(String(this.range.value.start));
      currentTimestamp.setHours(0, 0, 0, 0);
      // End at 23:59 on the start date
      let endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);
      for (let i = 0; i <= Diff + 1; i++) {
        this.Xaxisticks.push(+new Date(currentTimestamp) + i * (1440 * 60000));
      }
      this.Xaxismin = this.Xaxisticks[0];
      this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
      this.Xaxisticks.shift();
      this.Xaxisticks.pop();
      this.Xaxisticks = [...this.Xaxisticks];
    } else {
      let Diff = +endDate - startDate;

      this.chartfilter = 'Day';
      if (Diff >= 6 && Diff <= 15) {
        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.range.value.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 4; i++) {
          this.Xaxisticks.push(
            +new Date(currentTimestamp) + i * (4440 * 60000)
          );
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      } else if (Diff > 15 && Diff <= 24) {
        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.range.value.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 5; i++) {
          this.Xaxisticks.push(
            +new Date(currentTimestamp) + i * (4 * 1440 * 60000)
          );
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      } else {
        console.log('else');
        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.range.value.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 5; i++) {
          this.Xaxisticks.push(
            +new Date(currentTimestamp) + i * (5 * 1440 * 60000)
          );
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      }
    }

    if (this.vertical_type != 2) {
      this.dataservice.GetClusterAnalytics(data).subscribe(async (res: any) => {
        if (res.status == '200') {
          this.data = res.data;
          this.Tabledata = res.Tabledata;

          if (this.Tabledata.length == 0 && this.data.length == 0) {
            this.nodata = true;
          } else {
            this.nodata = false;
          }
          this.data.forEach((x: any) => {
            const FindDevice_Id = this.LineChartData.some(
              (item) => item.extra === x.device_id
            );
            if (!FindDevice_Id) {
              this.LineChartData.push({
                name: x.device_name,
                series: [],
                extra: x.device_id,
              });
            }
          });

          this.Tabledata.forEach((x: any) => {
            x.kwh = Number(x.kwh).toFixed(1);

            x.co2 = Number(x.co2).toFixed(2);
            if (x.co2 === 'NaN' || x.co2 === null) {
              this.noco2 = true;
            }
            x.cost = Number(x.cost).toFixed(1);

            const Clusterpiechart = this.Clusterpiechartdata.some(
              (item) => item.extra === x.device_id
            );
            if (!Clusterpiechart) {
              this.Clusterpiechartdata.push({
                name: x.device_name,
                value: x.kwh,
                extra: x.device_id,
              });
            }
            this.novalue = this.Clusterpiechartdata.every(
              (x: any) => x.value == 0
            );
            this.TotalKwh = Number(
              Number(this.TotalKwh) + Number(x.kwh)
            ).toFixed(1);
            this.TotalCo2 = Number(
              Number(this.TotalCo2) + Number(x.co2)
            ).toFixed(2);
            this.TotalCost = Number(
              Number(this.TotalCost) + Number(x.cost)
            ).toFixed(0);
          });
          this.AvgKwh = (this.TotalKwh / this.Tabledata.length).toFixed(1);
          await Promise.all(
            this.LineChartData.map((y: any) => {
              const Matches = this.data.filter(
                (x: any) => x.device_id === y.extra
              );
              Matches.forEach((match: any) => {
                y.series.push({
                  name: new Date(match.updated_time),
                  value: match.kwh,
                });
              });
            })
          );

          this.Finding = false;
          if (condition) {
            this.loading = false;
          }
        } else if (res.status == '401') {
          this.Finding = false;
          this.loading = false;
        } else {
          this.toastr.error('Error occurred');
        }
      });
    } else if (this.vertical_type == 2) {
      this.dataservice
        .GetProductionAnalytics(data)
        .subscribe(async (res: any) => {
          if (res.status == '200') {
            this.Tabledata = [res.Tabledata];
            this.Clusterpiechartdata = res.PieCharatdata;
            console.log(this.Tabledata);
            this.virtualpin = this.Tabledata[0]?.headers.filter(
              (x: any) => !x.heading.search('V')
            );

            if (this.Tabledata[0]?.rows.length == 0) {
              this.nodata = true;
            } else {
              this.nodata = false;
            }
            this.Tabledata.forEach((x: any) => {
              const FindDevice_Id = this.LineChartData.some(
                (item) => item.extra === x.device_id
              );
              if (!FindDevice_Id) {
                this.LineChartData.push({
                  name: x.device_name,
                  series: [],
                  extra: x.device_id,
                });
              }
            });

            // this.Tabledata.forEach((x: any) => {
            //   x.kwh = Number(x.kwh).toFixed(1);

            //   x.co2 = Number(x.co2).toFixed(2);
            //   if (x.co2 === 'NaN' || x.co2 === null) {
            //     this.noco2 = true
            //   }
            //   x.cost = Number(x.cost).toFixed(1);

            //   const Clusterpiechart = this.Clusterpiechartdata.some(item => item.extra === x.device_id)
            //   if (!Clusterpiechart) {
            //     this.Clusterpiechartdata.push({ name: x.device_name, value: x.kwh, extra: x.device_id });
            //   }
            //   this.novalue = this.Clusterpiechartdata.every((x: any) => x.value == 0)
            //   this.TotalKwh = Number(Number(this.TotalKwh) + Number(x.kwh)).toFixed(1)
            //   this.TotalCo2 = Number(Number(this.TotalCo2) + Number(x.co2)).toFixed(2);
            //   this.TotalCost = Number((Number(this.TotalCost) + Number(x.cost))).toFixed(0);
            // })
            // this.AvgKwh = (this.TotalKwh / this.Tabledata.length).toFixed(1)

            await Promise.all(
              this.LineChartData.map((y: any) => {
                const Matches = this.Tabledata.filter(
                  (x: any) => x.device_id === y.extra
                );
                Matches.forEach((match: any) => {
                  y.series.push({
                    name: new Date(match.updated_time),
                    value: match.V13,
                    extra: match.device_name,
                  });
                });
              })
            );
            this.initMulti = this.LineChartData;
            this.Finding = false;
            if (condition) {
              this.loading = false;
            }
          } else if (res.status == '401') {
            this.Finding = false;
            this.loading = false;
          } else {
            this.toastr.error('Error occurred');
          }
        });
    }
  }

  onSelect(event: any) {
    if (this.isLegend(event)) {
      if (this.isDataShown(event)) {
        const tempData = JSON.parse(JSON.stringify(this.LineChartData));
        tempData.forEach((country: any) => {
          country.series.forEach((year: any) => {
            if (year.extra === event) {
              year.value = 0;
            }
          });
        });
        this.LineChartData = tempData;
      } else {
        console.log(this.initMulti);
        this.initMulti.forEach((country: any) => {
          country.series.forEach((year: any) => {
            console.log(year, event);
            if (year.extra === event && year.value !== 0) {
              this.setChartDataBackToInitData(
                country.name,
                year.extra,
                year.value
              );
            }
          });
        });
      }
    } else {
      console.log('emit event and do something else');
      // this.selection.emit(event);
    }
  }

  isLegend = (event: any) => typeof event === 'string';

  isDataShown = (event: any) => {
    const selectedBar = this.LineChartData.find((model) => {
      return model.series.find((singleModel: any) => {
        return singleModel.extra === event && singleModel.value !== 0;
      });
    });

    return typeof selectedBar !== 'undefined';
  };

  setChartDataBackToInitData = (
    country: any,
    yearName: any,
    yearDefValue: any
  ) => {
    console.log(country, yearName, yearDefValue);
    const tempData = this.LineChartData;

    tempData
      .find((_country: any) => _country.name === country)
      .series.find((_year: any) => _year.extra === yearName).value =
      yearDefValue;
    this.LineChartData = [...tempData];
  };

  GetDeviceAnalytics(Device_Id: any, devicename: any) {
    this.ClusterAnalytics = false;
    this.devicedata = {
      device_id: Device_Id,
      devicename: devicename,
      ...this.range.value,
    };
  }

  pieTooltipText(data: any) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);
    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">$${val}</span>
    `;
  }

  setColorScheme(name: any) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find((s: any) => s.name === name);
  }

  xAxisTickFormattingFunction = (value: any) => {
    let formatvalue;
    if (this.chartfilter == 'hour') {
      formatvalue = new Date(value).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    } else if (this.chartfilter == 'Day') {
      formatvalue = new Date(value).toLocaleDateString('en-US', {
        weekday: 'short',
        day: 'numeric',
      });
    } else if (this.chartfilter == 'Week') {
      formatvalue = new Date(value).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      });
    }
    return formatvalue;
  };

  receiveMessage(event: any) {
    if (event) {
      this.ClusterAnalytics = true;
    } else {
      this.ClusterAnalytics = false;
    }
  }

  ExportAnalytics() {
    if (
      this.range.valid &&
      !this.range.controls.start.hasError('matStartDateInvalid') &&
      !this.range.controls.end.hasError('matEndDateInvalid')
    ) {
      if (this.vertical_type != 2) {
        const data = { ...this.range.value, clustername: this.clustername };
        this.dataservice.ExportClusterAnalytics(data).subscribe((res: any) => {
          if (res.status == '400') {
            this.toastr.error('Error occurred');
          } else {
            const a = document.createElement('a');
            const fileUrl = window.URL.createObjectURL(res);
            a.href = fileUrl;
            a.download = this.clustername + '.csv';
            a.click();
            window.URL.revokeObjectURL(fileUrl);
          }
        });
      } else if (this.vertical_type == 2) {
        const data = { ...this.range.value, clustername: this.clustername };
        this.dataservice
          .GetProductionAnalyticsCsv(data)
          .subscribe((res: any) => {
            if (res.status == '400') {
              this.toastr.error('Error occurred');
            } else {
              const a = document.createElement('a');
              const fileUrl = window.URL.createObjectURL(res);
              a.href = fileUrl;
              a.download = this.clustername + '.csv';
              a.click();
              window.URL.revokeObjectURL(fileUrl);
            }
          });
      }
    }
  }
}

@Component({
  selector: 'app-deviceanalytics',
  templateUrl: 'deviceanalytics.html',
  styleUrls: ['./analytics.component.css'],
})
export class deviceanalytics implements OnInit {
  @Input() devicedata: any;
  @Output() messageEvent = new EventEmitter<boolean>();
  roles: any;
  isread!: boolean;
  iscreate!: boolean;
  isdelete!: boolean;
  reports: any[] = [];
  data: any;
  productvalue: any;
  clustername: any;
  loading!: boolean;
  colorSets: any = colorSets;
  Xaxisticks: any[] = [];
  Xaxismin: any;
  Xaxismax: any;
  ClusterAnalytics!: boolean;
  Finding!: boolean;
  KwhRYBpiechartdata: any[] = [];
  LineChartData: any[] = [];
  colorScheme: any;

  schemeType = ScaleType.Ordinal;
  selectedColorScheme!: string;
  legendPosition = LegendPosition.Right;
  TotalKwh: any = 0;
  TotalCo2: any = 0;
  TotalCost: any = 0;
  AvgKwh: any = 0;
  chartfilter: any = 'hour';
  devicename: any = 'Test';
  device_id: any;
  range: any;
  Tabledata: any;
  noco2: boolean = false;
  vertical_type: any;
  Clusterpiechartdata: any[] = [];
  constructor(
    public matdialog: MatDialog,
    private dataservice: Dataservice,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private toastr: ToastrService,
    private formbuilder: FormBuilder
  ) {
    this.loading = false;
    this.vertical_type = this.authentication.getUserData().vertical_type;
  }

  async updateRouteParams() {
    this.vertical_type = this.route.snapshot.paramMap.get('id1');
  }

  ngOnInit(): void {
    this.setColorScheme('cool');
    this.route.paramMap.subscribe(async (param: any) => {
      await this.updateRouteParams();
    });
    this.vertical_type = this.route.snapshot.paramMap.get('id1');

    this.devicename = this.devicedata?.devicename;
    this.device_id = this.devicedata?.device_id;

    this.GetAnalyticsData(this.devicedata, this.loading);

    this.range = this.formbuilder.group({
      start: [new Date(String(this.devicedata.start)), Validators.required],
      end: [new Date(String(this.devicedata.end)), Validators.required],
    });
  }

  async GetAnalyticsData(data: any, condition: any) {
    this.KwhRYBpiechartdata = [];
    this.LineChartData = [];
    this.data = [];
    this.Xaxisticks = [];
    this.Finding = true;
    let startDate;
    let endDate;
    this.Tabledata = [];
    startDate = new Date(String(this.devicedata.start));
    endDate = new Date(String(this.devicedata.end));
    startDate = startDate.getDate();
    endDate = endDate.getDate();

    if (startDate == endDate) {
      this.chartfilter = 'hour';
      // Start from 00:00 on the start date
      let currentTimestamp = new Date(String(this.devicedata.start));
      currentTimestamp.setHours(0, 0, 0, 0);

      // End at 23:59 on the start date
      const endOfDay = new Date(startDate);
      endOfDay.setHours(23, 59, 59, 999);
      for (let i = 0; i <= 6; i++) {
        this.Xaxisticks.push(+new Date(currentTimestamp) + i * (240 * 60000));
      }

      this.Xaxismin = this.Xaxisticks[0];
      this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
      this.Xaxisticks.shift();
      this.Xaxisticks = [...this.Xaxisticks];
    } else if (+endDate - +startDate <= 5) {
      let Diff = +endDate - startDate;
      this.chartfilter = 'Day';
      // Start from 00:00 on the start date
      let currentTimestamp = new Date(String(this.devicedata.start));
      currentTimestamp.setHours(0, 0, 0, 0);

      // End at 23:59 on the start date
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);

      for (let i = 0; i <= Diff + 1; i++) {
        this.Xaxisticks.push(+new Date(currentTimestamp) + i * (1440 * 60000));
      }

      this.Xaxismin = this.Xaxisticks[0];
      this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
      this.Xaxisticks.shift();
      this.Xaxisticks.pop();
      this.Xaxisticks = [...this.Xaxisticks];
    } else {
      let Diff = +endDate - startDate;
      this.chartfilter = 'Day';
      if (Diff >= 6 && Diff <= 15) {
        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.devicedata.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 4; i++) {
          this.Xaxisticks.push(
            +new Date(currentTimestamp) + i * (4440 * 60000)
          );
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      } else if (Diff > 15 && Diff <= 24) {
        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.devicedata.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 5; i++) {
          this.Xaxisticks.push(
            +new Date(currentTimestamp) + i * (4 * 1440 * 60000)
          );
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      } else {
        console.log('else');
        // Start from 00:00 on the start date
        let currentTimestamp = new Date(String(this.devicedata.start));
        currentTimestamp.setHours(0, 0, 0, 0);
        // End at 23:59 on the start date
        let endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        for (let i = 0; i <= 5; i++) {
          this.Xaxisticks.push(
            +new Date(currentTimestamp) + i * (5 * 1440 * 60000)
          );
        }
        this.Xaxismin = this.Xaxisticks[0];
        this.Xaxismax = this.Xaxisticks[this.Xaxisticks.length - 1];
        this.Xaxisticks.shift();
        this.Xaxisticks.pop();
        this.Xaxisticks = [...this.Xaxisticks];
      }
    }

    if (this.vertical_type != 2) {
      this.dataservice.GetDeviceAnalytics(data).subscribe((res: any) => {
        this.data = res.DeviceResultPerhour;
        this.Tabledata = res.DeviceResultPerday;
        this.Tabledata.forEach((x: any) => {
          x.kwh = Number(x.kwh).toFixed(2);
          x.co2 = Number(x.co2).toFixed(2);
          console.log(x.co2);
          if (x.co2 === 'NaN' || x.co2 === null) {
            this.noco2 = true;
          }
          this.TotalCo2 = Number(Number(x.co2) + Number(this.TotalCo2)).toFixed(
            2
          );
          console.log(this.TotalKwh);

          this.TotalKwh = Number(Number(x.kwh) + Number(this.TotalKwh)).toFixed(
            2
          );
        });

        this.AvgKwh = parseFloat(res.AvgKwh).toFixed(1);
        // this.TotalKwh = parseFloat(res.TotalKwh).toFixed(1);
        // this.TotalCo2 = parseFloat(res.TotalCo2).toFixed(1);
        this.TotalCost = parseFloat(res.TotalCost).toFixed(1);
        this.KwhRYBpiechartdata.push(
          { name: 'R', value: res.Kwh_R },
          { name: 'Y', value: res.Kwh_Y },
          { name: 'B', value: res.Kwh_B }
        );
        // this.KwhRYBpiechartdata.push({ "name": "R", "value": 20 }, { "name": "Y", "value": 40 }, { "name": "B", "value": 60 });

        this.Finding = false;

        this.data.forEach((x: any) => {
          const FindDevice_Id = this.LineChartData.some(
            (item) => item.extra === x.device_id
          );
          if (!FindDevice_Id) {
            this.LineChartData.push({
              name: x.device_name,
              series: [],
              extra: x.device_id,
            });
          }
        });

        this.LineChartData.map((y: any) => {
          const Matches = this.data.filter((x: any) => x.device_id === y.extra);
          Matches.forEach((match: any) => {
            y.series.push({
              name: new Date(match.updated_time),
              value: match.kwh,
            });
          });
        });

        this.Finding = false;
        if (condition) {
          this.loading = false;
        }
      });
    } else if (this.vertical_type == 2) {
      this.dataservice
        .GetDeviceProductionAnalytics(data)
        .subscribe(async (res: any) => {
          if (res.status == '200') {
            this.Tabledata = res.Tabledata;
            this.Clusterpiechartdata = res.PieCharatdata;

            this.Tabledata.forEach((x: any) => {
              const FindDevice_Id = this.LineChartData.some(
                (item) => item.extra === x.device_id
              );
              if (!FindDevice_Id) {
                this.LineChartData.push({
                  name: x.device_name,
                  series: [],
                  extra: x.device_id,
                });
              }
            });

            // this.Tabledata.forEach((x: any) => {
            //   x.kwh = Number(x.kwh).toFixed(1);

            //   x.co2 = Number(x.co2).toFixed(2);
            //   if (x.co2 === 'NaN' || x.co2 === null) {
            //     this.noco2 = true
            //   }
            //   x.cost = Number(x.cost).toFixed(1);

            //   const Clusterpiechart = this.Clusterpiechartdata.some(item => item.extra === x.device_id)
            //   if (!Clusterpiechart) {
            //     this.Clusterpiechartdata.push({ name: x.device_name, value: x.kwh, extra: x.device_id });
            //   }
            //   this.novalue = this.Clusterpiechartdata.every((x: any) => x.value == 0)
            //   this.TotalKwh = Number(Number(this.TotalKwh) + Number(x.kwh)).toFixed(1)
            //   this.TotalCo2 = Number(Number(this.TotalCo2) + Number(x.co2)).toFixed(2);
            //   this.TotalCost = Number((Number(this.TotalCost) + Number(x.cost))).toFixed(0);
            // })
            // this.AvgKwh = (this.TotalKwh / this.Tabledata.length).toFixed(1)

            await Promise.all(
              this.LineChartData.map((y: any) => {
                const Matches = this.Tabledata.filter(
                  (x: any) => x.device_id === y.extra
                );
                Matches.forEach((match: any) => {
                  y.series.push({
                    name: new Date(match.updated_time),
                    value: match.V13,
                    extra: match.device_name,
                  });
                });
              })
            );

            this.Finding = false;
            if (condition) {
              this.loading = false;
            }
          } else if (res.status == '401') {
            this.Finding = false;
            this.loading = false;
          } else {
            this.toastr.error('Error occurred');
          }
        });
    }
  }

  ExportAnalytics() {
    if (this.vertical_type != 2) {
      const data = { ...this.devicedata, devicename: this.devicename };
      this.dataservice.ExportDeviceAnalytics(data).subscribe((res: any) => {
        if (res.status == '400') {
          this.toastr.error('Error occurred');
        } else {
          const a = document.createElement('a');
          const fileUrl = window.URL.createObjectURL(res);
          a.href = fileUrl;
          a.download = this.devicename + '.csv';
          a.click();
          window.URL.revokeObjectURL(fileUrl);
        }
      });
    } else if (this.vertical_type == 2) {
      const data = { ...this.devicedata, devicename: this.devicename };
      this.dataservice
        .GetDeviceProductionAnalyticsCsv(data)
        .subscribe((res: any) => {
          if (res.status == '400') {
            this.toastr.error('Error occurred');
          } else {
            const a = document.createElement('a');
            const fileUrl = window.URL.createObjectURL(res);
            a.href = fileUrl;
            a.download = this.devicename + '.csv';
            a.click();
            window.URL.revokeObjectURL(fileUrl);
          }
        });
    }
  }

  close() {
    this.ClusterAnalytics = true;
    this.messageEvent.emit(this.ClusterAnalytics);
  }

  pieTooltipText(data: any) {
    const label = formatLabel(data.name);
    const val = formatLabel(data.value);

    return `
      <span class="tooltip-label">${escapeLabel(label)}</span>
      <span class="tooltip-val">$${val}</span>
    `;
  }

  setColorScheme(name: any) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find((s: any) => s.name === name);
  }

  xAxisTickFormattingFunction = (value: any) => {
    let formatvalue;
    if (this.chartfilter == 'hour') {
      formatvalue = new Date(value).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    } else if (this.chartfilter == 'Day') {
      formatvalue = new Date(value).toLocaleDateString('en-US', {
        weekday: 'short',
        day: 'numeric',
      });
    } else if (this.chartfilter == 'Week') {
      formatvalue = new Date(value).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      });
    }
    return formatvalue;
  };
}

@Component({
  selector: 'analyticspopup',
  templateUrl: 'analytics.html',
  styleUrls: ['matdialog.css'],
})
export class analyticspopup implements OnInit {
  data: any;
  productvalue: any;
  devicevalue: any;
  Device_Id: any[] = [];
  Analyticsreport = this.formbuilder.group({
    cluster_Id: ['', Validators.required],
    device_Id: ['', Validators.required],
    description: ['', Validators.maxLength(75)],
  });
  constructor(
    private dataservice: Dataservice,
    private formbuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AnalyticsComponent>,
    @Inject(MAT_DIALOG_DATA)
    private toastr: ToastrService,
    private authentication: AuthenticationService
  ) {}
  ngOnInit(): void {
    // Fetch user data for the current session
    const data = this.authentication.getUserData();

    // Fetch the list of products
    this.dataservice.getproduct(data).subscribe((res: any) => {
      if (res.status == '200') {
        this.data = res.data;
        this.productvalue = this.data;
      } else {
        this.toastr.error('Error occurred');
      }
    });

    this.Analyticsreport.controls['cluster_Id'].valueChanges.subscribe(
      (x: any) => {
        const cluster_Id = x;

        this.dataservice.getproductdevices(cluster_Id).subscribe((res: any) => {
          if (res.status == '200') {
            this.Device_Id = [];
            this.data = res.data;
            this.devicevalue = this.data;
          } else {
            this.toastr.error('Error occurred');
          }
        });
      }
    );
  }

  DownloadReport() {
    const cluster_Id = this.Analyticsreport.value.cluster_Id;

    const foundItem = this.productvalue.find(
      (x: any) => Number(x.cluster_id) === Number(cluster_Id)
    );

    this.dialogRef.close({
      event: 'success',
      data: {
        ...this.Analyticsreport.value,
        cluster_name: foundItem?.cluster_name,
      },
    });

    this.dataservice.downloadReport();
    let data: any;
    // this.dataservice.downloadAnalytics(data).subscribe((res: any) => {
    //   if (res.status == '400') {
    //     this.toastr.error('Error occurred');
    //   } else {
    //     const a = document.createElement('a');
    //     const fileUrl = window.URL.createObjectURL(res);
    //     a.href = fileUrl;
    //     a.download = foundItem?.cluster_name + '-Analytics.csv';
    //     a.click();
    //     window.URL.revokeObjectURL(fileUrl);
    //   }
    // });
  }

  getDeviceId(event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }) {
    if (event.isUserInput) {
      if (event.source.selected === true) {
        this.Device_Id.push(event.source.value);
        console.log(event.source.value);
      } else {
        const Findindex = this.Device_Id.indexOf(event.source.value);
        this.Device_Id.splice(Findindex, 1);
      }
    }
  }
}
